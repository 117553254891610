import { useMsal } from "@azure/msal-react";
import { useEffect, useState } from "react";

const useGetToken = () => {
  const { instance, accounts, inProgress } = useMsal();
  const [accessToken, setAccessToken] = useState("");

  useEffect(() => {
    if (inProgress === "none" && accounts.length > 0) {
      const request = {
        scopes: ["api://2bfcdd28-0445-4dd7-95ba-ce62a9383ed9/.default"], // Add the necessary scopes
        //scopes: ["User.Read", "User.ReadBasic.All"],
        account: accounts[0],
      };

      instance.acquireTokenSilent(request).then((response) => {
        setAccessToken(response.accessToken);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessToken, inProgress, accounts]);

  return accessToken;
};

const useGetPowerBiAuthToken = () => {
  const { instance, accounts, inProgress } = useMsal();
  const [accessToken, setAccessToken] = useState("");

  useEffect(() => {
    if (inProgress === "none" && accounts.length > 0) {
      const request = {
        // scopes: ["User.Read", "User.ReadBasic.All"],
        scopes: ["https://analysis.windows.net/powerbi/api/.default"],
        account: accounts[0],
      };

      instance.acquireTokenSilent(request).then((response) => {
        setAccessToken(response.accessToken);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessToken, inProgress, accounts]);

  return accessToken;
};

export { useGetToken, useGetPowerBiAuthToken };
