import "./CreateNewScenario.scss";
import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Stepper } from "../../../Stepper/Stepper";
import { Button } from "../../../Button/Button";
import { CancelBtn, NextBtn } from "../../../../util/constants";
import {
  dropSimulateState,
  getScenarioDetail,
  nextSimulateStep,
  previousSimulateStep,
  setScope,
  setSimulateStepIndex,
  setScenarioDetails,
  isEditScenario,
} from "../../../../store/slices/simulateSlice";
import { routes } from "../../../../routes/routes.constatns";
import { LoadingIndicatorEmbed } from "../../../LoadingIndicator/LoadingIndicatorEmbed";
import {
  saveScenario,
  updateScenarioDescription,
} from "../../../../store/services/simulate.service";
import { SaveUserModuleLock } from "../../../../store/services/project.service";

export function CreateNewScenario() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { state } = useLocation();
  const steps = useSelector((state) => state.stepper.steps);
  const engagement = useSelector((state) => state.engagement.engagement);
  const selectedRegion = useSelector(
    (state) => state.engagement.selectedRegion
  );
  const isNextTabButtonDisabled = useSelector(
    (state) => state.simulate.isNextTabButtonDisabled
  );
  const stepIndex = useSelector((state) => state.engagement.stepIndex);
  const currentStep = useSelector((state) => state.engagement.currentStep);
  const simulateSteps = useSelector((state) => state.simulate.simulateSteps);
  const simulate = useSelector((state) => state.simulate.scenario);
  const user = useSelector((state) => state.user.user);
  const currentSimulateStep = useSelector(
    (state) => state.simulate.currentSimulateStep
  );
  const currentSimulateStepIndex = useSelector(
    (state) => state.simulate.currentSimulateStepIndex
  );
  const [isDataSaveInProgress, setIsDataSaveInProgress] = useState(false);

  useEffect(() => {
    dispatch(setScope({ scope: 2 }));
  }, [dispatch]);

  useEffect(() => {
    var scenId = state?.scenarioId ?? simulate?.scenarioID;

    if (scenId) {
      dispatch(getScenarioDetail({ scenarioId: scenId }));
    }
  }, [state?.scenarioId, simulate?.scenarioID, dispatch]);

  function handleCancelClick() {
    dispatch(isEditScenario({ editScenario: false }));
    dispatch(dropSimulateState());
    navigate(`/${routes.createNewEngagement}`, {
      state: {
        engagementId: engagement.id,
        regionId: selectedRegion.id,
      },
      gestureEnabled: false,
    });
  }

  // Calling save user modulelock
  async function saveUserLockM4(scenarioID) {
    const requestScenario = {
      LockedBy: user.username,
      ModuleName: "Scenario",
      lockGuid: scenarioID,
      Id: 0,
    };
    await SaveUserModuleLock(requestScenario);
  }
  async function onSaveAndNext() {
    if (currentSimulateStep === 0) {
      setIsDataSaveInProgress(true);
      if (simulate.scenarioID) {
        const request = {
          ScenarioID: simulate.scenarioID,
          scenarioDesc: simulate.scenarioDescrption,
        };
        await updateScenarioDescription({ request });
        saveUserLockM4(simulate.scenarioID);
      } else {
        const request = {
          scenarioName: simulate.scenarioName,
          engagementID: engagement.id,
          engagementCode: engagement.engagementCode,
          scenario_Desc: simulate.scenarioDescrption,
          createdBy: user.username,
        };
        const response = await saveScenario({ request });
        if (response.data) {
          dispatch(
            setScenarioDetails({ scenarioID: response?.data?.scenarioID })
          );
          const scenarioID = response.data.scenarioID;
          saveUserLockM4(scenarioID);
        }
      }
      setIsDataSaveInProgress(false);
    }

    if (currentSimulateStep === 1) {
    }

    if (currentSimulateStep === currentSimulateStepIndex) {
      dispatch(setSimulateStepIndex({ index: currentSimulateStep + 1 }));
    }
    dispatch(nextSimulateStep());
  }

  return (
    <div className="create-new-scenario">
      <div className="create-new-scenario-header">
        <div className="create-new-scenario-header-title">
          {steps[currentStep]?.stepHeading}
        </div>
        <div className="create-new-scenario-header-stepper">
          <Stepper
            steps={steps}
            currentStep={currentStep}
            stepIndex={stepIndex}
          />
        </div>
      </div>
      <div className="create-new-scenario-content">
        {simulateSteps[currentSimulateStep]?.stepComponent}
      </div>
      <div className="forecast-import-excel-footer">
        <Button variant="secondary" onClick={handleCancelClick}>
          {CancelBtn}
        </Button>
        <div className="new-engagement-footer-nav-buttons">
          {currentSimulateStep === 1 ? (
            <Button
              variant="secondary"
              onClick={() => dispatch(previousSimulateStep())}
            >
              {"Previous"}
            </Button>
          ) : (
            <></>
          )}

          {currentSimulateStep === 1 ? (
            ""
          ) : (
            <LoadingIndicatorEmbed show={isDataSaveInProgress}>
              <Button
                variant="primary"
                onClick={onSaveAndNext}
                disabled={isNextTabButtonDisabled || isDataSaveInProgress}
              >
                {NextBtn}
              </Button>
            </LoadingIndicatorEmbed>
          )}
        </div>
      </div>
    </div>
  );
}
