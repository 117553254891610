import React, { memo } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { routes } from "../../routes/routes.constatns";
import {
  getUserLockByModule,
  SaveUserModuleLock,
} from "../../store/services/project.service";
import { setProjectId } from "../../store/slices/projectSlice";
import "./Analyse.scss";
import { Locker } from "../../icons/locker";
import { MotifTooltip } from "@ey-xd/motif-react";

export default memo(({ node, isDataNotinSync }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((state) => state.user?.user?.username);

  function onViewProjectClick(
    projectId,
    engagementId,
    projectStatusID,
    isValidated
  ) {
    dispatch(setProjectId({ id: projectId }));
    const request = {
      LockedBy: user,
      ModuleName: "Project",
      EngagementID: projectId,
    };

    getUserLockByModule({ request }).then((action) => {
      if (action.data?.lockStatus === 0) {
        const lockRequest = {
          moduleName: "Project",
          lockedBy: user,
          id: 0,
          lockGuid: projectId,
        };
        SaveUserModuleLock(lockRequest);
      }
    });
    navigate(`${routes.createNewProject}?projectId=${projectId}`, {
      state: {
        engagementId: engagementId,
        projectId: projectId,
        projectStatusId: projectStatusID,
        isValidated: isValidated,
      },
      gestureEnabled: false,
    });
  }
  // Check if the project is locked
  const isProjectLocked = node.data.isLocked === 1;
  return (
    <span
      className={` ${
        isDataNotinSync ? "analyse-table-data-validation-link" : ""
      } ${
        node.data.isValidated === "N"
          ? "analyse-table-pending-validation-link"
          : "view-project-link"
      }`}
      onClick={() => {
        // !isDataNotinSync &&
        onViewProjectClick(
          node.data.id,
          node.data.engagementID,
          node.data.projectStatusID,
          node.data.isValidated
        );
      }}
    >
      {isProjectLocked && (
        <MotifTooltip
          className="tooltip-icon"
          children={node.data.lockedby}
          placement="bottom"
          hideCloseButton="true"
          variant="alt"
          trigger={<Locker />}
        />
      )}
      {node.data.projectName}
    </span>
  );
});
