import React, { useState, useEffect } from "react";
import { Input } from "../../../../Input/Input";
import { TextArea } from "../../../../TextArea/TextArea";
import {
  saveSimulateHeaderTitle,
  ScenarioName,
  ScenarioDescription,
  sceanrioNameTooltip,
  scenarioDesctooltip,
} from "../../../../../util/constants";
import {
  setScenarioName,
  setScenarioDesc,
  setIsNextTabButtonDisabled,
} from "../../../../../store/slices/simulateSlice";
import { useDispatch, useSelector } from "react-redux";
import { LoadingIndicatorEmbed } from "../../../../LoadingIndicator/LoadingIndicatorEmbed";
import { Tooltip } from "../../../../CommonUIComponents/Tooltip/Tooltip";
import { EmissionIcon } from "../../../../../icons";

export function SaveScenario() {
  const dispatch = useDispatch();
  const simulate = useSelector((state) => state.simulate.scenario);
  const isEditScenario = useSelector((state) => state.simulate.isEditScenario);
  const [loader, showLoader] = useState(true);

  useEffect(() => {
    if (isEditScenario) {
      showLoader(true);
    } else {
      showLoader(false);
    }
  }, [isEditScenario]);

  useEffect(() => {
    if (simulate.scenarioName) {
      showLoader(false);
    }
  }, [simulate.scenarioName]);

  // On change scenario name and description
  const handleOnChange = async (event, fieldName) => {
    if (fieldName === "ScenarioName") {
      dispatch(setScenarioName({ name: event.target.value }));
    }
    if (fieldName === "ScenarioDesc") {
      dispatch(setScenarioDesc({ descrp: event.target.value }));
    }
  };

  // Disable Save button ob basis of empty fields
  if (simulate.scenarioName) {
    dispatch(setIsNextTabButtonDisabled({ isDisabled: false }));
  } else {
    dispatch(setIsNextTabButtonDisabled({ isDisabled: true }));
  }

  return (
    <>
      <LoadingIndicatorEmbed
        show={loader}
        className={"decarb-loading-indicator-transparent"}
      >
        <div className="save-new-scenario-content-body">
          <div className="save-new-scenario-content-body-header">
            <span>{saveSimulateHeaderTitle}</span>
          </div>
          <div className="save-new-scenario-content-body">
            <div className="save-new-scenario-content-body-row">
              <span className="save-new-scenario-content-body-name-title decarb-input-error-label">
                {ScenarioName}
                <span className="required-star">*</span>
                <Tooltip
                  trigger={<EmissionIcon />}
                  variant="ghost"
                  tooltipText={sceanrioNameTooltip}
                ></Tooltip>
              </span>
              <Input
                data-testid="scenario-name-input"
                value={simulate.scenarioName}
                disabled={simulate.scenarioID !== ""}
                onChange={(e) => handleOnChange(e, "ScenarioName")}
                type="text"
                maxLength={50}
              ></Input>
            </div>
            <div className="save-new-scenario-content-body-row">
              <span className="save-new-scenario-content-body-name-title decarb-input-error-label">
                {ScenarioDescription}
                <Tooltip
                  trigger={<EmissionIcon />}
                  variant="ghost"
                  tooltipText={scenarioDesctooltip}
                ></Tooltip>
              </span>
              <div style={{ flex: 1 }}>
                <TextArea
                  data-testid="scenario-name-textarea"
                  value={simulate.scenarioDescrption}
                  onChange={(e) => handleOnChange(e, "ScenarioDesc")}
                  maxLength={2000}
                  disableErrorPlaceholder={false}
                />
              </div>
            </div>
          </div>
        </div>
      </LoadingIndicatorEmbed>
    </>
  );
}
