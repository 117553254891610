import { EmissionsTable } from "./EmissionsTable/EmissionsTable";
import "./Emissions.scss";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { EmissionHeader } from "./EmissionsHeader/EmissionHeader";
import {
  getHierarchyLevelData,
  getReplaceHierarchyData,
} from "../../store/services/engagement.service";
import {
  setAppliedFiltersPayload,
  setDropdownMetadata,
  setHierarchialData,
  setIsNextTabButtonDisabled,
  setM3Step2Loader,
  setReduceDefautViewData,
  setUISelectedHeaderFilters,
  setUnitValidationFlag,
  setselectedUIAllFilters,
} from "../../store/slices/projectSlice";
import { EmissionSimulate } from "./EmissionsSimulate/EmissionSimulate";
import { EmissionGraph } from "./EmissionGraph/EmissionGraph";
import {
  getProjectEmissionFilters,
  getReduceDefautViewData,
  saveEmissionFiltersReduce,
  unitValidationStep2Module3,
} from "../../store/services/project.service";
import { SimulateFields } from "./SimulateFields/SimulateFields";

export function Emissions({ onTabStepCompleted }) {
  const engagement = useSelector((state) => state.engagement.engagement);
  const project = useSelector((state) => state.project.project);
  const m3Step2Loader = useSelector((state) => state.project.graphTableLoader);

  const dispatch = useDispatch();
  const [showloader, setShowLoader] = useState(true);
  const user = useSelector((state) => state.user.user);
  const isManualEdit = useSelector((state) => state.project.isManualEdit);
  const isEdit = useSelector((state) => state.project.isEditProject);
  let appliedFilterscounter = 0;
  const [selectedColumns, setSelectedCols] = useState(["scope"]);
  const isExtend = useSelector((state) => state.project.isExtend);
  const cols = ["scope"];

  useEffect(() => {
    setShowLoader(m3Step2Loader);
  }, [m3Step2Loader]);

  useEffect(() => {
    if (project?.projectId) {
      getProjectEditDetails();
      onTabStepCompleted();
      hierarchyData();
      dispatch(setIsNextTabButtonDisabled({ isDisabled: false }));
      // getDefaultViewData();
    }
  }, [project?.projectId]);

  const getProjectEditDetails = async () => {
    setShowLoader(true);
    const request = { projectID: project?.projectId };
    setEmissionFiltersSaved(request);
  };

  const getDefaultViewData = async (type) => {
    dispatch(setM3Step2Loader({ step2GraphTableLoader: true }));
    let request = {};

    if (isEdit || type == "M3-ApplyFilter") {
      if (type == "M3-ApplyFilter") {
        request = {
          engagementCode: engagement.engagementCode,
          projectID: project.projectId,
          columnNames: cols,
          numberOfYears: engagement.foreCastEndYear - engagement.mostRecentYear,
          viewType: "M3-ApplyFilter",
          modifiedBy: user.username,
          counter: appliedFilterscounter,
        };
      } else {
        request = {
          engagementCode: engagement.engagementCode,
          projectID: project.projectId,
          columnNames: ["scope"],
          numberOfYears: engagement.foreCastEndYear - engagement.mostRecentYear,
          viewType: "Default",
          modifiedBy: user.username,
          counter: 1,
        };
      }
    } else {
      request = {
        engagementCode: engagement.engagementCode,
        projectID: project.projectId,
        columnNames: ["scope"],
        numberOfYears: engagement.foreCastEndYear - engagement.mostRecentYear,
        viewType: "Default",
        modifiedBy: user.username,
        counter: 1,
      };
    }
    dispatch(setAppliedFiltersPayload({ filtersPayload: request }));
    const data = await getReduceDefautViewData({ request });
    dispatch(setReduceDefautViewData({ defaultViewData: data }));
    dispatch(setM3Step2Loader({ step2GraphTableLoader: false }));
  };
  //Payload construction  for Applying filters.
  const constructPayload = async (filters, type) => {
    let dropDownMetaData = [];
    const selectedScope = filters.scope.map((x) => {
      return { columnType: "Scope", columnValue: x };
    });
    const selectedCatLevel1 = filters.categoryLevel1.map((x) => {
      return { columnType: "Category_level1", columnValue: x };
    });
    const selectedCatLevel2 = filters.categoryLevel2.map((x) => {
      return { columnType: "Category_level2", columnValue: x };
    });
    const selectedCatLevel3 = filters.categoryLevel3.map((x) => {
      return { columnType: "Category_level3", columnValue: x };
    });
    const selectedRegLevel1 = filters.regionlevel1.map((x) => {
      return { columnType: "Region_level1", columnValue: x };
    });
    const selectedRegLevel2 = filters.regionlevel2.map((x) => {
      return { columnType: "Region_level2", columnValue: x };
    });
    const selectedRegLevel3 = filters.regionlevel3.map((x) => {
      return { columnType: "Region_level3", columnValue: x };
    });
    const selectedEntityLevel1 = filters.entitylevel1.map((x) => {
      return { columnType: "Entity_level1", columnValue: x };
    });
    const selectedEntityLevel2 = filters.entitylevel2.map((x) => {
      return { columnType: "Entity_level2", columnValue: x };
    });
    const selectedEntityLevel3 = filters.entitylevel3.map((x) => {
      return { columnType: "Entity_level3", columnValue: x };
    });
    const selectedEntityLevel4 = filters.entitylevel4.map((x) => {
      return { columnType: "Entity_level4", columnValue: x };
    });

    let selectedCustom1 = filters.customspecification1.map((x) => {
      return { columnType: "Custom_specification1", columnValue: x };
    });
    let selectedCustom2 = filters.customspecification2.map((x) => {
      return { columnType: "Custom_specification2", columnValue: x };
    });
    let selectedCustom3 = filters.customspecification3.map((x) => {
      return { columnType: "Custom_specification3", columnValue: x };
    });
    console.log(selectedCustom1, selectedCustom2);

    if (selectedCustom1.length === 0) {
      selectedCustom1 = [
        { columnType: "Custom_specification1", columnValue: "All" },
      ];
    }
    if (selectedCustom2.length === 0) {
      selectedCustom2 = [
        { columnType: "Custom_specification2", columnValue: "All" },
      ];
    }
    if (selectedCustom3.length === 0) {
      selectedCustom3 = [
        { columnType: "Custom_specification3", columnValue: "All" },
      ];
    }

    dropDownMetaData = [
      ...selectedScope,
      ...selectedCatLevel1,
      ...selectedCatLevel2,
      ...selectedCatLevel3,
      ...selectedRegLevel1,
      ...selectedRegLevel2,
      ...selectedRegLevel3,
      ...selectedEntityLevel1,
      ...selectedEntityLevel2,
      ...selectedEntityLevel3,
      ...selectedEntityLevel4,
      ...selectedCustom1,
      ...selectedCustom2,
      ...selectedCustom3,
    ];

    dispatch(setDropdownMetadata({ data: dropDownMetaData }));

    const payload = {
      engagementId: engagement.id,
      engagementCode: engagement.engagementCode,
      name: "Payload",
      moduleName: "M3-ApplyFilter",
      CreatedBy: user.username,
      modifiedby: user.username,
      dropDownMetaData: dropDownMetaData,
      projectId: project.projectId,
    };
    return payload;
  };

  const setEmissionFiltersSaved = async (request) => {
    const filters = await getProjectEmissionFilters({ request });
    if (filters?.scope?.length > 0) {
      if (!filters?.categoryLevel1?.includes("All")) {
        cols.push("category_level1");
      }
      if (!filters?.categoryLevel2?.includes("All")) {
        cols.push("category_level2");
      }
      if (!filters?.categoryLevel3?.includes("All")) {
        cols.push("category_level3");
      }
      if (!filters?.regionlevel1?.includes("All")) {
        cols.push("region_level1");
      }
      if (!filters?.regionlevel2?.includes("All")) {
        cols.push("region_level2");
      }
      if (!filters?.regionlevel3?.includes("All")) {
        cols.push("region_level3");
      }
      if (!filters?.entitylevel1?.includes("All")) {
        cols.push("entity_level1");
      }
      if (!filters?.entitylevel2?.includes("All")) {
        cols.push("entity_level2");
      }
      if (!filters?.entitylevel3?.includes("All")) {
        cols.push("entity_level3");
      }
      if (!filters?.entitylevel4?.includes("All")) {
        cols.push("entity_level4");
      }
      if (!filters?.customspecification1?.includes("All")) {
        cols.push("custom_specification1");
      }
      if (!filters?.customspecification2?.includes("All")) {
        cols.push("custom_specification2");
      }
      if (!filters?.customspecification3?.includes("All")) {
        cols.push("custom_specification3");
      }
      let payload = await constructPayload(filters, "Edit");
      const apiResponse = await saveEmissionFiltersReduce({ payload });
      // dispatch(setEmissionFiltersSaved({ selectedEmissionFilters: apiResponse }));
      let response = {
        isDuplicate: apiResponse?.[0]?.isDuplicate,
        eF_VALUE_COUNT: apiResponse?.[0]?.eF_VALUE_COUNT,
      };
      dispatch(setUnitValidationFlag({ flag: response }));
      appliedFilterscounter = apiResponse?.[0]?.counter;
      getDefaultViewData("M3-ApplyFilter");
    } else {
      getDefaultViewData("Default");
      let request = {
        engagementCode: engagement.engagementCode,
        projectID: project.projectId,
        modifiedBy: user.username,
      };
      const response = await unitValidationStep2Module3({ request });
      dispatch(setUnitValidationFlag({ flag: response }));
      let dropDownMetaData = [
        {
          columnType: "Scope",
          columnValue: "All",
        },
        {
          columnType: "Category_level1",
          columnValue: "All",
        },
        {
          columnType: "Category_level2",
          columnValue: "All",
        },
        {
          columnType: "Category_level3",
          columnValue: "All",
        },
        {
          columnType: "Region_level1",
          columnValue: "All",
        },
        {
          columnType: "Region_level2",
          columnValue: "All",
        },
        {
          columnType: "Region_level3",
          columnValue: "All",
        },
        {
          columnType: "Entity_level1",
          columnValue: "All",
        },
        {
          columnType: "Entity_level2",
          columnValue: "All",
        },
        {
          columnType: "Entity_level3",
          columnValue: "All",
        },
        {
          columnType: "Entity_level4",
          columnValue: "All",
        },
        {
          columnType: "Custom_specification1",
          columnValue: "All",
        },
        {
          columnType: "Custom_specification2",
          columnValue: "All",
        },
        {
          columnType: "Custom_specification3",
          columnValue: "All",
        },
      ];
      dispatch(setDropdownMetadata({ data: dropDownMetaData }));
    }

    dispatch(
      setselectedUIAllFilters({
        selectedUIAllFilters: {
          scope: filters.scope ? [...filters.scope] : [],
          category_level1:
            filters["categoryLevel1"]?.length > 0
              ? filters.categoryLevel1.includes("All")
                ? []
                : [...filters.categoryLevel1]
              : [],
          category_level2:
            filters["categoryLevel2"]?.length > 0
              ? filters.categoryLevel2.includes("All")
                ? []
                : [...filters.categoryLevel2]
              : [],
          category_level3:
            filters["categoryLevel3"]?.length > 0
              ? filters.categoryLevel3.includes("All")
                ? []
                : [...filters.categoryLevel3]
              : [],
          region_level1:
            filters["regionlevel1"]?.length > 0
              ? filters.regionlevel1.includes("All")
                ? []
                : [...filters.regionlevel1]
              : [],
          region_level2:
            filters["regionlevel2"]?.length > 0
              ? filters.regionlevel2.includes("All")
                ? []
                : [...filters.regionlevel2]
              : [],
          region_level3:
            filters["regionlevel3"]?.length > 0
              ? filters.regionlevel3.includes("All")
                ? []
                : [...filters.regionlevel3]
              : [],
          entity_level1:
            filters["entitylevel1"]?.length > 0
              ? filters.entitylevel1.includes("All")
                ? []
                : [...filters.entitylevel1]
              : [],
          entity_level2:
            filters["entitylevel2"]?.length > 0
              ? filters.entitylevel2.includes("All")
                ? []
                : [...filters.entitylevel2]
              : [],
          entity_level3:
            filters["entitylevel3"]?.length > 0
              ? filters.entitylevel3.includes("All")
                ? []
                : [...filters.entitylevel3]
              : [],
          entity_level4:
            filters["entitylevel4"]?.length > 0
              ? filters.entitylevel4.includes("All")
                ? []
                : [...filters.entitylevel4]
              : [],
          custom_specification1:
            filters["customspecification1"]?.length > 0
              ? filters.customspecification1.includes("All")
                ? []
                : [...filters.customspecification1]
              : [],
          custom_specification2:
            filters["customspecification2"]?.length > 0
              ? filters.customspecification2.includes("All")
                ? []
                : [...filters.customspecification2]
              : [],
          custom_specification3:
            filters["customspecification3"]?.length > 0
              ? filters.customspecification3.includes("All")
                ? []
                : [...filters.customspecification3]
              : [],
          highestLevelFilter: filters.highestFilterSelected,
        },
      })
    );
  };

  //Fetching hierarchy data and creating redux state for it.
  const hierarchyData = async () => {
    if (project.projectType === 2) {
      let request = {
        engagementCode: engagement.engagementCode,
        projectID: project.projectId,
      };
      const data = await getReplaceHierarchyData({ request });
      dispatch(setHierarchialData({ hierarchialData: data }));
    } else {
      let request = {
        engagement: engagement.engagementCode,
        clientName: engagement.clientName,
      };
      const data = await getHierarchyLevelData({ request });
      dispatch(setHierarchialData({ hierarchialData: data }));
    }
  };

  return (
    <div className="emissions">
      <div className="emissions-header">{/* <EmissionHeader /> */}</div>
      <div className="emissions-chart-section">
        {/* <EmissionSimulate /> */}
        <SimulateFields />
        <EmissionGraph loader={showloader} />
      </div>
      {(isManualEdit || isExtend) && (
        <div className="emissions-body">
          <EmissionsTable loader={showloader} />
        </div>
      )}
    </div>
  );
}
