import { useNavigate } from "react-router-dom";
import { Button } from "../../../../Button/Button";
import { ProjectFilter } from "./ProjectsFilter/ProjectsFilter";
import { useDispatch, useSelector } from "react-redux";
import { GraphIcon } from "../../../../../icons";
import { Select } from "../../../../Select/Select";
import { MotifCard, MotifCardBody, MotifCardHeader } from "@ey-xd/motif-react";
import {
  forecastBodyCalculateEmissionsSummaryChangeOfEmission,
  forecastBodyCalculateEmissionsSummaryCoveredEmission,
  forecastBodyCalculateEmissionsSummaryEmissionCoverage,
  forecastBodyCalculateEmissionsSummaryEmissionReducationTarget,
  forecastBodyCalculateEmissionsSummaryEmissionReductioonGap,
  forecastBodyCalculateEmissionsSummaryRelativeToBaseYear,
  forecastBodyCalculateEmissionsSummaryRelativeToTargetYear,
  PoweBiBtn,
} from "../../../../../util/constants";
import {
  getM4TrajectoryGraphData,
  getM4WaterfallGraphData,
  getM4MarginalAbatementCostGraphData,
  getBusinessCaseSummaryData,
  getAbatementCashflowData,
} from "../../../../../store/services/simulate.service";
import { LoadingIndicator } from "../../../../LoadingIndicator/LoadingIndicator";
import {
  setScope,
  setGraphData,
  setCardData,
} from "../../../../../store/slices/simulateSlice";
import { useEffect, useState } from "react";
import { routes } from "../../../../../routes/routes.constatns";
import { SimulateAbatementTable } from "./SimulateAbatementTable/SimulateAbatementTable";
import { SimulateEmissionGraph } from "./SimulateEmissionGraph/SimulateEmissionGraph";
import { SimulateWaterFallGraph } from "./SimulateWaterFallGraph/SimulateWaterFallGraph";
import { SimulateAbatementGraph } from "./SimulateAbatementGraph/SimulateAbatementGraph";
import { getLockingData } from "../../../../../store/slices/projectSlice";
import { LockerModalM4 } from "../../../../LockerModal/lockerModalM4";
import { SaveUserModuleLock } from "../../../../../store/services/project.service";
import { numberFormatter } from "../../../../../util/dateUtil";
import "./Simulate.scss";

export function Simulate() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const engagement = useSelector((state) => state.engagement.engagement);
  const simulate = useSelector((state) => state.simulate.scenario);
  const trajectoryGraphData = useSelector(
    (state) => state.simulate.trajectoryGraphData
  );
  const trajectoryCardData = useSelector(
    (state) => state.simulate.trajectoryCardData
  );
  const [sceanrioTrajectoryData, setSceanrioTrajectoryData] = useState({});
  const [scenarioEmissionData, setScenarioEmissionData] = useState({});
  const [scenarioWaterFallData, setScenarioWaterFallData] = useState({});
  const [scenarioMarginalCostData, setScenarioMarginalCostData] = useState({});
  const [scenarioBusinessSummaryData, setScenarioBusinessSummaryData] =
    useState({});
  const [scenarioCashFlowData, setScenarioCashFlowData] = useState([]);
  const [lockedData, setLock] = useState();
  const [lockedBy, setLockedby] = useState();
  const user = useSelector((state) => state.user.user);
  const [isLockerModalOpen, setLockerModalOpen] = useState(false);
  const [selectedScope, setSelectedScope] = useState(1);
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    if (
      engagement?.id &&
      simulate?.scenarioID &&
      simulate?.generateGraphResponse
    ) {
      updateM4GraphTableData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    engagement?.id,
    simulate?.generateGraphResponse,
    simulate?.scenarioID,
    dispatch,
  ]);

  useEffect(() => {
    dispatch(setScope({ scope: 2 }));
  }, [dispatch]);
  // to get a locked user flags
  useEffect(() => {
    if (engagement?.id) {
      const request = {
        LockedBy: user.username,
        ModuleName: "Scenario",
        EngagementID: simulate?.scenarioID,
      };

      dispatch(getLockingData(request)).then((action) => {
        if (action) {
          const lockedData = action.payload.data?.lockStatus;
          const lockedBy = action.payload.data?.lockedBy;
          setLock(lockedData);
          setLockedby(lockedBy);
          if (lockedData === 0) {
            const request = {
              LockedBy: user.username,
              ModuleName: "Scenario",
              lockGuid: simulate.scenarioID,
              Id: 0,
            };
            SaveUserModuleLock(request);
          }
          if (lockedData === 2) {
            setLockerModalOpen(true);
          }
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, lockedData]);

  function onScopeChange(event) {
    setSelectedScope(event);
    dispatch(setScope({ scope: event }));
    let emissionGraphData = [];
    let emissionCardData = [];
    if (event === "2") {
      emissionGraphData = trajectoryGraphData[1]["Scope 3"];
      emissionCardData = trajectoryCardData[1]["Scope 3"];
    } else if (event === "3") {
      emissionGraphData = trajectoryGraphData[2]["All Scopes"];
      emissionCardData = trajectoryCardData[2]["All Scopes"];
    } else if (event === "1") {
      emissionGraphData = trajectoryGraphData[0]["Scope 1&2"];
      emissionCardData = trajectoryCardData[0]["Scope 1&2"];
    }
    setSceanrioTrajectoryData(emissionGraphData);
    setScenarioEmissionData(emissionCardData);
  }

  //  Update M4 Charts and table data by calling respective api calls
  const updateM4GraphTableData = async () => {
    setLoader(true);
    const request = {
      engagementCode: engagement.engagementCode,
      ScenarioID: simulate?.scenarioID,
    };
    let data = await getM4TrajectoryGraphData({ request });
    dispatch(setGraphData({ graphData: data.graphData }));
    dispatch(setCardData({ cardData: data.cardsdata }));
    setSceanrioTrajectoryData(data.graphData[0]["Scope 1&2"]);
    setScenarioEmissionData(data.cardsdata[0]["Scope 1&2"]);
    const waterFallData = await getM4WaterfallGraphData({ request });
    setScenarioWaterFallData(waterFallData);
    const marginalCostData = await getM4MarginalAbatementCostGraphData({
      request,
    });
    setScenarioMarginalCostData(marginalCostData);
    const businessSummaryData = await getBusinessCaseSummaryData({ request });
    setScenarioBusinessSummaryData(businessSummaryData);
    const cashFlowData = await getAbatementCashflowData({ request });
    setScenarioCashFlowData(cashFlowData);
    setLoader(false);
  };

  return (
    <>
      <ProjectFilter />
      <div className="create-new-scenario-content-body">
        <div className="create-new-scenario-content-body-header">
          <span>Simulation</span>
          <Button
            onClick={() => {
              navigate(`/${routes.powerBI}`, {
                state: {
                  reportId: process.env.REACT_APP_POWER_BI_REPORT_ID_M4,
                  scenarioId: simulate?.scenarioID,
                  module: "M4",
                  engagemnentId: engagement.id,
                },
                gestureEnabled: false,
              });
            }}
            variant="secondary"
          >
            <GraphIcon />
            {PoweBiBtn}
          </Button>
        </div>
        <div className="create-new-scenario-content-body-scope">
          <span>Emissions trajectory</span>
        </div>
        <div className="create-new-scenario-content-body-scope-filter">
          <Select
            data-testid="scenario-scope"
            value={selectedScope}
            onChange={onScopeChange}
            disabled={trajectoryGraphData?.length === 0}
            options={[
              {
                name: "Scope 1 and 2",
                id: 1,
              },
              {
                name: "Scope 3",
                id: 2,
              },
              {
                name: "All Scopes",
                id: 3,
              },
            ]}
          />
        </div>
        <div className="create-new-scenario-content-body-emissions-traj-chart">
          <SimulateEmissionGraph
            sceanrioTrajectoryData={sceanrioTrajectoryData}
            loader={loader}
          />
        </div>
        <div className="forecast-body-chart-section-summary ">
          <MotifCard orientation="horizontal">
            <MotifCardHeader variant="alt">
              <span className="motif-h6">
                {forecastBodyCalculateEmissionsSummaryChangeOfEmission}
              </span>
              <span>
                {forecastBodyCalculateEmissionsSummaryRelativeToBaseYear}
              </span>
            </MotifCardHeader>
            <LoadingIndicator show={loader} fullscreen={false} transparent>
              <MotifCardBody>
                {Object.keys(scenarioEmissionData).length > 0 ? (
                  <>
                    <span className="summary-percent">
                      {scenarioEmissionData[0]?.percentage === 0
                        ? 0
                        : scenarioEmissionData[0]?.percentage?.toFixed(2) + "%"}
                    </span>
                    <span>
                      {scenarioEmissionData[0]?.emissions === 0
                        ? 0
                        : `${numberFormatter.format(
                            scenarioEmissionData[0]?.emissions
                          )} tCO\u{2082}e`}
                    </span>
                  </>
                ) : (
                  ""
                )}
              </MotifCardBody>
            </LoadingIndicator>
          </MotifCard>

          <MotifCard orientation="horizontal">
            <MotifCardHeader variant="alt">
              <span className="motif-h6">
                {forecastBodyCalculateEmissionsSummaryCoveredEmission}
              </span>
              <span>
                {forecastBodyCalculateEmissionsSummaryEmissionCoverage}
              </span>
            </MotifCardHeader>
            <LoadingIndicator show={loader} fullscreen={false} transparent>
              <MotifCardBody>
                {Object.keys(scenarioEmissionData).length > 0 ? (
                  <>
                    <span className="summary-percent">
                      {scenarioEmissionData[1]?.percentage === 0
                        ? 0
                        : scenarioEmissionData[1]?.percentage?.toFixed(2) + "%"}
                    </span>
                    <span>
                      {scenarioEmissionData[1]?.emissions === 0
                        ? 0
                        : `${numberFormatter.format(
                            scenarioEmissionData[1]?.emissions
                          )} tCO\u{2082}e`}
                    </span>
                  </>
                ) : (
                  ""
                )}
              </MotifCardBody>
            </LoadingIndicator>
          </MotifCard>
          <MotifCard orientation="horizontal">
            <MotifCardHeader variant="alt">
              <span className="motif-h6">
                {forecastBodyCalculateEmissionsSummaryEmissionReducationTarget}
              </span>
              <span>
                {forecastBodyCalculateEmissionsSummaryRelativeToBaseYear}
              </span>
            </MotifCardHeader>
            <LoadingIndicator show={loader} fullscreen={false} transparent>
              <MotifCardBody>
                {Object.keys(scenarioEmissionData).length > 0 ? (
                  <>
                    <span className="summary-percent">
                      {scenarioEmissionData[3]?.percentage === 0
                        ? 0
                        : scenarioEmissionData[3]?.percentage?.toFixed(2) + "%"}
                    </span>
                    <span>
                      {scenarioEmissionData[3]?.emissions === 0
                        ? 0
                        : `${numberFormatter.format(
                            scenarioEmissionData[3]?.emissions
                          )} tCO\u{2082}e`}
                    </span>
                  </>
                ) : (
                  ""
                )}
              </MotifCardBody>
            </LoadingIndicator>
          </MotifCard>
          <MotifCard orientation="horizontal">
            <MotifCardHeader variant="alt">
              <span className="motif-h6">
                {forecastBodyCalculateEmissionsSummaryEmissionReductioonGap}
              </span>
              <span>
                {forecastBodyCalculateEmissionsSummaryRelativeToTargetYear}
              </span>
            </MotifCardHeader>
            <LoadingIndicator show={loader} fullscreen={false} transparent>
              <MotifCardBody>
                {Object.keys(scenarioEmissionData).length > 0 ? (
                  <>
                    <span className="summary-percent">
                      {scenarioEmissionData[2]?.percentage === 0
                        ? 0
                        : typeof scenarioEmissionData[2]?.percentage ===
                          "number"
                        ? numberFormatter.format(
                            scenarioEmissionData[2]?.percentage?.toFixed(2)
                          ) + "%"
                        : scenarioEmissionData[2]?.percentage}
                    </span>
                    <span>
                      {scenarioEmissionData[2]?.emissions === 0
                        ? 0
                        : `${numberFormatter.format(
                            scenarioEmissionData[2]?.emissions
                          )} tCO\u{2082}e`}
                    </span>
                  </>
                ) : (
                  ""
                )}
              </MotifCardBody>
            </LoadingIndicator>
          </MotifCard>
        </div>
        <div className="create-new-scenario-content-body-waterfall-header">
          <span>Waterfall diagram</span>
        </div>
        <div className="create-new-scenario-content-body-waterfall-chart">
          <SimulateWaterFallGraph
            scenarioWaterFallData={scenarioWaterFallData}
            loader={loader}
          />
        </div>
        <div className="create-new-scenario-content-body-waterfall-header">
          <span>Marginal abatement cost curve</span>
        </div>
        <div className="create-new-scenario-content-body-waterfall-chart">
          <SimulateAbatementGraph
            scenarioMarginalCostData={scenarioMarginalCostData}
            loader={loader}
          />
        </div>
        <div className="create-new-scenario-content-body-waterfall-header">
          <span>Business case summary</span>
        </div>
        <div className="create-new-scenario-content-body-business-summary">
          <MotifCard orientation="horizontal">
            <MotifCardHeader variant="alt">
              <span className="motif-h6">{"Simple payback period (yrs)"}</span>
            </MotifCardHeader>
            <LoadingIndicator show={loader} fullscreen={false} transparent>
              <MotifCardBody>
                <span className="summary-percent">
                  {scenarioBusinessSummaryData?.simplePaybackPeriod === null
                    ? "-"
                    : scenarioBusinessSummaryData?.simplePaybackPeriod}
                </span>
              </MotifCardBody>
            </LoadingIndicator>
          </MotifCard>

          <MotifCard orientation="horizontal">
            <MotifCardHeader variant="alt">
              <span className="motif-h6">
                {"Discounted payback period (yrs)"}
              </span>
            </MotifCardHeader>
            <LoadingIndicator show={loader} fullscreen={false} transparent>
              <MotifCardBody>
                <span className="summary-percent">
                  {scenarioBusinessSummaryData?.discountedPaybackPeriod === null
                    ? "-"
                    : scenarioBusinessSummaryData?.discountedPaybackPeriod}
                </span>
              </MotifCardBody>
            </LoadingIndicator>
          </MotifCard>
          <MotifCard orientation="horizontal">
            <MotifCardHeader variant="alt">
              <span className="motif-h6">{"Net present value ($)"}</span>
            </MotifCardHeader>
            <LoadingIndicator show={loader} fullscreen={false} transparent>
              <MotifCardBody>
                <span className="summary-percent">
                  {scenarioBusinessSummaryData?.npv === null ||
                  scenarioBusinessSummaryData?.npv === undefined
                    ? ""
                    : scenarioBusinessSummaryData?.npv > 1000 ||
                      scenarioBusinessSummaryData?.npv < -100
                    ? numberFormatter.format(
                        scenarioBusinessSummaryData?.npv.toFixed(0)
                      )
                    : numberFormatter.format(scenarioBusinessSummaryData?.npv)}
                </span>
              </MotifCardBody>
            </LoadingIndicator>
          </MotifCard>
          <MotifCard orientation="horizontal">
            <MotifCardHeader variant="alt">
              <span className="motif-h6">
                {"Technical abatement potential (%)"}
              </span>
            </MotifCardHeader>
            <LoadingIndicator show={loader} fullscreen={false} transparent>
              <MotifCardBody>
                <span className="summary-percent">
                  {scenarioBusinessSummaryData?.technicalAbatementPotential ===
                  null
                    ? "-"
                    : scenarioBusinessSummaryData?.technicalAbatementPotential}
                </span>
              </MotifCardBody>
            </LoadingIndicator>
          </MotifCard>
          <MotifCard orientation="horizontal">
            <MotifCardHeader variant="alt">
              <span className="motif-h6">
                {"Marginal abatement cost ($/tCO\u{2082}e)"}
              </span>
            </MotifCardHeader>
            <LoadingIndicator show={loader} fullscreen={false} transparent>
              <MotifCardBody>
                <span className="summary-percent">
                  {scenarioBusinessSummaryData?.mac === null ||
                  scenarioBusinessSummaryData?.mac === undefined
                    ? ""
                    : scenarioBusinessSummaryData?.mac > 1000 ||
                      scenarioBusinessSummaryData?.mac < -100
                    ? numberFormatter.format(
                        scenarioBusinessSummaryData?.mac?.toFixed(0)
                      )
                    : numberFormatter.format(scenarioBusinessSummaryData?.mac)}
                </span>
              </MotifCardBody>
            </LoadingIndicator>
          </MotifCard>
          <MotifCard orientation="horizontal">
            <MotifCardHeader variant="alt">
              <span className="motif-h6">
                {"Internal rate of return (% p.a.)"}
              </span>
            </MotifCardHeader>
            <LoadingIndicator show={loader} fullscreen={false} transparent>
              <MotifCardBody>
                <span className="summary-percent">
                  {scenarioBusinessSummaryData?.irr === null
                    ? "-"
                    : scenarioBusinessSummaryData?.irr}
                </span>
              </MotifCardBody>
            </LoadingIndicator>
          </MotifCard>
        </div>
        <div className="create-new-scenario-content-body-waterfall-header">
          <span>Abatement and cash flow calculations</span>
        </div>
        <div className="create-new-scenario-content-body-waterfall-header">
          <SimulateAbatementTable data={scenarioCashFlowData} loader={loader} />
        </div>
        {lockedData === 2 &&
          lockedBy === user.username &&
          simulate?.scenarioID && (
            <LockerModalM4
              isOpen={isLockerModalOpen}
              onClose={() => setLockerModalOpen(false)}
            />
          )}
      </div>
    </>
  );
}
