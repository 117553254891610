import React, { useEffect, useState } from "react";
import { ApplyBtn, reset, dropdowns,  emissionFilterTooltip, } from "../../../util/constants";
import { Select } from "../../Select/Select";
import { Button } from "../../Button/Button";
import { useSelector, useDispatch } from "react-redux";
import { MotifProgressLoader } from "@ey-xd/motif-react";
import { setGMPHierarchy } from "../../../store/slices/gmpSlice";
import { LoadingIndicatorEmbed } from "../../LoadingIndicator/LoadingIndicatorEmbed";
import { Tooltip } from "../../CommonUIComponents/Tooltip/Tooltip";
import {
  setselectedUIAllFilters,
} from "../../../store/slices/gmpSlice";
import { Modal } from "../../Modal/Modal";
import { EmissionIcon } from "../../../icons";

function ModalBody({ onApply }) {
  const dispatch = useDispatch();
  const hierarchialData = useSelector((state) => state.project.hierarchialData);
   const selectedUIAllFilters = useSelector(
      (state) => state.gmp.selectedUIAllFilters
    );
  const [selectedUIfilters, setSelectedUIAllFilters] = useState(selectedUIAllFilters);
  let dropdownOptions = hierarchialData;
  const project = useSelector((state) => state.project.project);
  const engagement = useSelector((state) => state.engagement.engagement);
  const gmp = useSelector((state) => state.gmp.gmp);
  const user = useSelector((state) => state.user.user);
  const [showLoader, setLoader] = useState(false);
  const [hierarchySelection, setHierarchySelection] = useState(false);
  const [scopeValue, setScopeValue] = useState([]);

  useEffect(() => {
    if (gmp?.gmpVariableType === "Independent") {
      setSelectedUIAllFilters([]);
      setHierarchySelection(false);
      dispatch(setGMPHierarchy({ gmpHierarchyData: {} }));
    }
  }, [gmp?.gmpVariableType]);

  //Disabling dropdowns based on header filters selection
  const disableDropdowns = (label) => {
    switch (label) {
      case "Scope":
        if (gmp?.gmpVariableType === "Independent") {
          return true;
        } else if (gmp?.gmpVariableType === "Dependent") {
          return false;
        }
        break;
      case "Category 1":
        if (
          selectedUIfilters?.scope?.length === 0 ||
          !selectedUIfilters.scope ||
          gmp?.gmpVariableType === "Independent"
        ) {
          return true;
        }
        break;
      case "Category 2":
        if (
          selectedUIfilters?.category_level1?.length === 0 ||
          !selectedUIfilters?.category_level1 ||
          gmp?.gmpVariableType === "Independent"
        ) {
          return true;
        }
        break;
      case "Category 3":
        if (
          selectedUIfilters?.category_level2?.length === 0 ||
          !selectedUIfilters?.category_level2 ||
          gmp?.gmpVariableType === "Independent"
        ) {
          return true;
        }
        break;
      case "Region level 1":
        if (
          selectedUIfilters?.scope?.length === 0 ||
          !selectedUIfilters.scope ||
          gmp?.gmpVariableType === "Independent"
        ) {
          return true;
        }
        break;
      case "Region level 2":
        if (
          selectedUIfilters.region_level1?.length === 0 ||
          !selectedUIfilters.region_level1 ||
          gmp?.gmpVariableType === "Independent"
        ) {
          return true;
        }
        break;
      case "Region level 3":
        if (
          selectedUIfilters.region_level2?.length === 0 ||
          !selectedUIfilters.region_level2 ||
          gmp?.gmpVariableType === "Independent"
        ) {
          return true;
        }
        break;
      case "Entity level 1":
        if (
          selectedUIfilters?.scope?.length === 0 ||
          !selectedUIfilters.scope ||
          gmp?.gmpVariableType === "Independent"
        ) {
          return true;
        }
        break;
      case "Entity level 2":
        if (
          selectedUIfilters.entity_level1?.length === 0 ||
          !selectedUIfilters.entity_level1 ||
          gmp?.gmpVariableType === "Independent"
        ) {
          return true;
        }
        break;
      case "Entity level 3":
        if (
          selectedUIfilters.entity_level2?.length === 0 ||
          !selectedUIfilters.entity_level2 ||
          gmp?.gmpVariableType === "Independent"
        ) {
          return true;
        }
        break;
      case "Entity level 4":
        if (
          selectedUIfilters.entity_level3?.length === 0 ||
          !selectedUIfilters.entity_level3 ||
          gmp?.gmpVariableType === "Independent"
        ) {
          return true;
        }
        break;
      case "Custom level 1":
        if (
          selectedUIfilters?.scope?.length === 0 ||
          !selectedUIfilters.scope ||
          gmp?.gmpVariableType === "Independent"
        ) {
          return true;
        }
        break;
      case "Custom level 2":
        if (
          selectedUIfilters.custom_specification1?.length === 0 ||
          !selectedUIfilters.custom_specification1 ||
          gmp?.gmpVariableType === "Independent"
        ) {
          return true;
        }
        break;
      case "Custom level 3":
        if (
          selectedUIfilters.custom_specification2?.length === 0 ||
          !selectedUIfilters.custom_specification2 ||
          gmp?.gmpVariableType === "Independent"
        ) {
          return true;
        }
        break;
    }
  };

  // Handling dropdown change

  const handleDropdownChange = async (event, fieldId, id) => {
    dispatch(setGMPHierarchy({ gmpHierarchyData: {} }));
    setHierarchySelection(false);
    switch (fieldId) {
      case "scope":
        setScopeValue(event);
        setSelectedUIAllFilters({
          ...selectedUIfilters,
          scope: event,
          category_level1: [],
          category_level2: [],
          category_level3: [],
          region_level1: [],
          region_level2: [],
          region_level3: [],
          entity_level1: [],
          entity_level2: [],
          entity_level3: [],
          entity_level4: [],
          custom_specification1: [],
          custom_specification2: [],
          custom_specification3: [],
          latest_filter: fieldId,
        });
        break;
      case "category_level1":
        setSelectedUIAllFilters({
          ...selectedUIfilters,
          category_level1: event,
          category_level2: [],
          category_level3: [],
          region_level1: [],
          region_level2: [],
          region_level3: [],
          entity_level1: [],
          entity_level2: [],
          entity_level3: [],
          entity_level4: [],
          custom_specification1: [],
          custom_specification2: [],
          custom_specification3: [],
          latest_filter: fieldId,
        });
        break;
      case "category_level2":
        setSelectedUIAllFilters({
          ...selectedUIfilters,
          category_level2: event,
          category_level3: [],
          region_level1: [],
          region_level2: [],
          region_level3: [],
          entity_level1: [],
          entity_level2: [],
          entity_level3: [],
          entity_level4: [],
          custom_specification1: [],
          custom_specification2: [],
          custom_specification3: [],
          latest_filter: fieldId,
        });
        break;
      case "category_level3":
        setSelectedUIAllFilters({
          ...selectedUIfilters,
          category_level3: event,
          region_level1: [],
          region_level2: [],
          region_level3: [],
          entity_level1: [],
          entity_level2: [],
          entity_level3: [],
          entity_level4: [],
          custom_specification1: [],
          custom_specification2: [],
          custom_specification3: [],
          latest_filter: fieldId,
        });
        break;
      case "region_level1":
        setSelectedUIAllFilters({
          ...selectedUIfilters,
          region_level1: event,
          region_level2: [],
          region_level3: [],
          entity_level1: [],
          entity_level2: [],
          entity_level3: [],
          entity_level4: [],
          custom_specification1: [],
          custom_specification2: [],
          custom_specification3: [],
          latest_filter: fieldId,
        });
        break;
      case "region_level2":
        setSelectedUIAllFilters({
          ...selectedUIfilters,
          region_level2: event,
          region_level3: [],
          entity_level1: [],
          entity_level2: [],
          entity_level3: [],
          entity_level4: [],
          custom_specification1: [],
          custom_specification2: [],
          custom_specification3: [],
          latest_filter: fieldId,
        });
        break;
      case "region_level3":
        setSelectedUIAllFilters({
          ...selectedUIfilters,
          region_level3: event,
          entity_level1: [],
          entity_level2: [],
          entity_level3: [],
          entity_level4: [],
          custom_specification1: [],
          custom_specification2: [],
          custom_specification3: [],
          latest_filter: fieldId,
        });
        break;
      case "entity_level1":
        setSelectedUIAllFilters({
          ...selectedUIfilters,
          entity_level1: event,
          entity_level2: [],
          entity_level3: [],
          entity_level4: [],
          custom_specification1: [],
          custom_specification2: [],
          custom_specification3: [],
          latest_filter: fieldId,
        });
        break;
      case "entity_level2":
        setSelectedUIAllFilters({
          ...selectedUIfilters,
          entity_level2: event,
          entity_level3: [],
          entity_level4: [],
          custom_specification1: [],
          custom_specification2: [],
          custom_specification3: [],
          latest_filter: fieldId,
        });
        break;
      case "entity_level3":
        setSelectedUIAllFilters({
          ...selectedUIfilters,
          entity_level3: event,
          entity_level4: [],
          custom_specification1: [],
          custom_specification2: [],
          custom_specification3: [],
          latest_filter: fieldId,
        });
        break;
      case "entity_level4":
        setSelectedUIAllFilters({
          ...selectedUIfilters,
          entity_level4: event,
          custom_specification1: [],
          custom_specification2: [],
          custom_specification3: [],
          latest_filter: fieldId,
        });
        break;
      case "custom_specification1":
        setSelectedUIAllFilters({
          ...selectedUIfilters,
          custom_specification1: event,
          custom_specification2: [],
          custom_specification3: [],
          latest_filter: fieldId,
        });
        break;
      case "custom_specification2":
        setSelectedUIAllFilters({
          ...selectedUIfilters,
          custom_specification2: event,
          custom_specification3: [],
          latest_filter: fieldId,
        });
        break;
      case "custom_specification3":
        setSelectedUIAllFilters({
          ...selectedUIfilters,
          custom_specification3: event,
          latest_filter: fieldId,
        });
        break;
    }
  };

  // Populating the dropdown values
  const getDropdownValues = (fieldId) => {
    let dataset = [...dropdownOptions];
    if (fieldId === "scope") {
      dataset = dataset;
    }
    if (fieldId === "category_level1") {
      const x = dropdownOptions.filter((element) =>
        selectedUIfilters["scope"]?.includes(element["scope"])
      );
      dataset = [...x];
    }
    if (fieldId === "category_level2") {
      dataset = dropdownOptions.filter((element) =>
        selectedUIfilters["category_level1"]?.includes(
          element["category_level1"]
        )
      );
    }
    if (fieldId === "category_level3") {
      dataset = dropdownOptions.filter((element) =>
        selectedUIfilters["category_level2"]?.includes(
          element["category_level2"]
        )
      );
    }
    if (fieldId === "region_level1") {
      dataset = dropdownOptions.filter((element) =>
        selectedUIfilters["scope"]?.includes(element["scope"])
      );
    }

    if (fieldId === "region_level2") {
      dataset = dropdownOptions.filter((element) =>
        selectedUIfilters["region_level1"]?.includes(element["region_level1"])
      );
    }
    if (fieldId === "region_level3") {
      dataset = dropdownOptions.filter((element) =>
        selectedUIfilters["region_level2"]?.includes(element["region_level2"])
      );
    }

    if (fieldId === "entity_level1") {
      dataset = dropdownOptions.filter((element) =>
        selectedUIfilters["scope"]?.includes(element["scope"])
      );
    }

    if (fieldId === "entity_level2") {
      dataset = dropdownOptions.filter((element) =>
        selectedUIfilters["entity_level1"]?.includes(element["entity_level1"])
      );
    }
    if (fieldId === "entity_level3") {
      dataset = dropdownOptions.filter((element) =>
        selectedUIfilters["entity_level2"]?.includes(element["entity_level2"])
      );
    }
    if (fieldId === "entity_level4") {
      dataset = dropdownOptions.filter((element) =>
        selectedUIfilters["entity_level3"]?.includes(element["entity_level3"])
      );
    }

    if (fieldId === "custom_specification1") {
      dataset = dropdownOptions.filter((element) =>
        selectedUIfilters["scope"]?.includes(element["scope"])
      );
    }
    if (fieldId === "custom_specification2") {
      dataset = dropdownOptions.filter((element) =>
        selectedUIfilters["custom_specification1"]?.includes(
          element["custom_specification1"]
        )
      );
    }
    if (fieldId === "custom_specification3") {
      dataset = dropdownOptions.filter((element) =>
        selectedUIfilters["custom_specification2"]?.includes(
          element["custom_specification2"]
        )
      );
    }
    let array = [...new Set(dataset.map((element) => element[fieldId]))];
    array = array.sort();
    return array;
  };

  //Resetting the dropdowns
  const resetDropdowns = async () => {
    dispatch(setGMPHierarchy({ gmpHierarchyData: {} }));
    setHierarchySelection(false);
    setScopeValue([]);
    setSelectedUIAllFilters([]);
    dispatch(
      setselectedUIAllFilters({ selectedUIAllFilters: [] })
    );
  };

  //Applying the filters

  const applyFilters = async (selectedUIfilters) => {
    setLoader(true);
    setHierarchySelection(true);
    const filters = {
      scope: selectedUIfilters["scope"],
      category_level1:
        selectedUIfilters["category_level1"]?.length > 0
          ? selectedUIfilters["category_level1"]
          : ["All"],
      category_level2:
        selectedUIfilters["category_level2"]?.length > 0
          ? selectedUIfilters["category_level2"]
          : ["All"],
      category_level3:
        selectedUIfilters["category_level3"]?.length > 0
          ? selectedUIfilters["category_level3"]
          : ["All"],
      region_level1:
        selectedUIfilters["region_level1"]?.length > 0
          ? selectedUIfilters["region_level1"]
          : ["All"],
      region_level2:
        selectedUIfilters["region_level2"]?.length > 0
          ? selectedUIfilters["region_level2"]
          : ["All"],
      region_level3:
        selectedUIfilters["region_level3"]?.length > 0
          ? selectedUIfilters["region_level3"]
          : ["All"],
      entity_level1:
        selectedUIfilters["entity_level1"]?.length > 0
          ? selectedUIfilters["entity_level1"]
          : ["All"],
      entity_level2:
        selectedUIfilters["entity_level2"]?.length > 0
          ? selectedUIfilters["entity_level2"]
          : ["All"],
      entity_level3:
        selectedUIfilters["entity_level3"]?.length > 0
          ? selectedUIfilters["entity_level3"]
          : ["All"],
      entity_level4:
        selectedUIfilters["entity_level4"]?.length > 0
          ? selectedUIfilters["entity_level4"]
          : ["All"],
      custom_specification1:
        selectedUIfilters["custom_specification1"]?.length > 0
          ? selectedUIfilters["custom_specification1"]
          : ["All"],
      custom_specification2:
        selectedUIfilters["custom_specification2"]?.length > 0
          ? selectedUIfilters["custom_specification2"]
          : ["All"],
      custom_specification3:
        selectedUIfilters["custom_specification3"]?.length > 0
          ? selectedUIfilters["custom_specification3"]
          : ["All"],
    };
    const payload = await constructPayload(filters);
    dispatch(setGMPHierarchy({ gmpHierarchyData: payload }));
    dispatch(
          setselectedUIAllFilters({ selectedUIAllFilters: selectedUIfilters })
        );
    setLoader(false);
    onApply();
  };

  const constructPayload = async (filters) => {
    let dropDownMetaData = [];
    const selectedScope = filters.scope.map((x) => {
      return { columnType: "Scope", columnValue: x };
    });
    const selectedCatLevel1 = filters.category_level1.map((x) => {
      return { columnType: "Category_level1", columnValue: x };
    });
    const selectedCatLevel2 = filters.category_level2.map((x) => {
      return { columnType: "Category_level2", columnValue: x };
    });
    const selectedCatLevel3 = filters.category_level3.map((x) => {
      return { columnType: "Category_level3", columnValue: x };
    });
    //Geo Data
    const selectedRegionLevel1 = filters.region_level1.map((x) => {
      return { columnType: "Region_level1", columnValue: x };
    });
    const selectedRegionLevel2 = filters.region_level2.map((x) => {
      return { columnType: "Region_level2", columnValue: x };
    });
    const selectedRegionLevel3 = filters.region_level3.map((x) => {
      return { columnType: "Region_level3", columnValue: x };
    });

    //Org Data
    const selectedEntityLevel1 = filters.entity_level1?.map((x) => {
      return { columnType: "Entity_level1", columnValue: x };
    });
    const selectedEntityLevel2 = filters.entity_level2?.map((x) => {
      return { columnType: "Entity_level2", columnValue: x };
    });
    const selectedEntityLevel3 = filters.entity_level3?.map((x) => {
      return { columnType: "Entity_level3", columnValue: x };
    });
    const selectedEntityLevel4 = filters.entity_level4?.map((x) => {
      return { columnType: "Entity_level4", columnValue: x };
    });
    const selectedCustom1 = filters.custom_specification1.map((x) => {
      return { columnType: "Custom_specification1", columnValue: x };
    });
    const selectedCustom2 = filters.custom_specification2.map((x) => {
      return { columnType: "Custom_specification2", columnValue: x };
    });
    const selectedCustom3 = filters.custom_specification3.map((x) => {
      return { columnType: "Custom_specification3", columnValue: x };
    });

    dropDownMetaData = [
      ...selectedScope,
      ...selectedCatLevel1,
      ...selectedCatLevel2,
      ...selectedCatLevel3,
      ...selectedRegionLevel1,
      ...selectedRegionLevel2,
      ...selectedRegionLevel3,
      ...selectedEntityLevel1,
      ...selectedEntityLevel2,
      ...selectedEntityLevel3,
      ...selectedEntityLevel4,
      ...selectedCustom1,
      ...selectedCustom2,
      ...selectedCustom3,
    ];
    return dropDownMetaData;
  };

  return (
    <div className="add-gmp-hieraachry-container">
      <div className="emission-filter-modal-content">
        {dropdowns.map((element) => {
          return (
            <div className="emission-filter-modal-content-main">
              <span className="emission-filter-modal-label" key={element.Name}>
                {element.Name}
                <Tooltip
                  trigger={<EmissionIcon />}
                  variant="ghost"
                  tooltipText={element.tooltip}
                ></Tooltip>
              </span>
              <div className="emission-filter-modal-content-dropdowns">
                {element.dropdownList.map((dropdown) => {
                  return (
                    <div>
                      <span className="emission-filter-modal-content-dropdowns-label">
                        {dropdown.label}
                        {dropdown.label === "Scope" ? (
                          <span className="required-star">*</span>
                        ) : (
                          ""
                        )}
                      </span>
                      <Select
                        options={getDropdownValues(dropdown.fieldId)}
                        disabled={disableDropdowns(dropdown.label)}
                        value={
                          selectedUIfilters[dropdown.fieldId]
                            ? selectedUIfilters[dropdown.fieldId]
                            : []
                        }
                        onChange={(event) => {
                          handleDropdownChange(
                            event,
                            dropdown.fieldId,
                            dropdown.id
                          );
                        }}
                        multiple={true}
                        showSelectAllButton={true}
                      ></Select>
                    </div>
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>
      <div className="emission-filter-modal-footer">
        <Button
          variant="primary"
          onClick={() => {
            resetDropdowns();
          }}
          disabled={
            // scopeValue.length === 0 
            // selectedUIfilters.length === 0 
            // selectedUIAllFilters.scope.length === 0
            Object.keys(selectedUIfilters).length > 0 ? false : true
          }
        >
          {reset}
        </Button>
        <LoadingIndicatorEmbed show={showLoader}>
          <Button
            variant="primary"
            onClick={() => applyFilters(selectedUIfilters)}
            disabled={
              scopeValue.length === 0 ||
              hierarchySelection === true ||
              selectedUIfilters.length === 0
            }
          >
            {hierarchySelection === true ? "Saved" : ApplyBtn}
          </Button>
        </LoadingIndicatorEmbed>
      </div>
    </div>
  );
}

export function GMPHierarchy({ isFilterOpen, onClose }) {
  return (
    <Modal
      show={isFilterOpen}
      onClose={onClose}
      header={
        <div className="emission-filter-modal-header">
          <span>Data Selection</span>
          <Tooltip
            trigger={<EmissionIcon />}
            variant="ghost"
            tooltipText={emissionFilterTooltip}
          ></Tooltip>
        </div>
      }
      body={<ModalBody onApply={onClose}></ModalBody>}
      className={"emission-gmp-modal"}
    ></Modal>
  );
}
