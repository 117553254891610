import React, { useState, useEffect } from "react";
import "./AddForecast.scss";
import { Button } from "../../Button/Button";
import { Stepper } from "../../Stepper/Stepper";
import { useDispatch, useSelector } from "react-redux";
import {
  addForecast,
  AddForecastBtn,
  addForecastTableHeaderCustom,
  addForecastTableHeaderDataEndYear,
  addForecastTableHeaderDataPercentageValue,
  addForecastTableHeaderDataStartYear,
  addForecastTableHeaderDataType,
  addForecastTableHeaderEmission,
  addForecastTableHeaderForecastDesc,
  addForecastTableHeaderForecastName,
  addForecastTableHeaderGeo,
  addForecastTableHeaderOrg,
  addforecastTooltip,
  ApplyBtn,
  applyErrorMessageForecast,
  CancelBtn,
  foreEndYear,
  foreStartYear,
} from "../../../util/constants";
import { AddIcon } from "../../../icons";
import { Table } from "../../Table/Table";
import { useNavigate } from "react-router-dom";
import { routes } from "../../../routes/routes.constatns";
import CustomEditComponent from "./CustomEditComponent";
import CustomChipComponent from "../components/shared/CustomChips";
import { setSkipForecast } from "../../../store/slices/engagementSlice";
import { getForecasts } from "../../../store/slices/forecastSlice";
import { MotifErrorMessage } from "@ey-xd/motif-react";
import { uploadFileToBlob } from "../../../store/services/azureBlob.service";
import trigger from "../../../assets/templates/trigger.txt";
import { formarDateTime } from "../../../util/dateUtil";
import { applyForecast } from "../../../store/services/forecast.service";
import {
  getEngagement,
  updateSkipForecast,
  uploadTextFile,
} from "../../../store/services/engagement.service";
import { getLockingData } from "../../../store/slices/projectSlice";
import { LockerModal } from "../../LockerModal/lockerModal";
import { LoadingIndicatorEmbed } from "../../LoadingIndicator/LoadingIndicatorEmbed";

export function AddForecast() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const steps = useSelector((state) => state.stepper.steps);
  const engagement = useSelector((state) => state.engagement.engagement);
  const selectedRegion = useSelector(
    (state) => state.engagement.selectedRegion
  );
  const stepIndex = useSelector((state) => state.engagement.stepIndex);
  const currentStep = useSelector((state) => state.engagement.currentStep);
  const forecasts = useSelector((state) => state.forecast.forecasts);
  const user = useSelector((state) => state.user.user);
  const forecastsDataLoading = useSelector(
    (state) => state.forecast.forecastsDataLoading
  );
  const [addForecastTableData, setAddForecastTable] = useState([]);
  const [isDataSaveInProgress, setIsDataSaveInProgress] = useState(false);
  const [tableDataValidationError, setTableDataValidationError] = useState("");
  const [applyError, setApplyError] = useState(false);
  const [lockedData, setLock] = useState();
  const [lockedBy, setLockedby] = useState();
  const [isLockerModalOpen, setLockerModalOpen] = useState(false);

  function handleCancelClick() {
    navigate(`/${routes.createNewEngagement}`, {
      state: {
        engagementId: engagement.id,
        regionId: selectedRegion.id,
      },
      gestureEnabled: false,
    });
  }

  async function applyChanges() {
    setIsDataSaveInProgress(true);
    const request = {
      id: engagement.id,
    };
    const response = await getEngagement({ request });
    if (response.data.forecast_inprogress === 0) {
      setIsDataSaveInProgress(false);
      setApplyError(true);
    } else {
      setApplyError(false);
      try {
        // await uploadFileToBlob({
        //   file: trigger,
        //   moduleName: "M2_Forecast_Calculation_Trigger",
        //   fileName: `TriggerForecast_${engagement.engagementCode}_${
        //     user.username
        //   }_${formarDateTime(new Date(), "ddMMyyyy HH:mm:ss")}.txt`,
        // });
        let request = {
          ModuleName: "m2forecast",
          FileName: `TriggerForecast_${engagement.engagementCode}_${
            user.username
          }_${formarDateTime(new Date(), "ddMMyyyy HH:mm:ss")}.txt`,
        };
        await uploadTextFile({ request });

        await applyForecast({ request: { engagementId: engagement.id } });

        if (engagement.skip_ForeCast === 1) {
          dispatch(setSkipForecast({ skipForecast: 0 }));
          await updateSkipForecast({
            request: {
              engagementId: engagement.id,
              skipvalue: 0,
            },
          });
        }
      } catch (error) {
        console.error(
          "Error updating batch id for forecast specification",
          error
        );
      }

      navigate(`/${routes.createNewEngagement}`, {
        state: {
          engagementId: engagement.id,
          regionId: selectedRegion.id,
        },
        gestureEnabled: false,
      });
    }
  }
  function AddForecastSpecification() {
    navigate(`${routes.addForecastSpecification}`, {
      state: { engagementId: engagement.id },
      gestureEnabled: false,
    });
  }
  // Function to check is the forecast is valid or not
  const checkIsvalidated = (isValidated, flag) => {
    if (isValidated === "N") {
      return "N";
    } else return flag;
  };
  // Functiton to for the forecasts to show in the table
  async function getAllRecordsBySpecification() {
    let uniqueBatchGroupIDs = [
      ...new Set(forecasts.map((element) => element.batchGroupID)),
    ];
    let rowData = [];
    let scopeArray = [];
    uniqueBatchGroupIDs.forEach((value) => {
      let emissionArray = [];
      let geoArray = [];
      let custArray = [];
      let orgArray = [];
      let uniqueRow = [];
      let name = "";
      let description = "";
      let scope = "";
      let dataType = "";
      let percentageValue = "";
      let startYear = 0;
      let endYear = 0;
      let batchGroupId = 0;
      let statusFlag = "";
      let id = 0;
      let activeFlag = "";
      let isValidated = "";
      forecasts.forEach((element) => {
        scopeArray.push(element.scope);
        if (element.batchGroupID === value) {
          name = element.name;
          description = element.description;
          scope = element.scope;
          dataType = element.data_type;
          percentageValue = element.forecast_percentage;
          startYear = element.startYear;
          endYear = element.endYear;
          batchGroupId = element.batchGroupID;
          statusFlag = element.statusFlag;
          id = element.id;
          activeFlag = element.activeFlag;
          isValidated = checkIsvalidated(isValidated, element.isValidated);
          let emissionSpec = element.category_level1
            ? element.category_level1 +
              (element.category_level2
                ? "-" +
                  element.category_level2 +
                  (element.category_level3 ? "-" + element.category_level3 : "")
                : element.category_level3
                ? "-" + element.category_level3
                : "")
            : "-";
          emissionArray.push(
            emissionSpec === "-"
              ? emissionSpec
              : scope + "-" + emissionSpec + ",emission"
          );
          geoArray.push(
            element.region_level1 +
              "-" +
              element.region_level2 +
              "-" +
              element.region_level3 +
              ",geo"
          );

          orgArray.push(
            element.entity_level1 +
              "-" +
              element.entity_level2 +
              "-" +
              element.entity_level3 +
              "-" +
              element.entity_asset +
              ",org"
          );
          let customSpec = element.custom_specification1
            ? element.custom_specification1 +
              (element.custom_specification2
                ? "-" +
                  element.custom_specification2 +
                  (element.custom_specification3
                    ? "-" + element.custom_specification3
                    : "")
                : element.custom_specification3
                ? "-" + element.custom_specification3
                : "")
            : "-";
          custArray.push(customSpec + ",custom");
        }
      });
      uniqueRow = [
        ...new Set(emissionArray),
        ...new Set(geoArray),
        ...new Set(orgArray),
        ...new Set(custArray),
      ];
      uniqueRow.push({
        name: name,
        description: description,
        scope: scope,
        dataType: dataType,
        percentageValue: percentageValue,
        startYear: startYear,
        endYear: endYear,
        batchGroupId: batchGroupId,
        statusFlag: statusFlag,
        id: id,
        activeFlag: activeFlag,
        isValidated: isValidated,
      });
      rowData.push(uniqueRow);
    });
    let rows = rowData.map((element) => {
      let emissionspec = [];
      let geospec = [];
      let orgspec = [];
      let customspec = [];
      for (let i = 0; i < element.length - 1; i++) {
        let record = element[i].split(",");
        if (record[1] === "emission") {
          emissionspec.push(record[0]);
        }
      }
      for (let i = 0; i < element.length - 1; i++) {
        let record = element[i].split(",");
        if (record[1] === "geo") {
          geospec.push(record[0]);
        }
      }
      for (let i = 0; i < element.length - 1; i++) {
        let record = element[i].split(",");
        if (record[1] === "org") {
          orgspec.push(record[0]);
        }
      }
      for (let i = 0; i < element.length - 1; i++) {
        let record = element[i].split(",");
        if (record[1] === "custom") {
          customspec.push(record[0]);
        }
      }
      return {
        forecastName: element[element.length - 1].name,
        forecastDescription: element[element.length - 1].description,
        dataType: element[element.length - 1].dataType,
        percentageValue: element[element.length - 1].percentageValue + "%",
        startYear: element[element.length - 1].startYear,
        endYear: element[element.length - 1].endYear,
        emissionSpec: emissionspec,
        geoSpec: geospec,
        orgSpec: orgspec,
        customSpec: customspec,
        fullRow: element,
        batchGroupId: element[element.length - 1].batchGroupId,
        statusFlag: element[element.length - 1].statusFlag,
        activeFlag: element[element.length - 1].activeFlag,
        isValidated: element[element.length - 1].isValidated,
      };
    });
    setAddForecastTable(rows);
  }

  async function onDelete() {
    const request = {
      engagementId: engagement.id,
    };
    dispatch(getForecasts(request));
  }

  useEffect(() => {
    if (engagement?.id) {
      const request = {
        engagementId: engagement.id,
      };

      dispatch(getForecasts(request));
    }
  }, [engagement?.id, dispatch]);

  useEffect(() => {
    if (engagement?.id) {
      getAllRecordsBySpecification();
    }
  }, [dispatch, engagement?.id, forecasts]);

  // to get a locked user flags
  useEffect(() => {
    const request = {
      LockedBy: user.username,
      ModuleName: "Engagement",
      EngagementID: engagement.id,
    };

    dispatch(getLockingData(request)).then((action) => {
      if (action) {
        const lockedData = action.payload.data.lockStatus;
        const lockedBy = action.payload.data.lockedBy;
        setLockedby(lockedBy);
        setLock(lockedData);
        if (lockedData === 2) {
          setLockerModalOpen(true);
        }
      } else {
      }
    });
  }, [dispatch, lockedData]);

  return (
    <div className="add-forecast">
      <div className="add-forecast-eng-header">
        <div className="add-forecast-eng-header-title">
          {steps[currentStep]?.stepHeading}
        </div>
        <div className="add-forecast-eng-header-stepper">
          <Stepper
            steps={steps}
            currentStep={currentStep}
            stepIndex={stepIndex}
          />
        </div>
      </div>
      <div className="add-forecast-content">
        <div className="add-forecast-body">
          <div className="add-forecast-body-upload-year">
            <span className="add-forecast-body-upload-year-label">
              {foreStartYear}
            </span>
            <span className="add-forecast-body-upload-year-label-l">
              {engagement.mostRecentYear}
            </span>
            <span className="add-forecast-body-upload-year-label">
              {foreEndYear}
            </span>
            <span className="add-forecast-body-upload-year-label-l">
              {engagement.foreCastEndYear}
            </span>
          </div>
          <div className="add-forecast-header">
            <span className="add-forecast-header-main">{addForecast}</span>

            <div className="add-forecast-header-button">
              <Button
                variant="primary-alt"
                onClick={AddForecastSpecification}
                disabled={
                  isDataSaveInProgress ||
                  (lockedData === 1 && lockedBy !== user.username)
                }
                tooltip={addforecastTooltip}
              >
                <AddIcon />
                {AddForecastBtn}
              </Button>
            </div>
          </div>
          <div className="add-forecast-body-table">
            <Table
              rowData={addForecastTableData.filter(
                (element) => element.activeFlag === "A"
              )}
              colDefs={[
                {
                  field: "actions",
                  cellRenderer: CustomEditComponent,
                  cellRendererParams: () => {
                    return {
                      onDelete: onDelete,
                    };
                  },
                  filter: true,
                  headerName: "",
                  flex: 1,
                  width: 150,
                  suppressMovable: true,
                  pinned: "left",
                },
                {
                  field: "forecastName",
                  filter: true,
                  headerName: addForecastTableHeaderForecastName,
                  headerTooltip: addForecastTableHeaderForecastName,
                  minWidth: 200,
                  flex: 1,
                  suppressMovable: true,
                  pinned: "left",
                },
                {
                  field: "forecastDescription",
                  filter: true,
                  headerName: addForecastTableHeaderForecastDesc,
                  headerTooltip: addForecastTableHeaderForecastDesc,
                  minWidth: 300,
                  flex: 1,
                  suppressMovable: true,
                },
                {
                  field: "emissionSpec",
                  filter: true,
                  headerName: addForecastTableHeaderEmission,
                  headerTooltip: addForecastTableHeaderEmission,
                  cellRenderer: CustomChipComponent,
                  cellRendererParams: () => {
                    return {
                      spec: "emission",
                    };
                  },
                  minWidth: 400,
                  flex: 1,
                  suppressMovable: true,
                },
                {
                  field: "geoSpec",
                  filter: true,
                  headerName: addForecastTableHeaderGeo,
                  headerTooltip: addForecastTableHeaderGeo,
                  cellRenderer: CustomChipComponent,
                  cellRendererParams: () => {
                    return {
                      spec: "geo",
                    };
                  },
                  minWidth: 400,
                  flex: 1,
                  suppressMovable: true,
                },
                {
                  field: "orgSpec",
                  filter: true,
                  headerName: addForecastTableHeaderOrg,
                  headerTooltip: addForecastTableHeaderOrg,
                  cellRenderer: CustomChipComponent,
                  cellRendererParams: () => {
                    return {
                      spec: "org",
                    };
                  },
                  minWidth: 400,
                  flex: 1,
                  suppressMovable: true,
                },
                {
                  field: "customSpec",
                  filter: true,
                  headerName: addForecastTableHeaderCustom,
                  headerTooltip: addForecastTableHeaderCustom,
                  cellRenderer: CustomChipComponent,
                  cellRendererParams: () => {
                    return {
                      spec: "custom",
                    };
                  },
                  minWidth: 300,
                  flex: 1,
                  suppressMovable: true,
                },
                {
                  field: "dataType",
                  filter: true,
                  headerName: addForecastTableHeaderDataType,
                  headerTooltip: addForecastTableHeaderDataType,
                  minWidth: 300,
                  flex: 1,
                  suppressMovable: true,
                },
                {
                  field: "percentageValue",
                  filter: true,
                  headerName: addForecastTableHeaderDataPercentageValue,
                  headerTooltip: addForecastTableHeaderDataPercentageValue,
                  flex: 1,
                  minWidth: 200,
                  suppressMovable: true,
                },
                {
                  field: "startYear",
                  filter: true,
                  headerName: addForecastTableHeaderDataStartYear,
                  headerTooltip: addForecastTableHeaderDataStartYear,
                  flex: 1,
                  minWidth: 200,
                  suppressMovable: true,
                },
                {
                  field: "endYear",
                  filter: true,
                  headerName: addForecastTableHeaderDataEndYear,
                  headerTooltip: addForecastTableHeaderDataEndYear,
                  flex: 1,
                  minWidth: 200,
                  suppressMovable: true,
                },
              ]}
              rowClassRules={{
                "add-forecast-table-pending-validation": (params) => {
                  return params.data.isValidated === "N";
                },
              }}
              draggable={false}
              loading={forecastsDataLoading}
            />
            {tableDataValidationError ? (
              <MotifErrorMessage className="decarb-input-error-message">
                {tableDataValidationError}
              </MotifErrorMessage>
            ) : (
              <div className="decarb-input-hide-error">No Error</div>
            )}
          </div>
        </div>
      </div>
      <div className="add-forecast-footer">
        <Button
          variant="secondary"
          onClick={handleCancelClick}
          disabled={isDataSaveInProgress}
        >
          {CancelBtn}
        </Button>
        <div className="add-forecast-footer-nav-buttons">
          <LoadingIndicatorEmbed show={isDataSaveInProgress}>
            <Button
              variant="primary"
              onClick={applyChanges}
              disabled={
                isDataSaveInProgress ||
                (lockedData === 1 && lockedBy !== user.username) ||
                addForecastTableData.filter(
                  (element) => element.isValidated === "N"
                ).length > 0 ||
                addForecastTableData.length === 0
              }
            >
              {ApplyBtn}
            </Button>
          </LoadingIndicatorEmbed>
          {applyError ? (
            <MotifErrorMessage>{applyErrorMessageForecast}</MotifErrorMessage>
          ) : (
            ""
          )}
        </div>
      </div>

      {lockedData === 2 && lockedBy === user.username && (
        <LockerModal
          isOpen={isLockerModalOpen}
          onClose={() => setLockerModalOpen(false)}
        />
      )}
    </div>
  );
}
