import React, { useState, useEffect } from "react";
import {
  SaveBtn,
  addForecastSpecificationDataType,
  addForecastSpecificationDataTypeOptions,
  cumulative,
  endYr,
  simulate,
  strtYr,
  unitDropdown,
  unitTooltip,
  simulateValue,
  addForecastSpecificationStartYearGreaterThanMRY,
  addForecastSpecificationStartYearLessThanFEY,
  endYearGreaterThanFEYear,
  endYearLessThanStartYear,
  ruleName,
  decimalsNotAllowedError,
  relativePercentageError,
  only2DecimalsAllowed,
  negativeNotallowed,
  emissionArray,
  targetHierarchy,
  emisFactorUnit,
  actDataUnit,
  emisFactValue,
  actDataValue,
  targetHierarchyValues,
  maxlimitExceeded,
  onlyNumbersAllowed,
  ruleNameTooltip,
  reduceDataTypetooltip,
  replaceDataTypeTooltip,
  compensateDataTypeTooltip,
  cumulativeTooltip,
  emissionsValuetooltip,
  emissionsStartyearTooltip,
  emissionsEndYeartooltip,
  targetHierarchyTooltip,
  activityDataValueTooltip,
  emissionFactorTooltip,
  activityDataRelativeError,
  ruleDescription,
  addGMPSource,
  edittype,
  editTypeOptions,
  absoluteorRelative,
  projectDetailsBusinessRulesChangeType,
  editExtend,
  cumulativedropdown,
  aDValidationError,
  eFValidationError,
  transferOrCopy,
  transferCopyOptions,
  defineNewHrchy,
  ruleDescTooltip,
  sourceRuleTooltip,
  editTypeTooltip,
  forecastDataTypeTooltip,
  changeTypeTooltip,
  transferADTooltip,
  activityUnitTooltip,
  emissionFactorunitTooltip,
} from "../../../util/constants";
import { Select } from "../../Select/Select";
import {
  AreaSeacrhIcon,
  EmissionIcon,
  SaveIcon,
  SimulateIcon,
  WarningIconOrange,
} from "../../../icons";
import { Button } from "../../Button/Button";
import { Tooltip } from "../../CommonUIComponents/Tooltip/Tooltip";
import { Input } from "../../Input/Input";
import { useDispatch, useSelector } from "react-redux";
import {
  setSimulateDataOnTable,
  setSimulateFlag,
  setGraphSimulateFlag,
  setSimulateDataOnGraph,
  setReduceDefautViewData,
  getLockingData,
  setTogglechecked,
  resetManualEditRules,
  setM3Step2Loader,
  setIsManualEdit,
  setSelectedDataType,
  setExtendNewHierarchy,
} from "../../../store/slices/projectSlice";
import {
  getM3TableGraphData,
  manualEditM3Step2,
  SaveM3Step2ReplaceData,
} from "../../../store/services/project.service";
import { LoadingIndicatorEmbed } from "../../LoadingIndicator/LoadingIndicatorEmbed";
import { TextArea } from "../../TextArea/TextArea";
import { EmissionFilterModal } from "../EmissionsFilterModal/EmissionFilterModal";
import "../Emissions.scss";
import { ExtendModal } from "./ExtendModal";

export function SimulateFields() {
  const dispatch = useDispatch();
  const engagement = useSelector((state) => state.engagement.engagement);
  const project = useSelector((state) => state.project.project);
  const user = useSelector((state) => state.user.user);
  const filtersPayload = useSelector(
    (state) => state.project.appliedFilterPayload
  );
  const reduceDefaultViewData = useSelector(
    (state) => state.project.defaultViewData
  );
  const isResetApplied = useSelector((state) => state.project.isResetApplied);
  const [dataType, setDataType] = useState("Activity data");
  const [cumlative, setCumulative] = useState("");
  const [unitSelected, setUnit] = useState();
  const [value, setValue] = useState("");
  const [startYear, setStartYear] = useState("");
  const [endYear, setEndYear] = useState("");
  const [valueErrorMessage, setValueErrorMessage] = useState("");
  const [valueError, setValueError] = useState(false);
  const [startYearErrorMessage, setStartYearErrorMessage] = useState("");
  const [endYearErrorMessage, setEndYearErrorMessage] = useState("");
  const decimalNumberRegExp = /^-?\d{1,13}(\.?\d{1,2})?$/;
  const onlyNumbersRegExp = /^-?\d{1,11}$/;
  const onlyPositiveRegExp = /^\d{1,15}$/;
  const [showstartYearError, setShowStartYearError] = useState(false);
  const [showendYearError, setShowEndYearError] = useState(false);
  const [rule, setRuleName] = useState("");
  const [ruleDesc, setRuleDesc] = useState("");
  const [editType, setEditType] = useState("Batch edit");
  const [isSimulateApplied, setSimulateApplied] = useState(false);
  const [isSimulateBtnDisable, setSimulateBtnDisable] = useState(true);
  const [simulateLoader, setSimulateloader] = useState(false);
  const [saveLoader, setSaveLoader] = useState(false);
  const [trgtHrchy, setTrgtHrchy] = useState("");
  const [activityDataValue, setActivityDataValue] = useState("");
  const [emissionFactorValue, setEmissionFactorValue] = useState("");
  const [activityDataUnit, setActivityDataUnit] = useState("");
  const [emissionFactorUnit, setEmissionFactorUnit] = useState("");
  const [trnsfrOrCopy, setTransferCopy] = useState("");
  const [activityDataValueErrorMessage, setActivityDataValueErrorMessage] =
    useState("");
  const [isActivityDataValueError, setisActivityDataValueError] =
    useState(false);
  const [emissionFactorValueErrorMessage, setEmissionFactorValueErrorMessage] =
    useState("");
  const [isEmissionFactorValueError, setIsEmissionFactorValueError] =
    useState(false);
  const [replaceChange, setReplaceChangeType] = useState("Edit");
  const [lockedData, setLock] = useState();
  const [lockedBy, setLockedby] = useState();
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [isExtendModalOpen, setExtendModalOpen] = useState(false);
  const [source, setSource] = useState("");
  const selectedAllFilters = useSelector(
    (state) => state.project.selectedUIAllFilters
  );
  const dropdownMetadataFinal = useSelector(
    (state) => state.project.dropdownData
  );
  const [metaData, setMetadata] = useState();
  const validationFlag = useSelector(
    (state) => state.project.unitValidationFlag
  );
  const [showUnitValidationError, setUnitValidationError] = useState(false);
  const manualEditRules = useSelector((state) => state.project.manualEditRules);
  const newExtendhierarchy = useSelector(
    (state) => state.project.extendNewHierarchy
  );

  //Check for the Simulate and Save button validation
  useEffect(() => {
    checkSimulateEnableValidation();
  }, [
    dataType,
    cumlative,
    unitSelected,
    value,
    startYear,
    endYear,
    rule,
    editType,
    manualEditRules,
    activityDataUnit,
    activityDataValue,
    emissionFactorValue,
    trgtHrchy,
    newExtendhierarchy,
    validationFlag,
  ]);

  //Set the data Type selection
  useEffect(() => {
    if (project.projectType === 3) {
      dispatch(setSelectedDataType({ dataType: "Emissions" }));
      setDataType("Emissions");
    } else {
      dispatch(setSelectedDataType({ dataType: "Activity data" }));
      setDataType("Activity data");
    }
  }, [project.projectType]);

  //Code to get the dropdownMetadata payload
  useEffect(() => {
    setMetadata(dropdownMetadataFinal);
  }, [dropdownMetadataFinal]);

  //Unit Validation flag  error message display logic
  useEffect(() => {
    if (
      (dataType === "Activity data" && validationFlag?.isDuplicate === "Y") ||
      (dataType === "Emission factor" &&
        (validationFlag?.isDuplicate === "Y" ||
          validationFlag?.eF_VALUE_COUNT === "Y"))
    ) {
      setUnitValidationError(true);
    } else if (
      dataType === "" &&
      (validationFlag?.isDuplicate === "Y" ||
        validationFlag?.eF_VALUE_COUNT === "Y")
    ) {
      setUnitValidationError(true);
    } else {
      setUnitValidationError(false);
    }
  }, [validationFlag, dataType]);

  //resetting Fields after every data selection

  useEffect(() => {
    resetSimulatefields();
  }, [reduceDefaultViewData]);
  // Validation for Value field
  const checkValueValidation = (value) => {
    if (
      (dataType === "Activity data" || dataType === "Emissions") &&
      unitSelected === "Absolute"
    ) {
      if (value === "-") {
        setValueError(false);
        setValueErrorMessage("");
      } else if (!onlyNumbersRegExp.test(value)) {
        setValueErrorMessage(decimalsNotAllowedError);
        setValueError(true);
      } else {
        setValueError(false);
        setValueErrorMessage("");
      }
    }
    if (
      (dataType === "Activity data" ||
        dataType === "Emission factor" ||
        dataType === "Emissions") &&
      unitSelected === "Relative"
    ) {
      if (value === "" || value === "-") {
        setValueError(false);
        setValueErrorMessage("");
      } else if (value < -100 || value > 9999.99) {
        setValueErrorMessage(relativePercentageError);
        setValueError(true);
      } else if (!decimalNumberRegExp.test(value)) {
        setValueError(true);
        setValueErrorMessage(only2DecimalsAllowed);
      } else {
        setValueError(false);
        setValueErrorMessage("");
      }
    }
    if (dataType === "Emission factor" && unitSelected === "Absolute") {
      setValueError(false);
      setValueErrorMessage("");
      if (value === "" || value === "-") {
        setValueError(false);
        setValueErrorMessage("");
      } else if (value < 0) {
        setValueErrorMessage(negativeNotallowed);
        setValueError(true);
      } else if (!decimalNumberRegExp.test(value)) {
        if (!decimalNumberRegExp.test(value)) {
          setValueErrorMessage(only2DecimalsAllowed);
          setValueError(true);
        } else if (!onlyNumbersRegExp.test(value)) {
          setValueErrorMessage(onlyNumbersAllowed);
          setValueError(true);
        }
      } else if (parseFloat(value) > 99999999999) {
        setValueErrorMessage(maxlimitExceeded);
        setValueError(true);
      } else {
        setValueError(false);
        setValueErrorMessage("");
      }
    }
  };

  // Start year validation check
  const checkStartYearValidation = (value) => {
    const mostRecentYear = engagement.mostRecentYear;
    if (value && parseFloat(value) <= mostRecentYear) {
      setStartYearErrorMessage(addForecastSpecificationStartYearGreaterThanMRY);
      setShowStartYearError(true);
    } else if (value && parseFloat(value) > engagement?.foreCastEndYear) {
      setStartYearErrorMessage(addForecastSpecificationStartYearLessThanFEY);
      setShowStartYearError(true);
    } else {
      setStartYearErrorMessage("");
      setShowStartYearError(false);
    }
  };

  // End year validation check
  const checkEndYearValidation = (value) => {
    const forecastEndYear = engagement.foreCastEndYear;
    if (value && parseFloat(value) > forecastEndYear) {
      setEndYearErrorMessage(endYearGreaterThanFEYear);
      setShowEndYearError(true);
    } else if (value && startYear && parseFloat(value) < startYear) {
      setEndYearErrorMessage(endYearLessThanStartYear);
      setShowEndYearError(true);
    } else {
      setEndYearErrorMessage("");
      setShowEndYearError(false);
    }
  };

  //  Activity data value validation for Replace Extend
  const checkActivityDataValue = (value) => {
    if (unitSelected === "Absolute") {
      if (value == "") {
        setActivityDataValueErrorMessage("");
        setisActivityDataValueError(true);
      } else if (!onlyPositiveRegExp.test(value)) {
        if (value < 0 || value === "-") {
          setActivityDataValueErrorMessage(negativeNotallowed);
          setisActivityDataValueError(true);
        } else if (!onlyPositiveRegExp.test(value)) {
          setActivityDataValueErrorMessage(decimalsNotAllowedError);
          setisActivityDataValueError(true);
        }
      } else if (value > 99999999999) {
        setActivityDataValueErrorMessage(maxlimitExceeded);
        setisActivityDataValueError(true);
      } else {
        setActivityDataValueErrorMessage("");
        setisActivityDataValueError(false);
      }
    } else {
      if (value === "") {
        setActivityDataValueErrorMessage("");
        setisActivityDataValueError(false);
      } else if (value > 100) {
        setActivityDataValueErrorMessage(activityDataRelativeError);
        setisActivityDataValueError(true);
      } else if (!onlyPositiveRegExp.test(value)) {
        if (value > 100) {
          setActivityDataValueErrorMessage(activityDataRelativeError);
          setisActivityDataValueError(true);
        } else if (value < 0) {
          setActivityDataValueErrorMessage(negativeNotallowed);
          setisActivityDataValueError(true);
        } else if (!decimalNumberRegExp.test(value)) {
          setActivityDataValueErrorMessage(only2DecimalsAllowed);
          setisActivityDataValueError(true);
        } else {
          setActivityDataValueErrorMessage("");
          setisActivityDataValueError(false);
        }
      } else {
        setActivityDataValueErrorMessage("");
        setisActivityDataValueError(false);
      }
    }
  };

  // Emission factor value validations for Replace Extend scenario
  const checkEmissionFactorValue = (value) => {
    if (unitSelected === "Absolute") {
      if (value === "") {
        setEmissionFactorValueErrorMessage("");
        setIsEmissionFactorValueError(false);
      } else if (value < 0 || value === "-") {
        setEmissionFactorValueErrorMessage(negativeNotallowed);
        setIsEmissionFactorValueError(true);
      } else if (value > 99999999999) {
        setEmissionFactorValueErrorMessage(maxlimitExceeded);
        setIsEmissionFactorValueError(true);
      } else if (!decimalNumberRegExp.test(value)) {
        setEmissionFactorValueErrorMessage(only2DecimalsAllowed);
        setIsEmissionFactorValueError(true);
      } else {
        setEmissionFactorValueErrorMessage("");
        setIsEmissionFactorValueError(false);
      }
    } else {
      if (value === "") {
        setEmissionFactorValueErrorMessage("");
        setIsEmissionFactorValueError(false);
      } else if (value < -100 || value > 9999.99) {
        setEmissionFactorValueErrorMessage(relativePercentageError);
        setIsEmissionFactorValueError(true);
      } else {
        setEmissionFactorValueErrorMessage("");
        setIsEmissionFactorValueError(false);
      }
    }
  };

  // Onchange of Input fields
  const handleOnChange = (value, fieldName) => {
    if (fieldName === "dataType") {
      setDataType(value);
      dispatch(setSelectedDataType({ dataType: value }));
      setValue("");
    } else if (fieldName === "cumulative") {
      setCumulative(value);
      setValue("");
    } else if (fieldName === "unit") {
      setUnit(value);
      setValue("");
      setActivityDataUnit("");
      setEmissionFactorUnit("");
    } else if (fieldName === "value") {
      checkValueValidation(value);
      setValue(value);
    } else if (fieldName === "startYear") {
      checkStartYearValidation(value);
      setStartYear(value);
    } else if (fieldName === "endYear") {
      checkEndYearValidation(value);
      setEndYear(value);
    } else if (fieldName === "ruleName") {
      setRuleName(value);
    } else if (fieldName === "activityDataValue") {
      checkActivityDataValue(value);
      setActivityDataValue(value);
    } else if (fieldName === "emissionFactorValue") {
      checkEmissionFactorValue(value);
      setEmissionFactorValue(value);
    } else if (fieldName === "activityDataUnit") {
      setActivityDataUnit(value);
      if (value !== "") {
        setEmissionFactorUnit("kgCO2e/" + value);
      } else {
        setEmissionFactorUnit("");
      }
    } else if (fieldName === "targetHierarchy") {
      setTrgtHrchy(value);
      //   checkTargetHierarchy(value); needs to be changed during extend implementation
    } else if (fieldName === "transferOrCopy") {
      setTransferCopy(value);
    } else if (fieldName === "ruleDescription") {
      setRuleDesc(value);
    } else if (fieldName === "source") {
      setSource(value);
    } else if (fieldName === "editType") {
      setEditType(value);
      if (value === "Manual edit") {
        dispatch(setIsManualEdit({ isManualEdit: true }));
      } else {
        dispatch(setIsManualEdit({ isManualEdit: false }));
      }
    } else if (fieldName === "replaceChangeType") {
      setReplaceChangeType(value);
      if (value === "Extend") {
        dispatch(setTogglechecked({ isExtend: true }));
      } else {
        dispatch(setTogglechecked({ isExtend: false }));
      }
    }
  };

  const checkSimulateEnableValidation = () => {
    if (editType === "Batch edit") {
      if (replaceChange === "Extend") {
        if (
          rule &&
          trgtHrchy &&
          activityDataUnit &&
          activityDataValue &&
          emissionFactorUnit &&
          emissionFactorValue &&
          !isActivityDataValueError &&
          !isEmissionFactorValueError &&
          unitSelected &&
          cumlative &&
          startYear &&
          endYear &&
          !showstartYearError &&
          !showendYearError &&
          newExtendhierarchy.saved
        ) {
          setSimulateBtnDisable(false);
        } else {
          setSimulateBtnDisable(true);
        }
      } else if (
        dataType &&
        cumlative &&
        unitSelected &&
        value &&
        startYear &&
        endYear &&
        rule &&
        !valueError &&
        !showstartYearError &&
        !showendYearError
      ) {
        if (project.projectType === 1 || project.projectType === 2) {
          if (unitSelected === "Absolute" && dataType === "Activity data") {
            if (validationFlag.isDuplicate === "Y") {
              setSimulateBtnDisable(true);
            } else {
              setSimulateBtnDisable(false);
            }
          } else if (
            unitSelected === "Absolute" &&
            dataType === "Emission factor"
          ) {
            if (
              validationFlag.isDuplicate === "Y" ||
              validationFlag.eF_VALUE_COUNT === "Y"
            ) {
              setSimulateBtnDisable(true);
            } else {
              setSimulateBtnDisable(false);
            }
          } else {
            setSimulateBtnDisable(false);
          }
        } else {
          setSimulateBtnDisable(false);
        }
      } else {
        setSimulateBtnDisable(true);
      }
    }
    if (editType === "Manual edit") {
      if (dataType && rule && manualEditRules.length > 0) {
        setSimulateBtnDisable(false);
      } else {
        setSimulateBtnDisable(true);
      }
    }
  };

  const openFiltersModal = () => {
    setIsFilterOpen(true);
    // resetSimulatefields();
  };

  //Closing the data selection
  const onfilterClose = () => {
    setIsFilterOpen(false);
  };

  //getSelectedColumns from Data Selection

  const getselectedCols = (field) => {
    let array = [];
    if (field === "Emission") {
      array.push("scope");
      newExtendhierarchy.category1 && array.push("category_level1");
      newExtendhierarchy.category2 && array.push("category_level2");
      newExtendhierarchy.category3 && array.push("category_level3");
    } else if (field === "Geography") {
      newExtendhierarchy.region1 && array.push("region_level1");
      newExtendhierarchy.region2 && array.push("region_level2");
      newExtendhierarchy.region3 && array.push("region_level3");
    } else if (field === "Organisational") {
      newExtendhierarchy.entity1 && array.push("entity_level1");
      newExtendhierarchy.entity2 && array.push("entity_level2");
      newExtendhierarchy.entity3 && array.push("entity_level3");
      newExtendhierarchy.entity4 && array.push("entity_level4");
    }
    return array;
  };

  //getting unselectedCols
  const getUnselectedCols = (field) => {
    let array = [];
    if (field === "Emission") {
      newExtendhierarchy.category2 === undefined &&
        array.push("category_level2");
      newExtendhierarchy.category3 === undefined &&
        array.push("category_level3");
    } else if (field === "Geography") {
      newExtendhierarchy.region2 === undefined && array.push("region_level2");
      newExtendhierarchy.region3 === undefined && array.push("region_level3");
    } else if (field === "Organisational") {
      newExtendhierarchy.entity2 === undefined && array.push("entity_level2");
      newExtendhierarchy.entity3 === undefined && array.push("entity_level3");
      newExtendhierarchy.entity4 === undefined && array.push("entity_level4");
    }
    return array;
  };

  //getting New Hierarchy values
  const getNewHierarchy = (field) => {
    console.log(newExtendhierarchy);

    let string = "";
    if (field === "Emission") {
      string = newExtendhierarchy.scope;
      if (newExtendhierarchy.category1) {
        string = string + "," + newExtendhierarchy.category1;
      }
      if (newExtendhierarchy.category2) {
        string = string + "," + newExtendhierarchy.category2;
      }
      if (newExtendhierarchy.category3) {
        string = string + "," + newExtendhierarchy.category3;
      }
    } else if (field === "Geography") {
      string = newExtendhierarchy.region1;
      if (newExtendhierarchy.region2) {
        string = string + "," + newExtendhierarchy.region2 + ",";
      }
      if (newExtendhierarchy.region3) {
        string = string + "," + newExtendhierarchy.region3;
      }
    } else if (field === "Organisational") {
      string = newExtendhierarchy.entity1;
      if (newExtendhierarchy.entity2) {
        string = string + "," + newExtendhierarchy.entity2;
      }
      if (newExtendhierarchy.entity3) {
        string = string + "," + newExtendhierarchy.entity3;
      }
      if (newExtendhierarchy.entity4) {
        string = string + "," + newExtendhierarchy.entity4;
      }
    }
    return string;
  };

  //Simulate function
  const onSimulate = async () => {
    dispatch(setGraphSimulateFlag({ isGraphSimulate: false }));
    setSimulateloader(true);
    dispatch(setM3Step2Loader({ step2GraphTableLoader: true }));
    if (editType === "Manual edit") {
      onManualEdit("Simulate");
    } else {
      if (project.projectType === 2) {
        if (replaceChange === "Extend") {
          createExtendRule("Simulate");
        } else {
          let simulateSaveReplacePayload = {
            ...simulateSavePayload,
            target_Name: "",
            view_type: "Simulate",
            ruleType: "Simulate",
            changeType: "Edit",
            activitydata_value: "",
            activitydata_Unit: "",
            emissiondata_value: "",
            emissiondata_unit: "",
            targetHierarchy: "",
            transferAD: "N",
            columnNames: filtersPayload.columnNames,
            selectedcolumns: [],
            nonselectedcolumns: [],
          };
          const data = await SaveM3Step2ReplaceData({
            simulateSaveReplacePayload,
          });
          dispatch(
            setSimulateDataOnTable({ simulateTableData: data.tableData })
          );
          dispatch(setGraphSimulateFlag({ isGraphSimulate: true }));
          dispatch(
            setSimulateDataOnGraph({ simulateGraphData: data.graphData })
          );
        }
      } else {
        let request = filtersPayload;
        simulateSavePayload["view_type"] = "Simulate";
        simulateSavePayload["columnNames"] = request.columnNames;
        const data = await getM3TableGraphData({ simulateSavePayload });
        dispatch(setSimulateDataOnTable({ simulateTableData: data.tableData }));
        dispatch(setGraphSimulateFlag({ isGraphSimulate: true }));
        dispatch(setSimulateDataOnGraph({ simulateGraphData: data.graphData }));
      }
      dispatch(setSimulateFlag({ isSimulate: true }));
      setSimulateApplied(true);
      setSimulateloader(false);
      dispatch(setM3Step2Loader({ step2GraphTableLoader: false }));
    }
  };

  //Save function
  const onSave = async () => {
    try {
      setSaveLoader(true);
      dispatch(setGraphSimulateFlag({ isGraphSimulate: false }));
      dispatch(setM3Step2Loader({ step2GraphTableLoader: true }));
      setSimulateloader(false);
      if (editType === "Manual edit") {
        onManualEdit("Save");
      } else {
        if (project.projectType === 2) {
          if (replaceChange === "Extend") {
            createExtendRule("Save");
          } else {
            let simulateSaveReplacePayload = {
              ...simulateSavePayload,
              target_Name: "",
              view_type: "M3-ApplyFilter",
              changeType: "Edit",
              ruleType: "Save",
              activitydata_value: "",
              activitydata_Unit: "",
              emissiondata_value: "",
              emissiondata_unit: "",
              targetHierarchy: "",
              transferAD: "N",
              columnNames: filtersPayload.columnNames,
              selectedcolumns: [],
              nonselectedcolumns: [],
            };
            const data = await SaveM3Step2ReplaceData({
              simulateSaveReplacePayload,
            });
            dispatch(setReduceDefautViewData({ defaultViewData: data }));
            setSaveLoader(false);
            dispatch(setM3Step2Loader({ step2GraphTableLoader: false }));
            setSimulateloader(false);
            setSimulateApplied(false);
            setSimulateBtnDisable(true);
          }
        } else {
          let request = filtersPayload;
          simulateSavePayload["view_type"] = "M3-ApplyFilter";
          simulateSavePayload["columnNames"] = request.columnNames;
          const data = await getM3TableGraphData({ simulateSavePayload });
          dispatch(setReduceDefautViewData({ defaultViewData: data }));
          setSaveLoader(false);
          dispatch(setM3Step2Loader({ step2GraphTableLoader: false }));
          setSimulateloader(false);
          setSimulateApplied(false);
          setSimulateBtnDisable(true);
        }
      }
    } catch (error) {
      setSaveLoader(false);
      dispatch(setM3Step2Loader({ step2GraphTableLoader: false }));
      setSimulateloader(false);
      setSimulateApplied(false);
      setSimulateBtnDisable(true);
    }
  };

  const onManualEdit = async (type) => {
    let unique = [...new Set(manualEditRules.map((x) => x.rowIndex))];
    let payload = [];
    unique.forEach((row) => {
      let unique_rules = [...new Set(manualEditRules.map((x) => x.start_year))];
      let array = [];
      let payloadObject = {
        engagementCode: engagement.engagementCode,
        engagementId: engagement.id,
        createdby: user.username,
        modifiedby: user.username,
        project_type:
          project.projectType === 1
            ? "Reduce"
            : project.projectType === 2
            ? "Replace"
            : "Compensate",
        project_id: project.projectId,
        view_type: "M3-ApplyFilter",
        data_type: dataType,
        calculation_type:
          dataType === "Emission factor" ? "Absolute" : "Relative",
        cumulative_flag: "N",
        name: rule,
        rule_type: type,
        ruledescription: ruleDesc,
        source: source,
        manualEditYearValue: array,
        columnNames: filtersPayload.columnNames,
        dropDownMetaData: dropdownMetadataFinal,
      };
      unique_rules.forEach((year) => {
        let x = false;
        for (let i = manualEditRules.length - 1; i >= 0; i--) {
          if (
            manualEditRules[i]["start_year"] === year &&
            row === manualEditRules[i]["rowIndex"] &&
            !x
          ) {
            array.push({
              start_year: parseInt(year),
              end_year: parseInt(year),
              abatement_value: parseFloat(
                manualEditRules[i]["abatement_value"]
              ),
            });
            x = true;
            payloadObject.datatype_unit = manualEditRules[i]["data"]["unit"];
            payloadObject.dropDownMetaData = [
              {
                columnType: "Scope",
                columnValue: manualEditRules[i]["data"]["scope"],
              },
              {
                columnType: "Category_level1",
                columnValue:
                  manualEditRules[i]["data"]["category_level1"] === ""
                    ? "All"
                    : manualEditRules[i]["data"]["category_level1"],
              },
              {
                columnType: "Category_level2",
                columnValue:
                  manualEditRules[i]["data"]["category_level2"] === ""
                    ? "All"
                    : manualEditRules[i]["data"]["category_level2"],
              },
              {
                columnType: "Category_level3",
                columnValue:
                  manualEditRules[i]["data"]["category_level3"] === ""
                    ? "All"
                    : manualEditRules[i]["data"]["category_level3"],
              },
              {
                columnType: "Region_level1",
                columnValue:
                  manualEditRules[i]["data"]["region_level1"] === ""
                    ? "All"
                    : manualEditRules[i]["data"]["region_level1"],
              },
              {
                columnType: "Region_level2",
                columnValue:
                  manualEditRules[i]["data"]["region_level2"] === ""
                    ? "All"
                    : manualEditRules[i]["data"]["region_level2"],
              },
              {
                columnType: "Region_level3",
                columnValue:
                  manualEditRules[i]["data"]["region_level3"] === ""
                    ? "All"
                    : manualEditRules[i]["data"]["region_level3"],
              },
              {
                columnType: "Entity_level1",
                columnValue:
                  manualEditRules[i]["data"]["entity_level1"] === ""
                    ? "All"
                    : manualEditRules[i]["data"]["entity_level1"],
              },
              {
                columnType: "Entity_level2",
                columnValue:
                  manualEditRules[i]["data"]["entity_level2"] === ""
                    ? "All"
                    : manualEditRules[i]["data"]["entity_level2"],
              },
              {
                columnType: "Entity_level3",
                columnValue:
                  manualEditRules[i]["data"]["entity_level3"] === ""
                    ? "All"
                    : manualEditRules[i]["data"]["entity_level3"],
              },
              {
                columnType: "Entity_level4",
                columnValue:
                  manualEditRules[i]["data"]["entity_level4"] === ""
                    ? "All"
                    : manualEditRules[i]["data"]["entity_level4"],
              },
              {
                columnType: "Custom_specification1",
                columnValue:
                  manualEditRules[i]["data"]["custom_request1"] === ""
                    ? "All"
                    : manualEditRules[i]["data"]["custom_request1"],
              },
              {
                columnType: "Custom_specification2",
                columnValue:
                  manualEditRules[i]["data"]["custom_request2"] === ""
                    ? "All"
                    : manualEditRules[i]["data"]["custom_request2"],
              },
              {
                columnType: "Custom_specification3",
                columnValue:
                  manualEditRules[i]["data"]["custom_request3"] === ""
                    ? "All"
                    : manualEditRules[i]["data"]["custom_request3"],
              },
            ];
          }
        }
      });
      payload.push(payloadObject);
    });
    const data = await manualEditM3Step2({ payload });
    if (type === "Simulate") {
      setSimulateApplied(true);
      dispatch(setSimulateFlag({ isSimulate: true }));
      dispatch(setSimulateDataOnTable({ simulateTableData: data.tableData }));
      dispatch(setGraphSimulateFlag({ isGraphSimulate: true }));
      dispatch(setSimulateDataOnGraph({ simulateGraphData: data.graphData }));
      setSimulateloader(false);
      // setSimulateBtnDisable(true);
      dispatch(setM3Step2Loader({ step2GraphTableLoader: false }));
    } else {
      setSimulateApplied(false);
      dispatch(setSimulateFlag({ isSimulate: false }));
      dispatch(setGraphSimulateFlag({ isGraphSimulate: false }));
      dispatch(setReduceDefautViewData({ defaultViewData: data }));
      setSimulateBtnDisable(true);
      dispatch(resetManualEditRules({ array: [] }));
      dispatch(setM3Step2Loader({ step2GraphTableLoader: false }));
      setSaveLoader(false);
    }
  };

  let simulateSavePayload = {
    engagementCode: engagement.engagementCode,
    engagementId: engagement.id,
    name: rule,
    Ruledescription: ruleDesc,
    Source: source,
    calculation_type: unitSelected,
    cumulative_flag: cumlative,
    abatement_value: parseFloat(value),
    data_type: dataType,
    start_year: parseInt(startYear),
    end_year: parseInt(endYear),
    createdby: user.username,
    modifiedby: user.username,
    dropDownMetaData: metaData,
    datatype_unit: "kWh",
    project_type:
      project.projectType === 1
        ? "Reduce"
        : project.projectType === 2
        ? "Replace"
        : "Compensate",
    project_id: project.projectId,
  };

  const createExtendRule = async (type) => {
    let selectedCols = getselectedCols(trgtHrchy);
    let unSelectedCols = getUnselectedCols(trgtHrchy);
    let newhierarchy = getNewHierarchy(trgtHrchy);
    let simulateSaveReplacePayload = {
      ...simulateSavePayload,
      target_Name: newhierarchy,
      view_type: "M3-ApplyFilter",
      ruleType: type === "Simulate" ? "Simulate" : "Save",
      changeType: "Extend",
      cumulative_flag: cumlative,
      activitydata_value: activityDataValue,
      activitydata_Unit: activityDataUnit,
      emissiondata_value: emissionFactorValue,
      emissiondata_unit: emissionFactorUnit,
      targetHierarchy: trgtHrchy,
      transferAD: trnsfrOrCopy,
      columnNames: filtersPayload.columnNames,
      selectedcolumns: selectedCols,
      nonselectedcolumns: unSelectedCols,
      abatement_value: 0,
    };
    const data = await SaveM3Step2ReplaceData({
      simulateSaveReplacePayload,
    });
    if (type === "Simulate") {
      dispatch(setSimulateDataOnTable({ simulateTableData: data.tableData }));
      dispatch(setGraphSimulateFlag({ isGraphSimulate: true }));
      dispatch(setSimulateDataOnGraph({ simulateGraphData: data.graphData }));
      setSimulateloader(false);
    } else {
      dispatch(setReduceDefautViewData({ defaultViewData: data }));
      setSaveLoader(false);
      dispatch(setExtendNewHierarchy({ rule: {} }));
    }
    dispatch(setM3Step2Loader({ step2GraphTableLoader: false }));
  };

  const onExtendClose = (data) => {
    setExtendModalOpen(false);
  };
  const openExtendModal = () => {
    setExtendModalOpen(true);
  };

  const resetSimulatefields = () => {
    setCumulative("");
    setUnit("");
    setValue("");
    setStartYear("");
    setEndYear("");
    setSimulateApplied(false);
    setSimulateBtnDisable(true);
    setTrgtHrchy("");
    setTransferCopy("");
    setActivityDataUnit("");
    setActivityDataValue("");
    setEmissionFactorUnit("");
    setEmissionFactorValue("");
  };

  return (
    <div className="emissions-simulate-section">
      <div className="emissions-simulate-content">
        <div className="emissions-simultae-content-row">
          <div className="emissions-simulate-content-row-dropdown">
            <span>
              {ruleName}
              <span className="required-star">*</span>
              <Tooltip
                trigger={<EmissionIcon />}
                variant="ghost"
                tooltipText={ruleNameTooltip}
              ></Tooltip>
            </span>
            <Input
              type="text"
              onChange={(event) => {
                handleOnChange(event?.target?.value, "ruleName");
              }}
              value={rule}
              disabled={
                lockedData === 1 &&
                lockedBy !== user.username &&
                project.projectId
              }
              maxLength={50}
            ></Input>
          </div>
        </div>
        <div className="emissions-simultae-content-row">
          <div className="emissions-simulate-content-row-dropdown">
            <span>
              {ruleDescription}
              <Tooltip
                trigger={<EmissionIcon />}
                variant="ghost"
                tooltipText={ruleDescTooltip}
              ></Tooltip>
            </span>
            <TextArea
              type="text"
              onChange={(event) => {
                handleOnChange(event?.target?.value, "ruleDescription");
              }}
              value={ruleDesc}
              disabled={
                lockedData === 1 &&
                lockedBy !== user.username &&
                project.projectId
              }
              maxLength={500}
            ></TextArea>
          </div>
        </div>
        <div className="emissions-simultae-content-row">
          <div className="emissions-simulate-content-row-dropdown">
            <span>
              {addGMPSource}
              <Tooltip
                trigger={<EmissionIcon />}
                variant="ghost"
                tooltipText={sourceRuleTooltip}
              ></Tooltip>
            </span>
            <Input
              type="text"
              onChange={(event) => {
                handleOnChange(event?.target?.value, "source");
              }}
              value={source}
              disabled={
                lockedData === 1 &&
                lockedBy !== user.username &&
                project.projectId
              }
              maxLength={500}
            ></Input>
          </div>
        </div>
        {project.projectType === 2 && (
          <div className="emissions-simultae-content-row">
            <div className="emissions-simulate-content-row-dropdown">
              <span>
                {projectDetailsBusinessRulesChangeType}
                <span className="required-star">*</span>
                <Tooltip
                  trigger={<EmissionIcon />}
                  variant="ghost"
                  tooltipText={changeTypeTooltip}
                ></Tooltip>
              </span>
              <Select
                options={editExtend}
                onChange={(event) => {
                  handleOnChange(event, "replaceChangeType");
                }}
                value={replaceChange}
                disabled={
                  (lockedData === 1 &&
                    lockedBy !== user.username &&
                    project.projectId) ||
                  editType === "Manual edit"
                }
              ></Select>
            </div>
          </div>
        )}
        <div className="emissions-simultae-content-row">
          <div className="emissions-simulate-content-row-dropdown">
            <span>
              {edittype}
              <span className="required-star">*</span>
              <Tooltip
                trigger={<EmissionIcon />}
                variant="ghost"
                tooltipText={editTypeTooltip}
              ></Tooltip>
            </span>
            <Select
              options={editTypeOptions}
              onChange={(event) => {
                handleOnChange(event, "editType");
              }}
              value={editType}
              disabled={replaceChange === "Extend"}
            ></Select>
          </div>
        </div>
        <div className="emissions-simultae-content-row">
          <div className="emissions-simulate-content-row-dropdown">
            <Button variant="primary-alt" onClick={() => openFiltersModal()}>
              <AreaSeacrhIcon />
              {"Data selection"}
            </Button>
          </div>
        </div>
        {showUnitValidationError && project.projectType !== 3 && (
          <div className="emissions-simultae-content-row">
            <div className="emissions-simulate-content-row-dropdown">
              <span className="emissions-simulate-unit-validation-error">
                <WarningIconOrange />
                {dataType == "Activity data"
                  ? aDValidationError
                  : eFValidationError}
              </span>
            </div>
          </div>
        )}
        {replaceChange === "Extend" && (
          <div className="emissions-simultae-content-row">
            <div className="emissions-simulate-content-row-dropdown">
              <span>
                {targetHierarchy}
                <span className="required-star">*</span>
                <Tooltip
                  trigger={<EmissionIcon />}
                  variant="ghost"
                  tooltipText={targetHierarchyTooltip}
                ></Tooltip>
              </span>
              <Select
                options={targetHierarchyValues}
                onChange={(value) => {
                  handleOnChange(value, "targetHierarchy");
                }}
                value={trgtHrchy}
                disabled={validationFlag.isDuplicate === "Y"}
              />
            </div>
          </div>
        )}
        {replaceChange === "Extend" && (
          <div className="emissions-simultae-content-row">
            <div className="emissions-simulate-content-row-dropdown">
              <Button
                variant="primary-alt"
                onClick={() => openExtendModal()}
                disabled={
                  validationFlag.isDuplicate === "Y" || trgtHrchy === ""
                }
              >
                {defineNewHrchy}
              </Button>
            </div>
          </div>
        )}
        {replaceChange === "Edit" && (
          <div className="emissions-simultae-content-row">
            <div className="emissions-simulate-content-row-dropdown">
              <span>
                {addForecastSpecificationDataType}{" "}
                <span className="required-star">*</span>
                <Tooltip
                  trigger={<EmissionIcon />}
                  variant="ghost"
                  tooltipText={forecastDataTypeTooltip}
                ></Tooltip>
              </span>
              <Select
                options={
                  project.projectType === 3
                    ? emissionArray
                    : addForecastSpecificationDataTypeOptions
                }
                value={dataType}
                onChange={(event) => {
                  handleOnChange(event, "dataType");
                }}
                disabled={
                  lockedData === 1 &&
                  lockedBy !== user.username &&
                  project.projectId
                }
              ></Select>
            </div>
          </div>
        )}
        {editType === "Batch edit" && (
          <div className="emissions-simultae-content-row">
            <div className="emissions-simulate-content-row-dropdown">
              <span>
                {absoluteorRelative}
                <span className="required-star">*</span>
                <span className="tooltip">
                  <Tooltip
                    trigger={<EmissionIcon />}
                    variant="ghost"
                    tooltipText={unitTooltip}
                  ></Tooltip>
                </span>
              </span>
              <Select
                options={unitDropdown}
                value={unitSelected}
                onChange={(event) => {
                  handleOnChange(event, "unit");
                }}
                disabled={
                  lockedData === 1 &&
                  lockedBy !== user.username &&
                  project.projectId
                }
              ></Select>
            </div>
            <div className="emissions-simulate-content-row-dropdown">
              <span>
                {cumulative}
                <span className="required-star">*</span>
                <Tooltip
                  trigger={<EmissionIcon />}
                  variant="ghost"
                  tooltipText={cumulativeTooltip}
                ></Tooltip>
              </span>
              <Select
                options={cumulativedropdown}
                value={cumlative}
                onChange={(event) => {
                  handleOnChange(event, "cumulative");
                }}
                disabled={
                  lockedData === 1 &&
                  lockedBy !== user.username &&
                  project.projectId
                }
              ></Select>
            </div>
          </div>
        )}
        {replaceChange === "Extend" && (
          <div className="emissions-simultae-content-row">
            <div className="emissions-simulate-content-row-dropdown">
              <span>
                {transferOrCopy}
                <span className="required-star">*</span>
                <Tooltip
                  trigger={<EmissionIcon />}
                  variant="ghost"
                  tooltipText={transferADTooltip}
                ></Tooltip>
              </span>
              <Select
                options={transferCopyOptions}
                onChange={(event) => {
                  handleOnChange(event, "transferOrCopy");
                }}
                value={trnsfrOrCopy}
                disabled={validationFlag.isDuplicate === "Y"}
              ></Select>
            </div>
          </div>
        )}
        {editType === "Batch edit" && (
          <div className="emissions-simultae-content-row">
            <div className="emissions-simulate-content-row-dropdown">
              <span>
                {strtYr}
                <span className="required-star">*</span>
                <Tooltip
                  trigger={<EmissionIcon />}
                  variant="ghost"
                  tooltipText={emissionsStartyearTooltip}
                ></Tooltip>
              </span>
              <Input
                errorMessage={startYearErrorMessage}
                isError={showstartYearError}
                disableErrorPlaceholder={true}
                onChange={(event) => {
                  handleOnChange(event?.target?.value, "startYear");
                }}
                value={startYear}
                disabled={
                  lockedData === 1 &&
                  lockedBy !== user.username &&
                  project.projectId
                }
                type="number"
              />
            </div>
            <div className="emissions-simulate-content-row-dropdown">
              <span>
                {endYr}
                <span className="required-star">*</span>
                <Tooltip
                  trigger={<EmissionIcon />}
                  variant="ghost"
                  tooltipText={emissionsEndYeartooltip}
                ></Tooltip>
              </span>
              <Input
                errorMessage={endYearErrorMessage}
                isError={showendYearError}
                disableErrorPlaceholder={true}
                onChange={(event) => {
                  handleOnChange(event?.target?.value, "endYear");
                }}
                value={endYear}
                disabled={
                  lockedData === 1 &&
                  lockedBy !== user.username &&
                  project.projectId
                }
                type="number"
              />
            </div>
          </div>
        )}
        {replaceChange === "Extend" && (
          <div className="emissions-simultae-content-row">
            <div className="emissions-simulate-content-row-dropdown">
              <span>
                {actDataValue}
                <span className="required-star">*</span>
                <Tooltip
                  trigger={<EmissionIcon />}
                  variant="ghost"
                  tooltipText={activityDataValueTooltip}
                ></Tooltip>
              </span>
              <Input
                onChange={(event) => {
                  handleOnChange(event?.target?.value, "activityDataValue");
                }}
                value={activityDataValue}
                errorMessage={activityDataValueErrorMessage}
                isError={isActivityDataValueError}
                type="decimal"
                disabled={validationFlag.isDuplicate === "Y"}
              />
            </div>
            <div className="emissions-simulate-content-row-dropdown">
              <span>
                {actDataUnit}
                <span className="required-star">*</span>
                <Tooltip
                  trigger={<EmissionIcon />}
                  variant="ghost"
                  tooltipText={activityUnitTooltip}
                ></Tooltip>
              </span>
              <Input
                disableErrorPlaceholder={true}
                onChange={(event) => {
                  handleOnChange(event?.target?.value, "activityDataUnit");
                }}
                value={activityDataUnit}
                maxLength={30}
                type={"text"}
                disabled={validationFlag.isDuplicate === "Y"}
              />
            </div>
          </div>
        )}
        {replaceChange === "Extend" && (
          <div className="emissions-simultae-content-row">
            <div className="emissions-simulate-content-row-dropdown">
              <span>
                {emisFactValue}
                <span className="required-star">*</span>
                <Tooltip
                  trigger={<EmissionIcon />}
                  variant="ghost"
                  tooltipText={emissionFactorTooltip}
                ></Tooltip>
              </span>
              <Input
                disableErrorPlaceholder={true}
                onChange={(event) => {
                  handleOnChange(event?.target?.value, "emissionFactorValue");
                }}
                value={emissionFactorValue}
                isError={isEmissionFactorValueError}
                errorMessage={emissionFactorValueErrorMessage}
                type="decimal"
                disabled={validationFlag.isDuplicate === "Y"}
              />
            </div>
            <div className="emissions-simulate-content-row-dropdown">
              <span>
                {emisFactorUnit}
                <span className="required-star">*</span>
                <Tooltip
                  trigger={<EmissionIcon />}
                  variant="ghost"
                  tooltipText={emissionFactorunitTooltip}
                ></Tooltip>
              </span>
              <Input
                disableErrorPlaceholder={true}
                onChange={(event) => {
                  handleOnChange(event?.target?.value, "emissionFactorUnit");
                }}
                value={emissionFactorUnit}
                disabled={true}
                type={"text"}
              />
            </div>
          </div>
        )}
        {editType === "Batch edit" && replaceChange === "Edit" && (
          <div className="emissions-simulate-content-row">
            <div className="emissions-simulate-content-row-dropdown">
              <span>
                {simulateValue}
                <span className="required-star">*</span>
                <Tooltip
                  trigger={<EmissionIcon />}
                  variant="ghost"
                  tooltipText={emissionsValuetooltip}
                ></Tooltip>
              </span>
              <Input
                errorMessage={valueErrorMessage}
                isError={valueError}
                disableErrorPlaceholder={true}
                onChange={(event) => {
                  handleOnChange(event?.target?.value, "value");
                }}
                maxLength={value < 0 ? 12 : 11}
                disabled={
                  unitSelected === "" ||
                  dataType === "" ||
                  (lockedData === 1 &&
                    lockedBy !== user.username &&
                    project.projectId)
                }
                value={value}
                type={"decimal"}
              />
            </div>
            <div className="emissions-simulate-content-row-dropdown"></div>
          </div>
        )}
      </div>
      <div className="emissions-simulate-footer">
        {replaceChange === "Edit" && (
          <LoadingIndicatorEmbed show={simulateLoader}>
            <Button
              variant="primary-alt"
              onClick={onSimulate}
              disabled={
                isSimulateBtnDisable ||
                saveLoader ||
                (lockedData === 1 &&
                  lockedBy !== user.username &&
                  project.projectId)
              }
            >
              <SimulateIcon /> {simulate}
            </Button>
          </LoadingIndicatorEmbed>
        )}

        <LoadingIndicatorEmbed show={saveLoader}>
          <Button
            variant="primary-alt"
            onClick={() => onSave("Save")}
            disabled={
              isSimulateBtnDisable ||
              simulateLoader ||
              (lockedData === 1 &&
                lockedBy !== user.username &&
                project.projectId)
            }
          >
            <SaveIcon />
            {SaveBtn}
          </Button>
        </LoadingIndicatorEmbed>
      </div>
      <EmissionFilterModal
        isFilterOpen={isFilterOpen}
        onClose={onfilterClose}
      />
      <ExtendModal
        isModalOpen={isExtendModalOpen}
        onClose={() => onExtendClose()}
        hierarchy={trgtHrchy}
      />
    </div>
  );
}
