import { WarningIcon } from "../../icons";
import {
  CancelBtn,
  deleteModalBody,
  deleteModalHeader,
} from "../../util/constants";
import { Button } from "../Button/Button";
import { Modal } from "../Modal/Modal";
import "./ViewEngagementDeleteModal.scss";

function ModalBody() {
  return (
    <span className="view-endagement-delete-modal-body">{deleteModalBody}</span>
  );
}

function ModalFooter({ handleCancelClick, onConfirm }) {
  return (
    <div className="view-endagement-delete-modal-footer">
      {/* <Button variant="secondary" onClick={handleCancelClick}>
        {CancelBtn}
      </Button> */}
      <Button onClick={onConfirm} variant="primary">
        {"Yes, close"}
      </Button>
    </div>
  );
}

export function ViewEngagementDeleteModal({
  isModalVisible,
  onClose,
  onConfirm,
}) {
  return (
    <Modal
      show={isModalVisible}
      onClose={onClose}
      header={
        <div className="file-version-history-modal-header">
          <div className="motif-fab">
            <WarningIcon />
          </div>
          <span>{deleteModalHeader}</span>
        </div>
      }
      body={<ModalBody />}
      footer={<ModalFooter handleCancelClick={onClose} onConfirm={onConfirm} />}
    />
  );
}
