import React from "react";

export function WarningIconOrange() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.61617 3.64141C10.6736 1.80247 13.3268 1.80247 14.3841 3.64141L22.4271 17.6291C23.4813 19.4625 22.1579 21.7499 20.0431 21.7499H3.95721C1.84242 21.7499 0.519055 19.4625 1.57322 17.6291L9.61617 3.64141ZM13.0838 4.38912C12.6032 3.55324 11.3972 3.55324 10.9165 4.38912L2.87358 18.3769C2.39441 19.2102 2.99594 20.2499 3.95721 20.2499H20.0431C21.0044 20.2499 21.6059 19.2102 21.1267 18.3769L13.0838 4.38912Z"
        fill="#D07200"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.0002 8.24994C12.4144 8.24994 12.7502 8.58573 12.7502 8.99994V12.9999C12.7502 13.4142 12.4144 13.7499 12.0002 13.7499C11.5859 13.7499 11.2502 13.4142 11.2502 12.9999V8.99994C11.2502 8.58573 11.5859 8.24994 12.0002 8.24994Z"
        fill="#07200"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.5119 16.4414C12.8198 16.7185 12.8447 17.1927 12.5676 17.5006L12.5576 17.5117C12.2805 17.8195 11.8063 17.8445 11.4984 17.5674C11.1906 17.2903 11.1656 16.8161 11.4427 16.5082L11.4527 16.4971C11.7298 16.1892 12.204 16.1643 12.5119 16.4414Z"
        fill="#D07200"
      />
    </svg>
  );
}
