import React, { useState } from "react";
import { MultiSelect } from "react-multi-select-component";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import "./style.scss";

const MultiSelectCreatableOption = (props) => {
  const dispatch = useDispatch();
  const [selected, setSelected] = useState(
    props && props.value ? props.value : []
  );
  const [options, createOption] = useState([]);
  const avilableOptions = props.avilableOptions;
  const [disabled, setdisabled] = useState(props.disabled);
  useEffect(() => {
    async function createOptionArray() {
      let optionArray = [];
      avilableOptions &&
        avilableOptions?.map((item) =>
          optionArray.push({
            label: item.label ? item.label : item,
            value: item.value ? item.value : item,
            disabled: item.disabled ? item.disabled : false,
          })
        );
      createOption(optionArray);
    }
    if (avilableOptions) {
      createOptionArray();
    }
  }, [avilableOptions, dispatch]);

  useEffect(() => {
    if (selected) {
      props.onChange(selected);
    }
  }, [selected, dispatch]);

  const handleNewField = (value) => ({
    label: value,
    value: value,
  });

  const customValueRenderer = (selected, _options) => {
    return selected.length
      ? selected.map(({ label }) => label).join(", ")
      : props.label;
  };
  return (
    <div data-testid="MultiselectCreate" style={{ flex: 1 }}>
      <MultiSelect
        className={`decarb-multiselect`}
        options={options}
        value={selected}
        valueRenderer={customValueRenderer}
        onChange={setSelected}
        labelledBy={props.label}
        isCreatable={props.isCreatable}
        onCreateOption={handleNewField}
        overrideStrings={props.overrideStrings}
        disabled={props.disabled}
        hasSelectAll={props.hasSelectAll}
      />
    </div>
  );
};

export default MultiSelectCreatableOption;
