export const Project_URL = {
  GetDistinctTags: `GetDistinctTags`,
  GetDistinctProjects: `GetDistinctProjects`,
  CreateProjectDraft: `CreateProjectDraft`,
  createM3Project: `CreateM3Project`,
  SaveProjectTags: `SaveProjectTags`,
  reduceDefaultViewData: `GetM3Step2ReduceTableGraphData`,
  SaveEmissionFilters: `SaveEmissionSelection`,
  GetSaveProjectRule: `GetSavedProjectRule`,
  SaveM3Step2ReduceData: `SaveM3Step2ReduceData`,
  getM3TableGraphData: `GraphTableDataForSaveSimulate`,
  ProjectEditDetails: `GetEditDetails`,
  ProjectEmissionSelection: `GetProjectEmissionSelection`,
  RecreateProject: `ReCreateProjectM3`,
  UpdateProjectStep: `UpdateProjectStep`,
  GetM3GraphData: `GetM3GraphData`,
  GetProjectsScenario: `GetProjectsScenario`,
  GetNavBarProjects: `GetNavBarProjects`,
  ResetProject: `ResetProjectM3`,
  GetUserModuleLock: `getUserLockByModule`,
  SaveUserModuleLock: `SaveUserModule`,
  UpdateUserModuleLock: `UpdateUserModuleLock`,
  ReleaseUserModuleLock: `ReleaseUserModuleLock`,
  ManualEdit: `SaveM3Step2ManualEdit`,
  ColumnFlags: `GetColumnValue`,
  GetProjectdetails: `GetProjectdetails`,
  GetUserLockByModule: `getUserLockByModule`,
  GetBusinessCaseSummaryData: `GetBusinessCaseSummaryData`,
  GetFinancialsAbatementData: `GetFinancialsAbatementDataAsync`,
  GetProjectBusinessCaseSummaryData: `GetM3BusinessCaseSummaryData`,
  GetProjectFinancialsAbatementData: `GetFinancialsAbatementDataAsync`,
  GetProjectCashFlowData: `GetM3CashFlowGraphData`,
  SaveM3StepData: `SaveM3Step2Data`,
  SetProjectstatustoFinalise: `SetProjectStatustoFinalize`,
  CopyFromPreviousVersion: `CopyFromPreviousVersion`,
  GetUnitValidation: `GetUnitValidationFlags`,
  DeleteLastAppliedRule: `DeleteLatestAppliedRule`,
};
