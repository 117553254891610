import React, { useState, useEffect } from "react";
import { EmissionIcon, SaveIcon } from "../../icons";
import {
  // addForecastSpecificationDataTypeOptions,
  // financialVariableType,
  // financialRulesType,
  financialParameterType,
  SaveBtn,
  financialSetVariable,
  parameterSelectionTooltip,
  // addGMPVariableTypeOptions,
} from "../../util/constants";
import { LoadingIndicatorEmbed } from "../LoadingIndicator/LoadingIndicatorEmbed";
import { UnitValidationModal } from "./UnitValidationModal";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "../Button/Button";
import { Select } from "../Select/Select";
import "./FinancialsForm.scss";
import {
  saveDecarbGmpRuleMap,
  getParametersList,
  getruleList,
  getGMPRulesMapData,
} from "../../store/services/gmp.service";
import {
  setrulesMapData,
  setIsVariableApply,
  setUnitDetails,
} from "../../store/slices/gmpSlice";
import { LockerModalM3 } from "../LockerModal";
import { getLockingData } from "../../store/slices/projectSlice";
import { Tooltip } from "../CommonUIComponents/Tooltip/Tooltip";

export function FinancialsForm() {
  // const navigate = useNavigate();
  const dispatch = useDispatch();
  const { state } = useLocation();
  const user = useSelector((state) => state.user.user);
  const engagement = useSelector((state) => state.engagement.engagement);
  const project = useSelector((state) => state.project.project);
  // const [variableType, setVariableType] = useState("");
  const [paramType, setParamType] = useState("");
  // const [ruleType, setRuleType] = useState("");
  const [paramOptions, setParamOptions] = useState([]);
  const [ruleOptions, setRuleOptions] = useState([]);
  const [lockedData, setLock] = useState();
  const [lockedBy, setLockedby] = useState();
  const [isLockerModalOpen, setLockerModalOpen] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [unitModalOpen, setUnitModalOpen] = useState(false);

  useEffect(() => {
    if (engagement?.id) {
      getRulesList();
      getParameterList();
    }
  }, [engagement?.id]);

  // to get a locked user flags
  useEffect(() => {
    const request = {
      LockedBy: user.username,
      ModuleName: "Project",
      EngagementID: project.projectId,
    };

    dispatch(getLockingData(request)).then((action) => {
      if (action) {
        const lockedData = action.payload?.data?.lockStatus;
        const lockedBy = action.payload?.data?.lockedBy;
        setLock(lockedData);
        setLockedby(lockedBy);
        if (lockedData === 2) {
          setLockerModalOpen(true);
        }
      }
    });
  }, [dispatch, project.projectId, lockedData]);

  const onSaveRuleMap = async () => {
    setShowLoader(true);
    dispatch(setIsVariableApply({ isVariableApply: false }));
    const request = {
      parameterName: paramType,
      projectID: project?.projectId,
      // variableType: variableType,
      createdBy: user.username,
      modifiedBy: user.username,
      engagementCode: engagement.engagementCode,
      engagementID: engagement.id,
    };
    const data = await saveDecarbGmpRuleMap({ request });
    dispatch(setUnitDetails({ unitData: data }));
    getRulesMapData();
    setUnitModalOpen(true);
  };

  // Get GMP rules data to refresh table
  const getRulesMapData = async () => {
    const request = {
      projectId: project?.projectId,
    };
    const data = await getGMPRulesMapData({ request });
    dispatch(setrulesMapData({ rulesMapData: data }));
    setParamType("");
    setShowLoader(false);
  };

  //Onclick function on Simulate field
  const handleOnChange = (value, fieldName) => {
    // if (fieldName === "variableType") {
    //   setParamType("");
    //   getRulesList();
    //   getParameterList(value);
    //   setVariableType(value);
    // } else
    if (fieldName === "paramType") {
      setParamType(value);
    }
    //  else if (fieldName === "ruleType") {
    //   setRuleType(value);
    // }
  };

  // Get Parameters List
  const getParameterList = async (value) => {
    const request = {
      engagementId: engagement.id,
      // variable_type: value,
    };
    const data = await getParametersList({ request });
    setParamOptions(data);
  };

  // Get Rule List
  const getRulesList = async () => {
    const request = {
      projectId: project?.projectId,
    };
    const data = await getruleList({ request });
    setRuleOptions(data);
  };

  return (
    <div className="financial-form-section">
      <div className="financial-form-title">
        <span>{financialSetVariable}</span>
      </div>
      <div className="financial-body-container">
        {/* <div className="financial-form-body">
          <span className="form-lables">
            {financialVariableType}
            <span className="required-star">*</span>
          </span>
          <Select
            options={addGMPVariableTypeOptions}
            value={variableType}
            disabled={
              lockedData === 1 &&
              lockedBy !== user.username &&
              project.projectId
            }
            onChange={(event) => {
              // eslint-disable-next-line no-undef
              handleOnChange(event, "variableType");
            }}
          ></Select>
        </div> */}
        <div className="financial-form-body">
          <span className="form-lables">
            {financialParameterType}
            <span className="required-star">*</span>
            <Tooltip
              trigger={<EmissionIcon />}
              variant="ghost"
              tooltipText={parameterSelectionTooltip}
            ></Tooltip>
          </span>
          <Select
            options={paramOptions}
            multiple={true}
            showSelectAllButton={true}
            value={paramType}
            disabled={
              lockedData === 1 &&
              lockedBy !== user.username &&
              project.projectId
            }
            onChange={(event) => {
              // eslint-disable-next-line no-undef
              handleOnChange(event, "paramType");
            }}
          ></Select>
        </div>
        {/* <div className="financial-form-body">
          <span className="form-lables">
            {financialRulesType}
            <span className="required-star">*</span>
          </span>
          <Select
            options={["All"]}
            value="All"
            disabled
            onChange={(event) => {
              // eslint-disable-next-line no-undef
              handleOnChange(event, "ruleType");
            }}
          ></Select>
          <span className="new-label">
            {"Option to select rules will be part of release 2"}
          </span>
        </div> */}
        <div className="savebtn-btn">
          <LoadingIndicatorEmbed show={showLoader}>
            <Button
              variant="secondary"
              onClick={onSaveRuleMap}
              disabled={
                // variableType === "" ||
                paramType === "" ||
                ruleOptions?.length === 0 ||
                (lockedData === 1 &&
                  lockedBy !== user.username &&
                  project.projectId)
              }
            >
              <SaveIcon />
              &nbsp;{SaveBtn}
            </Button>
          </LoadingIndicatorEmbed>
        </div>
        {lockedData === 2 &&
          lockedBy === user.username &&
          project.projectId && (
            <LockerModalM3
              isOpen={isLockerModalOpen}
              onClose={() => setLockerModalOpen(false)}
            />
          )}
      </div>
      <UnitValidationModal
        isUnitModalOpen={unitModalOpen}
        onClose={() => setUnitModalOpen(false)}
      />
    </div>
  );
}
