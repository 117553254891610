import React, { memo, useEffect, useState } from "react";
import { Input } from "../../Input/Input";
import { useSelector } from "react-redux";

export default memo(({ value, node, field, onValueChange }) => {
  // const selectedRowData = useSelector((state) => state.project.selectedRowData);
  // const selectedRow = useSelector((state) => state.project.isAnyRowSelected);
  const manualEditRules = useSelector((state) => state.project.manualEditRules);
  const engagement = useSelector((state) => state.engagement.engagement);
  const isManualEdit = useSelector((state) => state.project.isManualEdit);
  let original = value === null ? "" : value;
  const [data1, setValue] = useState();
  const [changed, setChanged] = useState(false);

  useEffect(() => {
    let unique = [...new Set(manualEditRules.map((x) => x.rowIndex))];
    unique.forEach((index) => {
      let x = false;
      for (let i = manualEditRules.length - 1; i >= 0; i--) {
        if (
          manualEditRules[i]["rowIndex"] === node.rowIndex &&
          parseInt(field) === parseInt(manualEditRules[i]["start_year"]) &&
          !x
        ) {
          setValue(manualEditRules[i]["value"]);
          x = true;
          setChanged(true);
        }
      }
    });
  }, [manualEditRules]);

  let viewed = data1
    ? data1[0] === "0"
      ? data1.substring(1)
      : data1
    : original;

  const valueValidation = (data) => {
    let x = engagement.mostRecentYear + 1;
    let value = data.x;
    let ans = false;
    for (
      let i = engagement.mostRecentYear + 1;
      i <= engagement.foreCastEndYear;
      i++
    ) {
      if (data.i !== value) {
        ans = true;
      }
    }
    return ans;
  };
  const d = (
    <Input
      data-testid="custom-component-emission-table"
      type="decimal"
      aria-label="manual edit input"
      value={viewed}
      onChange={(event) => {
        if (event.target.value === "" || isNaN(event.target.value)) {
          setValue("0");
        } else {
          setValue(event.target.value);
        }
      }}
      onMouseOut={(event) => {
        onValueChange(
          value,
          field,
          event.target.value,
          node?.data?.data_type,
          node.rowIndex,
          node.data
        );
      }}
      disabled={
        // (!selectedRowData?.isAnyRowSelected &&
        //   selectedRow?.rowChecked !== selectedRow?.rowUnchecked) ||
        // selectedRowData?.rowIndex !== node.rowIndex ||
        value === null ||
        value === "" ||
        valueValidation(node.data) ||
        node.data.unit === "" ||
        !isManualEdit
      }
      key={node.rowIndex + field}
    />
  );
  return d;
});
