import { useEffect } from "react";
import "./AddGMP.scss";
import { Button } from "..";
import { useNavigate } from "react-router-dom";
import { routes } from "../../routes/routes.constatns";
import { useDispatch, useSelector } from "react-redux";
// import { CollapsableAccordion } from "../CommonUIComponents/CollapsableAccordion/Accordion";
// import { GMPHierarchy } from "./GMPHierarchy/GMPHierarchy";
import { GMPParameters } from "./GMPParameters/GMPParameters";
import { GMPTable } from "./GMPTable/GMPTable";
import { setHierarchialData } from "../../store/slices/projectSlice";
import {
  setGMPDetails,
  setSelectedGMPRowIndex,
} from "../../store/slices/gmpSlice";
import {
  getFinancialParametersHrchyData,
  getReplaceHierarchyData,
} from "../../store/services/engagement.service";
import { getGMPData } from "../../store/services/gmp.service";
import { Tooltip } from "../CommonUIComponents/Tooltip/Tooltip";
import { EmissionIcon } from "../../icons";
import { addGMPTooltip } from "../../util/constants";

export function AddGMP() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const steps = useSelector((state) => state.stepper.steps);
  const engagement = useSelector((state) => state.engagement.engagement);
  const project = useSelector((state) => state.project.project);
  const selectedRegion = useSelector(
    (state) => state.engagement.selectedRegion
  );
  const currentStep = useSelector((state) => state.engagement.currentStep);

  useEffect(() => {
    if (engagement?.id) {
      hierarchyData();
      getGMPDetails();
    }
  }, [engagement?.id, dispatch]);

  // Handle cancel button onclick
  function handleCancelClick() {
    dispatch(setSelectedGMPRowIndex({ rowDataIndex: null }));
    if (project?.projectId) {
      navigate(
        `/${routes.createNewEngagement}/${routes.createNewProject}?projectId=${project?.projectId}`,
        {
          state: {
            engagementId: engagement.id,
            regionId: selectedRegion.id,
          },
          gestureEnabled: false,
        }
      );
    } else {
      navigate(`/${routes.createNewEngagement}`, {
        state: {
          engagementId: engagement.id,
          regionId: selectedRegion.id,
        },
        gestureEnabled: false,
      });
    }
  }

  //Fetching hierarchy data and creating redux state for it.
  const hierarchyData = async () => {
    const request = {
      engagementCode: engagement.engagementCode,
      // clientName: engagement.clientName,
    };
    const data = await getFinancialParametersHrchyData({ request });
    dispatch(setHierarchialData({ hierarchialData: data }));
  };

  // Fetching GMP details
  const getGMPDetails = async () => {
    const request = {
      engagement: engagement.engagementCode,
      viewType: "Default",
    };
    const data = await getGMPData({ request });
    dispatch(setGMPDetails({ gmpData: data }));
  };

  return (
    <div className="add-gmp">
      <div className="add-gmp-header">
        <div className="add-gmp-header-title">
          {steps[currentStep]?.stepHeading}
        </div>
        <div className="add-gmp-header-stepper"></div>
      </div>

      <div className="add-gmp-content">
        <div className="add-gmp-content-header">
          <div className="add-gmp-content-header-main">
            <span className="add-gmp-content-header-main-analyse">
              Financial parameter creation
            </span>
            <Tooltip
              trigger={<EmissionIcon />}
              variant="ghost"
              tooltipText={addGMPTooltip}
            ></Tooltip>
          </div>
          {/* <span className="add-gmp-content-header-desc"></span> */}
        </div>
        <div className="add-gmp-content-body">
          {/* <CollapsableAccordion
            headerTitle="Set hierarchy"
            accordionContent={<GMPHierarchy />}
          /> */}
          <GMPParameters />
          <GMPTable />
          <div className="add-gmp-footer">
            <Button variant="secondary" onClick={handleCancelClick}>
              {"Close"}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
