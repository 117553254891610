import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  getEngagement,
  createEngagement as createEngagementApi,
  updateEngagement as updateEngagementApi,
  getM1FileUploadVersionHistory,
  resetM2Data,
  updateForecastEndYear,
  getConsentData,
  getRegions,
} from "../services/engagement.service";

const initialState = {
  engagement: {},
  currentStep: undefined,
  stepIndex: 0,
  isSaveAndNextDisabled: true,
  isForecastEndYearDisabled: false,
  isMostRecentYearDisabled: false,
  fileUploadHistory: [],
  fileUploadHistoryLoading: false,
  resetM2DataLoading: false,
  selectedRegion: {},
  regionList: [],
  regionListLoading: false,
  userConsent: undefined,
  externalUserFlag: false,
  userConsentLoading: false,
  createEngagementLoading: false,
  loading: false,
  error: null,
  defaultLoader: true,
};

const apiRegionUrls = {
  1: process.env.REACT_APP_API_URL_REG_US,
  2: process.env.REACT_APP_API_URL_REG_APAC,
  3: process.env.REACT_APP_API_URL_REG_EU,
};

const appRegionUrls = {
  1: process.env.REACT_APP_URL_REG_US,
  2: process.env.REACT_APP_URL_REG_APAC,
  3: process.env.REACT_APP_URL_REG_EU,
};

export const getEngagementById = createAsyncThunk(
  "Engagement/GetEngagementById",
  async (request) => {
    const apiResponse = await getEngagement({ request });

    return apiResponse.data;
  }
);

export const createEngagement = createAsyncThunk(
  "Engagement/CreateEngagement",
  async (request) => {
    const apiResponse = await createEngagementApi({ request });

    return apiResponse.data;
  }
);

export const updateEngagement = createAsyncThunk(
  "Engagement/UpdateEngagement",
  async (request) => {
    const apiResponse = await updateEngagementApi({ request });

    return apiResponse.data;
  }
);

export const getFileUploadHistoryM1 = createAsyncThunk(
  "Engagement/GetM1FileUploadVersionHistory",
  async (request) => {
    const apiResponse = await getM1FileUploadVersionHistory({ request });
    const sortedData = apiResponse.sort(function (a, b) {
      return new Date(b.uploadDate) - new Date(a.uploadDate);
    });

    sortedData[0].liveVersion = true;

    return sortedData;
  }
);

export const getResetM2Data = createAsyncThunk(
  "Engagement/DeleteM2Data",
  async (request) => {
    const apiResponse = await resetM2Data({ request });

    if (request?.forecastEndYear) {
      await updateForecastEndYear({ request });
    }

    return { data: apiResponse, forecastEndYear: request?.forecastEndYear };
  }
);

export const getUserConsent = createAsyncThunk(
  "Engagement/GetPIAConsentFlag",
  async (request) => {
    const apiResponse = await getConsentData({ request });

    return apiResponse.data;
  }
);

export const getRegionList = createAsyncThunk(
  "Engagement/GetRegions",
  async () => {
    const apiResponse = await getRegions();

    return apiResponse.map((x) => {
      return {
        id: x.id,
        name: x.regionName,
        apiUrl: apiRegionUrls[x.id],
        appUrl: appRegionUrls[x.id],
      };
    });
  }
);

const engagementSlice = createSlice({
  name: "engagement",
  initialState,
  reducers: {
    previousStep(state) {
      state.currentStep = state.currentStep - 1;
    },
    nextStep(state) {
      state.currentStep = state.currentStep + 1;
    },
    onClickStep(state, action) {
      const { selectedStep } = action.payload;
      state.currentStep = action.payload - 1;
      // state.stepIndex = selectedStep;
    },
    nextStepIndex(state) {
      state.stepIndex = state.stepIndex + 1;
    },
    setIsSaveAndNextDisabled(state, action) {
      const { isDisabled } = action.payload;

      state.isSaveAndNextDisabled = isDisabled;
    },
    setEngagementName(state, action) {
      const { name } = action.payload;

      state.engagement = { ...state.engagement, engagementName: name };
    },
    setEngagementCode(state, action) {
      const { code } = action.payload;

      state.engagement = { ...state.engagement, engagementCode: code };
    },
    setEngagementClientName(state, action) {
      const { clientName } = action.payload;

      state.engagement = { ...state.engagement, clientName: clientName };
    },
    setEngagementForecastEndYear(state, action) {
      const { endYear } = action.payload;

      state.engagement = {
        ...state.engagement,
        foreCastEndYear: parseFloat(endYear),
      };
      state.isForecastEndYearDisabled = true;
    },
    setEngagementMostRecentYear(state, action) {
      const { recentYear } = action.payload;

      state.engagement = {
        ...state.engagement,
        mostRecentYear: parseFloat(recentYear),
      };
      state.isMostRecentYearDisabled = true;
    },
    setSkipForecast(state, action) {
      const { skipForecast } = action.payload;

      state.engagement = { ...state.engagement, skip_ForeCast: skipForecast };
    },
    setIsMostRecentYearDisabled(state, action) {
      const { isDisabled } = action.payload;

      state.isMostRecentYearDisabled = isDisabled;
    },
    setIsForecastEndYearDisabled(state, action) {
      const { isDisabled } = action.payload;

      state.isForecastEndYearDisabled = isDisabled;
    },
    setStep(state, action) {
      const { step } = action.payload;
      state.currentStep = step;
    },
    setShowForecastFlag(state, action) {
      const { show } = action.payload;

      state.engagement = { ...state.engagement, show_Forecast: show };
    },
    setForecastInProgress(state, action) {
      const { inProgress } = action.payload;

      state.engagement = {
        ...state.engagement,
        forecast_inprogress: inProgress,
      };
    },
    setShowTargetFlag(state, action) {
      const { show } = action.payload;

      state.engagement = { ...state.engagement, show_Target: show };
    },
    setTargetInProgress(state, action) {
      const { inProgress } = action.payload;

      state.engagement = { ...state.engagement, target_inprogress: inProgress };
    },
    setGMPDetails(state, action) {
      const { gmpData } = action.payload;

      state.engagement = { ...state.engagement, gmpDataSet: gmpData };
    },
    setRegion(state, action) {
      const { regId } = action.payload;

      state.selectedRegion = state.regionList.find((x) => x.id === regId);
    },
    setUserConsent(state, action) {
      const { consent } = action.payload;

      state.userConsent = consent;
    },
    setExternalUserFlag(state, action) {
      const { externalUser } = action.payload;

      state.externalUserFlag = externalUser;
    },
    setDefaultLoader(state, action) {
      const { loader } = action.payload;
      state.defaultLoader = loader;
    },
    dropSelectedRegion(state) {
      const id = state.selectedRegion?.id ?? 1;
      state.selectedRegion = state.regionList.find((x) => x.id === id);
    },
    dropEngagementState(state) {
      state.engagement = initialState.engagement;
      state.currentStep = initialState.currentStep;
      state.stepIndex = initialState.stepIndex;
      state.isSaveAndNextDisabled = initialState.isSaveAndNextDisabled;
      state.isForecastEndYearDisabled = initialState.isForecastEndYearDisabled;
      state.isMostRecentYearDisabled = initialState.isMostRecentYearDisabled;
      state.loading = initialState.loading;
      state.error = initialState.error;
      state.fileUploadHistory = initialState.fileUploadHistory;
      state.fileUploadHistoryLoading = initialState.fileUploadHistoryLoading;
      state.userConsent = initialState.userConsent;
      state.externalUserFlag = initialState.externalUserFlag;
      state.defaultLoader = initialState.defaultLoader;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getEngagementById.pending, (state) => {
        state.loading = true;
      })
      .addCase(getEngagementById.fulfilled, (state, action) => {
        state.loading = false;
        state.engagement = action.payload;
        state.currentStep = state.currentStep
          ? state.currentStep
          : action.payload.engagementSetupStepID;
        state.stepIndex = action.payload.engagementSetupStepID;
        state.isForecastEndYearDisabled = action.payload.foreCastEndYear
          ? true
          : false;
        state.isMostRecentYearDisabled = action.payload.mostRecentYear
          ? true
          : false;
      })
      .addCase(getEngagementById.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
    builder
      .addCase(createEngagement.pending, (state) => {
        state.createEngagementLoading = true;
      })
      .addCase(createEngagement.fulfilled, (state, action) => {
        state.createEngagementLoading = false;
        state.engagement = action.payload;
        state.currentStep = action.payload.engagementSetupStepID;
        state.stepIndex = action.payload.engagementSetupStepID;
      })
      .addCase(createEngagement.rejected, (state, action) => {
        state.createEngagementLoading = false;
        state.error = action.error.message;
      });
    builder
      .addCase(updateEngagement.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateEngagement.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(updateEngagement.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
    builder
      .addCase(getFileUploadHistoryM1.pending, (state) => {
        state.fileUploadHistoryLoading = true;
      })
      .addCase(getFileUploadHistoryM1.fulfilled, (state, action) => {
        state.fileUploadHistoryLoading = false;
        state.fileUploadHistory = action.payload;
      })
      .addCase(getFileUploadHistoryM1.rejected, (state, action) => {
        state.fileUploadHistoryLoading = false;
        state.error = action.error.message;
      });
    builder
      .addCase(getResetM2Data.pending, (state) => {
        state.resetM2DataLoading = true;
      })
      .addCase(getResetM2Data.fulfilled, (state, action) => {
        state.resetM2DataLoading = false;
        state.engagement = {
          ...state.engagement,
          foreCastEndYear:
            parseInt(action.payload.forecastEndYear) ??
            state.engagement.foreCastEndYear,
        };
      })
      .addCase(getResetM2Data.rejected, (state, action) => {
        state.resetM2DataLoading = false;
        state.error = action.error.message;
      });
    builder
      .addCase(getUserConsent.pending, (state) => {
        state.userConsentLoading = true;
      })
      .addCase(getUserConsent.fulfilled, (state, action) => {
        state.userConsentLoading = false;
        state.userConsent = action.payload.consentFlag;
        state.externalUserFlag = action.payload.externalUser;
      })
      .addCase(getUserConsent.rejected, (state, action) => {
        state.userConsentLoading = false;
        state.error = action.error.message;
      });
    builder
      .addCase(getRegionList.pending, (state) => {
        state.regionListLoading = true;
      })
      .addCase(getRegionList.fulfilled, (state, action) => {
        state.regionListLoading = false;
        state.regionList = action.payload;
      })
      .addCase(getRegionList.rejected, (state, action) => {
        state.regionListLoading = false;
        state.error = action.error.message;
      });
  },
});

export const {
  previousStep,
  nextStep,
  onClickStep,
  nextStepIndex,
  setIsSaveAndNextDisabled,
  dropEngagementState,
  setEngagementName,
  setEngagementCode,
  setEngagementClientName,
  setEngagementForecastEndYear,
  setEngagementMostRecentYear,
  setSkipForecast,
  setIsMostRecentYearDisabled,
  setIsForecastEndYearDisabled,
  setStep,
  setShowForecastFlag,
  setShowTargetFlag,
  setForecastInProgress,
  setTargetInProgress,
  setGMPDetails,
  setRegion,
  setUserConsent,
  dropSelectedRegion,
  setDefaultLoader,
  setExternalUserFlag,
} = engagementSlice.actions;

export default engagementSlice.reducer;
