import axios from "axios";
import { MS_GRAPH_URL } from "./endpoints/graph";
import { USER_URL } from "./endpoints/user";
import { post } from "./base.service";

async function getUserPhoto({ request }) {
  return await axios(MS_GRAPH_URL.GetUserPhoto, {
    responseType: "arraybuffer",
    headers: {
      Authorization: `Bearer ${request.token}`,
    },
  }).catch((err) => {
    throw err;
  });
}

async function userSearch({ request }) {
  return await post(
    // `${USER_URL.UserSearch}?searchString=${request.string}&count=10`
    `/WebApi/Invoke`,
    {
      ActionVerb: "POST",
      ControllerName: "User",
      ActionName: USER_URL.UserSearch,
      QueryString: "searchString=" + request.string + `&` + "count=10",
      Data: "",
    }
  );
}

async function CheckIfUserIsAdmin({ request }) {
  return await post(
    // `${USER_URL.CheckIfUserIsAdmin}?emailId=${request.emailId}`
    `/WebApi/Invoke`,
    {
      ActionVerb: "get",
      ControllerName: "User",
      ActionName: USER_URL.CheckIfUserIsAdmin,
      QueryString: "emailId=" + request.emailId,
      Data: "",
    }
  );
}

async function AddGroupMember({ request }) {
  return await post(
    // `${USER_URL.CheckIfUserIsAdmin}?emailId=${request.emailId}`
    `/WebApi/Invoke`,
    {
      ActionVerb: "POST",
      ControllerName: "User",
      ActionName: USER_URL.AddGroupMember,
      QueryString: "email=" + request.emailId,
      Data: "",
    }
  );
}

export { getUserPhoto, userSearch, CheckIfUserIsAdmin, AddGroupMember };
