import {
  MotifProgressIndicatorStep,
  MotifProgressIndicator,
} from "@ey-xd/motif-react";
import { StepperCompletedStage, StepperActiveStage } from "../../icons";
import { useDispatch } from "react-redux";
import "./Stepper.scss";
import {
  onClickStep
} from "../../store/slices/engagementSlice";

export function Stepper({
  steps,
  stepIndex,
  currentStep,
  size = "md",
  className,
}) {
  const dispatch = useDispatch();
  const getStatus = (itemIdx) => {
    if (itemIdx === currentStep) {
      return "active";
    } else if (itemIdx < stepIndex) {
      return "completed";
    } else if (itemIdx > stepIndex) {
      return "default";
    }
  };

  const onStepperClick = (item) => {
    console.log(item);
    dispatch(onClickStep(item.stepIndex));
  }

  return (
    <MotifProgressIndicator
      variant="row"
      className={`decarb-stepper ${className} ${
        size === "small" ? "decarb-stepper-small" : ""
      }`}
      size={size === "small" ? "sm" : "md"}
    >
      {steps.map((item, index) => {
        const stepStatus = getStatus(index);
        const currectIcon =
          stepStatus === "completed"
            ? { complete: <StepperCompletedStage /> }
            : { incomplete: <StepperActiveStage /> };

        return (
          <MotifProgressIndicatorStep
            heading={item.stepName}
            status={stepStatus}
            key={index}
            onClick={()=> onStepperClick(item)}
            disabled={stepStatus !== "completed" && index !== currentStep}
            icons={currectIcon}
          />
        );
      })}
    </MotifProgressIndicator>
  );
}
