import React, { useEffect, useState } from "react";
import { Modal } from "../Modal/Modal";
import { Table } from "../Table/Table";
import {
  CancelBtn,
  unitValidationTableColDefs,
  unitValidationMsg,
  unitValidationHeader
} from "../../util/constants";
import { Button } from "../Button/Button";
import { useSelector, useDispatch } from "react-redux";

function ModalHeader() {
  return (
    <div className="emissions-change-log-header">
      <span className="emissions-change-log-header-title">
      {unitValidationHeader}
      </span>
    </div>
  );
}
function ModalBody({ loader }) {
  //  const gmp = useSelector((state) => state.gmp.gmp);
  const gmp = useSelector((state) => state.gmp.gmp);
  const dispatch = useDispatch();
  const [tableHeaders, setTableHeaders] = useState(unitValidationTableColDefs);
  const [tableData, setTableData] = useState([]);

    // Load table data on modal open
  useEffect(() => {
      if (gmp?.unitData?.unitValidationData) {
        setTableData(gmp?.unitData?.unitValidationData);
      }
  }, [gmp?.unitData?.unitValidationData]);

  return (
    <div className="emissions-change-log-content">
      <Table
        colDefs={tableHeaders}
        rowData={tableData}
        draggable={false}
        loading={loader}
      ></Table>
       <div className="emissions-change-log-content-msg"> {gmp?.unitData?.unmatchedparameters.length > 0 ? unitValidationMsg : ""}  {gmp?.unitData?.unmatchedparameters?.join()} </div>
    </div>
  );
}
function ModalFooter({ onApply }) {
  return (
    <div className="emissions-change-log-footer">
      <Button onClick={() => onApply()} variant="secondary">
        {CancelBtn}
      </Button>
    </div>
  );
}
export function UnitValidationModal({ isUnitModalOpen, onClose }) {
  const project = useSelector((state) => state.project.project);
  const [showLoader, setloader] = useState(false);
  const dispatch = useDispatch();

  // Load table data on modal open
  useEffect(() => {
    if (isUnitModalOpen) {
      setloader(true);
    }
  }, [isUnitModalOpen]);

  setTimeout(() => {
    setloader(false);
  }, 2000);

  return (
    <div className="financial-unit-validation">
      <Modal
        show={isUnitModalOpen}
        onClose={onClose}
        header={<ModalHeader></ModalHeader>}
        body={<ModalBody loader={showLoader}></ModalBody>}
        footer={
          <ModalFooter
            onApply={onClose}
          ></ModalFooter>
        }
        className={"financial-unit-validation-modal"}
      />
    </div>
  );
}
