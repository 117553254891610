import axios from "axios";

export async function get(url, token = null) {
  const config = {
    withCredentials: true,
    sameSite: "Strict",
    "X-Frame-Options": "DENY",
    "Content-Security-Policy": "frame-ancestors 'none'",
  };

  config.headers = token ? { Authorization: `Bearer ${token}` } : {};

  return await axios
    .get(`${url}`, config)
    .then((res) => {
      if (res.status === 200) {
        return res.data;
      }
      throw new Error("Error in response - " + res);
    })
    .catch((err) => {
      throw err;
    });
}

export async function post(url, data = null) {
  const config = {
    withCredentials: true,
    sameSite: "Strict",
    "X-Frame-Options": "DENY",
    "Content-Security-Policy": "frame-ancestors 'none'",
  };
  return await axios
    .post(`${url}`, data, config)
    .then((res) => {
      if (res.status === 200) {
        return res.data;
      }
      throw new Error("Error in response - " + res);
    })
    .catch((err) => {
      throw err;
    });
}

export async function getListCrossRegion(url, data) {
  const axiosResponse = await Promise.allSettled([
    axios.post(
      // `${process.env.REACT_APP_API_URL_REG_US}${url}`
      `${process.env.REACT_APP_API_URL_REG_US}WebApi/Invoke`,
      {
        ActionVerb: "GET",
        ControllerName: data.email ? "Engagement" : "DecarbLibrary",
        ActionName: url,
        QueryString: data.email ? "CreatedBy=" + data.email : "",
        Data: "",
      }
    ),
    axios.post(
      `${process.env.REACT_APP_API_URL_REG_APAC}WebApi/Invoke`,
      //  `https://useufas0bbwap02.azurewebsites.net/api/v1/WebApi/Invoke`,
      {
        ActionVerb: "GET",
        ControllerName: data.email ? "Engagement" : "DecarbLibrary",
        ActionName: url,
        QueryString: data.email ? "CreatedBy=" + data.email : "",
        Data: "",
      },
      {
        withCredentials: true,
        // baseURL: request.baseUrl,
        headers: {
          Authorization: `Bearer ${data.token}`,
        },
      }
    ),
  ]);

  let response = [];
  let mergedResponse = [];
  let externalUser = "";
  const validData = axiosResponse.map((res) => {
    if (res.status === "fulfilled") {
      return res.value.data;
    }

    if (res.status === "rejected") {
      console.error("Error in response", res);
    }
  });

  if (validData && validData.length) {
    externalUser = validData[0].externalUserFlag;
    response = validData.flat(1).filter((x) => x);
  }

  response.map((item) => {
    mergedResponse = mergedResponse.concat(item.data);
  });

  return [mergedResponse, externalUser];
}
