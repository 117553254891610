import { Project_URL } from "./endpoints/project";
import { get, post } from "./base.service";
import axios from "axios";

async function GetProjectDistinctTags({ request }) {
  // return await get(
  //   `${Project_URL.GetDistinctTags}?EngagementID=${request.engagementId}`
  // );
  return await post(`/WebApi/Invoke`, {
    ActionVerb: "GET",
    ControllerName: "Project",
    ActionName: Project_URL.GetDistinctTags,
    QueryString: "EngagementID=" + request.engagementId,
    Data: "",
  });
}

async function getDistinctProjectsNames({ request }) {
  // return await get(
  //   `${Project_URL.GetDistinctProjects}?EngagementID=${request.engagementId}`
  // );
  return await post(`/WebApi/Invoke`, {
    ActionVerb: "GET",
    ControllerName: "Project",
    ActionName: Project_URL.GetDistinctProjects,
    QueryString: "EngagementID=" + request.engagementId,
    Data: "",
  });
}

async function createProjectDraft({ request }) {
  // return await post(`${Project_URL.CreateProjectDraft}`, request);
  return await post(`/WebApi/Invoke`, {
    ActionVerb: "POST",
    ControllerName: "Project",
    ActionName: Project_URL.CreateProjectDraft,
    QueryString: "",
    Data: request,
  });
}

async function createM3Project({ dataSet }) {
  // return await post(
  //   `${Project_URL.createM3Project}?engagementCode=${dataSet.engagementCode}&projectID=${dataSet.projectID}&createdBy=${dataSet.createdBy}`
  // );
  return await post(`/WebApi/Invoke`, {
    ActionVerb: "POST",
    ControllerName: "Project",
    ActionName: Project_URL.createM3Project,
    QueryString:
      "engagementCode=" +
      dataSet.engagementCode +
      `&` +
      "projectID=" +
      dataSet.projectID +
      `&` +
      "createdBy=" +
      dataSet.createdBy,
    Data: "",
  });
}

async function saveProjectTags({ payload }) {
  // return await post(`${Project_URL.SaveProjectTags}`, payload);
  return await post(`/WebApi/Invoke`, {
    ActionVerb: "POST",
    ControllerName: "Project",
    ActionName: Project_URL.SaveProjectTags,
    QueryString: "",
    Data: payload,
  });
}

async function getReduceDefautViewData({ request }) {
  // return await post(`${Project_URL.reduceDefaultViewData}`, request);
  return await post(`/WebApi/Invoke`, {
    ActionVerb: "POST",
    ControllerName: "Project",
    ActionName: Project_URL.reduceDefaultViewData,
    QueryString: "",
    Data: request,
  });
}

async function saveEmissionFiltersReduce({ payload }) {
  // return await post(`${Project_URL.SaveEmissionFilters}`, payload);
  return await post(`/WebApi/Invoke`, {
    ActionVerb: "POST",
    ControllerName: "Project",
    ActionName: Project_URL.SaveEmissionFilters,
    QueryString: "",
    Data: payload,
  });
}

async function getSaveProjectRule({ request }) {
  // return await get(
  //   `${Project_URL.GetSaveProjectRule}?ProjectID=${request.projectID}`
  // );
  return await post(`/WebApi/Invoke`, {
    ActionVerb: "POST",
    ControllerName: "Project",
    ActionName: Project_URL.GetSaveProjectRule,
    QueryString: "ProjectID=" + request.projectID,
    Data: "",
  });
}

async function saveM3Step2ReduceData({ payload }) {
  // return await post(`${Project_URL.SaveM3Step2ReduceData}`, payload);
  return await post(`/WebApi/Invoke`, {
    ActionVerb: "POST",
    ControllerName: "Project",
    ActionName: Project_URL.SaveM3Step2ReduceData,
    QueryString: "",
    Data: payload,
  });
}

async function getM3TableGraphData({ simulateSavePayload }) {
  // return await post(`${Project_URL.getM3TableGraphData}`, simulateSavePayload);
  return await post(`/WebApi/Invoke`, {
    ActionVerb: "POST",
    ControllerName: "Project",
    ActionName: Project_URL.getM3TableGraphData,
    QueryString: "",
    Data: simulateSavePayload,
  });
}

async function getProjectDetailsById({ request }) {
  // return await get(
  //   `${Project_URL.ProjectEditDetails}?projectID=${request.projectID}`
  // );
  return await post(`/WebApi/Invoke`, {
    ActionVerb: "GET",
    ControllerName: "Project",
    ActionName: Project_URL.ProjectEditDetails,
    QueryString: "projectID=" + request.projectID,
    Data: "",
  });
}

async function getProjectEmissionFilters({ request }) {
  // return await get(
  //   `${Project_URL.ProjectEmissionSelection}?projectID=${request.projectID}`
  // );
  return await post(`/WebApi/Invoke`, {
    ActionVerb: "GET",
    ControllerName: "Project",
    ActionName: Project_URL.ProjectEmissionSelection,
    QueryString: "ProjectID=" + request.projectID,
    Data: "",
  });
}

async function updateProjectStep({ request }) {
  // return await post(
  //   `${Project_URL.UpdateProjectStep}?ProjectID=${request.projectId}&StepID=${request.stepId}`
  // );
  return await post(`/WebApi/Invoke`, {
    ActionVerb: "POST",
    ControllerName: "Project",
    ActionName: Project_URL.UpdateProjectStep,
    QueryString:
      "ProjectID=" + request.projectId + `&` + "StepID=" + request.stepId,
    Data: "",
  });
}

async function getM3GraphData({ request }) {
  // return await get(
  //   `${Project_URL.GetM3GraphData}?engagementCode=${request.engagementCode}&projectId=${request.projectId}`
  // );
  return await post(`/WebApi/Invoke`, {
    ActionVerb: "GET",
    ControllerName: "Project",
    ActionName: Project_URL.GetM3GraphData,
    QueryString:
      "engagementCode=" +
      request.engagementCode +
      `&` +
      "projectId=" +
      request.projectId,
    Data: "",
  });
}

async function recreateProjectM3({ request }) {
  // return await post(`${Project_URL.RecreateProject}`, request);
  return await post(`/WebApi/Invoke`, {
    ActionVerb: "POST",
    ControllerName: "Project",
    ActionName: Project_URL.RecreateProject,
    QueryString: "",
    Data: request,
  });
}

async function getM4GetProjectsScenarios({ request }) {
  // return await get(
  //   `${Project_URL.GetProjectsScenario}?EngagementID=${request.engagementId}&createdBy=${request.createdBy}`
  // );
  return await post(`/WebApi/Invoke`, {
    ActionVerb: "GET",
    ControllerName: "Project",
    ActionName: Project_URL.GetProjectsScenario,
    QueryString:
      "EngagementID=" +
      request.engagementId +
      `&` +
      "createdBy=" +
      request.createdBy,
    Data: "",
  });
}

async function getM4NavBarProjects({ request }) {
  // return await get(
  //   `${Project_URL.GetNavBarProjects}?EngagementID=${request.engagementId}&createdBy=${request.createdBy}&ScenarioID=${request.scenarioId}`
  // );
  return await post(`/WebApi/Invoke`, {
    ActionVerb: "GET",
    ControllerName: "Project",
    ActionName: Project_URL.GetNavBarProjects,
    QueryString:
      "EngagementID=" +
      request.engagementId +
      `&` +
      "createdBy=" +
      request.createdBy +
      `&` +
      "ScenarioID=" +
      request.scenarioId,
    Data: "",
  });
}

async function resetProjectM3({ request }) {
  // return await post(
  //   `${Project_URL.ResetProject}?EngagementCode=${request.engagementCode}&ProjectID=${request.projectID}`
  // );
  return await post(`/WebApi/Invoke`, {
    ActionVerb: "POST",
    ControllerName: "Project",
    ActionName: Project_URL.ResetProject,
    QueryString:
      "EngagementCode=" +
      request.engagementCode +
      `&` +
      "ProjectID=" +
      request.projectID,
    Data: "",
  });
}

async function getUserModuleLock({ request }) {
  let queryParams =
    "LockedBy=" +
    encodeURIComponent(request.LockedBy) +
    `&` +
    "ModuleName=" +
    encodeURIComponent(request.ModuleName);

  if (request.EngagementID) {
    queryParams +=
      `&` + "EngagementID=" + encodeURIComponent(request.EngagementID);
  }

  // return await get(`${Project_URL.GetUserModuleLock}?${queryParams}`);
  return await post(`/WebApi/Invoke`, {
    ActionVerb: "GET",
    ControllerName: "Project",
    ActionName: Project_URL.GetUserModuleLock,
    QueryString: queryParams,
    Data: request,
  });
}

async function manualEditM3Step2({ payload }) {
  // return await post(`${Project_URL.ManualEdit}`, payload);
  return await post(`/WebApi/Invoke`, {
    ActionVerb: "POST",
    ControllerName: "Project",
    ActionName: Project_URL.ManualEdit,
    QueryString: "",
    Data: payload,
  });
}

async function SaveUserModuleLock(request) {
  // return await post(`${Project_URL.SaveUserModuleLock}`, request);
  return await post(`/WebApi/Invoke`, {
    ActionVerb: "POST",
    ControllerName: "Project",
    ActionName: Project_URL.SaveUserModuleLock,
    QueryString: "",
    Data: request,
  });
}

async function UpdateUserModuleLock(request) {
  // return await post(`${Project_URL.UpdateUserModuleLock}`, request);
  return await post(`/WebApi/Invoke`, {
    ActionVerb: "POST",
    ControllerName: "Project",
    ActionName: Project_URL.UpdateUserModuleLock,
    QueryString: "",
    Data: request,
  });
}

async function ReleaseUserModuleLock(request) {
  // return await post(`${Project_URL.ReleaseUserModuleLock}`, request);
  return await post(`/WebApi/Invoke`, {
    ActionVerb: "POST",
    ControllerName: "Project",
    ActionName: Project_URL.ReleaseUserModuleLock,
    QueryString: "",
    Data: request,
  });
}

async function getColumnValueFlags({ flagsPayload }) {
  // return await post(`${Project_URL.ColumnFlags}`, flagsPayload);
  return await post(`/WebApi/Invoke`, {
    ActionVerb: "POST",
    ControllerName: "Project",
    ActionName: Project_URL.ColumnFlags,
    QueryString: "",
    Data: flagsPayload,
  });
}

async function GetProjectdetails({ request }) {
  // return await get(
  //   `${Project_URL.GetProjectdetails}?projectID=${request.projectID}`
  // );
  return await post(`/WebApi/Invoke`, {
    ActionVerb: "POST",
    ControllerName: "Project",
    ActionName: Project_URL.GetProjectdetails,
    QueryString: "projectID=" + request.projectID,
    Data: "",
  });
}

async function GetProjectBusinessCaseSummaryData({ request }) {
  // return await get(
  //   `${Project_URL.GetProjectBusinessCaseSummaryData}?ProjectID=${request.projectId}`
  // );
  return await post(`/WebApi/Invoke`, {
    ActionVerb: "POST",
    ControllerName: "GMP",
    ActionName: Project_URL.GetProjectBusinessCaseSummaryData,
    QueryString: "ProjectID=" + request.projectId,
    Data: "",
  });
}

async function GetProjectFinancialsAbatementData({ request }) {
  // return await post(
  //   `${Project_URL.GetProjectFinancialsAbatementData}`,
  //   request
  // );
  return await post(`/WebApi/Invoke`, {
    ActionVerb: "POST",
    ControllerName: "DecarbLibrary",
    ActionName: Project_URL.GetProjectFinancialsAbatementData,
    QueryString: "",
    Data: request,
  });
}

async function GetProjectCashflowData({ request }) {
  // return await get(
  //   `${Project_URL.GetProjectCashFlowData}?EngagementCode=${request.engagementCode}&ProjectID=${request.projectId}`
  // );
  return await post(`/WebApi/Invoke`, {
    ActionVerb: "GET",
    ControllerName: "GMP",
    ActionName: Project_URL.GetProjectCashFlowData,
    QueryString:
      "EngagementCode=" +
      request.engagementCode +
      `&` +
      "ProjectID=" +
      request.projectId,
    Data: "",
  });
}

async function getUserLockByModule({ request }) {
  // return await axios
  //   .get(
  //     `${Project_URL.GetUserLockByModule}?LockedBy=${request.LockedBy}&EngagementID=${request.EngagementID}&ModuleName=${request.ModuleName}`
  //   )
  //   .then((res) => {
  //     if (res.status === 200) {
  //       return res.data;
  //     }
  //     throw new Error("Error in response - " + res);
  //   })
  //   .catch((err) => {
  //     throw err;
  //   });
  return await post(`/WebApi/Invoke`, {
    ActionVerb: "POST",
    ControllerName: "Project",
    ActionName: Project_URL.GetUserLockByModule,
    QueryString:
      "LockedBy=" +
      request.LockedBy +
      `&` +
      "EngagementID=" +
      request.EngagementID +
      `&` +
      "ModuleName=" +
      request.ModuleName,
    Data: "",
  });
}

async function SaveM3Step2ReplaceData({ simulateSaveReplacePayload }) {
  // return await post(
  //   `${Project_URL.SaveM3StepData}`,
  //   simulateSaveReplacePayload
  // );
  return await post(`/WebApi/Invoke`, {
    ActionVerb: "POST",
    ControllerName: "Project",
    ActionName: Project_URL.SaveM3StepData,
    QueryString: "",
    Data: simulateSaveReplacePayload,
  });
}

async function SetProjectStatustoFinalize({ request }) {
  // return await post(
  //   `${Project_URL.SetProjectstatustoFinalise}?projectId=${request.projectId}`
  // );
  return await post(`/WebApi/Invoke`, {
    ActionVerb: "POST",
    ControllerName: "Project",
    ActionName: Project_URL.SetProjectstatustoFinalise,
    QueryString: "projectId=" + request.projectId,
    Data: request,
  });
}

async function copyFromPastVersionsProject({ request }) {
  // return await post(
  //   `${Project_URL.CopyFromPreviousVersion}?EngagementCode=${request.engagementCode}`
  // );
  return await post(`/WebApi/Invoke`, {
    ActionVerb: "POST",
    ControllerName: "Project",
    ActionName: Project_URL.CopyFromPreviousVersion,
    QueryString: "EngagementCode=" + request.engagementCode,
    Data: request,
  });
}

async function unitValidationStep2Module3({ request }) {
  return await post(`/WebApi/Invoke`, {
    ActionVerb: "POST",
    ControllerName: "Project",
    ActionName: Project_URL.GetUnitValidation,
    QueryString:
      "EngagementCode=" +
      request.engagementCode +
      `&` +
      "ProjectId=" +
      request.projectID +
      `&` +
      "viewtype=Default" +
      `&` +
      "modifiedBy=" +
      request.modifiedBy +
      `&` +
      "Counter=" +
      0,
    Data: "",
  });
}

async function deleteLastAppliedRule({ request }) {
  return await post(`WebApi/Invoke`, {
    ActionVerb: "POST",
    ControllerName: "Project",
    ActionName: Project_URL.DeleteLastAppliedRule,
    QueryString: "projectId=" + request.projectId,
    Data: "",
  });
}

export {
  GetProjectDistinctTags,
  getDistinctProjectsNames,
  createProjectDraft,
  createM3Project,
  saveProjectTags,
  getReduceDefautViewData,
  saveEmissionFiltersReduce,
  getSaveProjectRule,
  saveM3Step2ReduceData,
  getM3TableGraphData,
  getProjectDetailsById,
  getProjectEmissionFilters,
  updateProjectStep,
  getM3GraphData,
  recreateProjectM3,
  getM4GetProjectsScenarios,
  getM4NavBarProjects,
  resetProjectM3,
  getUserModuleLock,
  SaveUserModuleLock,
  ReleaseUserModuleLock,
  UpdateUserModuleLock,
  manualEditM3Step2,
  getColumnValueFlags,
  GetProjectdetails,
  getUserLockByModule,
  GetProjectBusinessCaseSummaryData,
  GetProjectFinancialsAbatementData,
  GetProjectCashflowData,
  SaveM3Step2ReplaceData,
  SetProjectStatustoFinalize,
  copyFromPastVersionsProject,
  unitValidationStep2Module3,
  deleteLastAppliedRule,
};
