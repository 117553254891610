// Header Component
const appName = "Decarbonization Workbench";

// Home component constants
const homeHeader = "EY Decarbonization Workbench";
const homeHeaderBody =
  "Unlock insights to help clients develop effective net zero transition plans and reduce GHG emissions cost-effectively and at scale.";
const homeViewDashboard = "View engagement dashboard";
const homeSolutionModules = "Solution modules";
const viewresults = "View results";
const homescreencards = [
  {
    image: "M1Home",
    heading: "Manage emissions data",
    description:
      "Manage data for actual (current and past) Scope 1, 2 and 3 GHG emissions",
  },
  {
    image: "M2Home",
    heading: "Forecast future emissions",
    description: "Forecast ‘business-as-usual’ emissions",
  },
  {
    image: "M3Home",
    heading: "Analyze emissions reduction options",
    description:
      "Analyze the abatement potential and financial impact of emissions reduction options",
  },
  {
    image: "M4Home",
    heading: "Simulate scenarios and set roadmaps",
    description:
      "Simulate different emissions reduction scenarios and set a decarbonization roadmap",
  },
];

// Setup module constants
const setupCreateNewEngHeader = "Create new engagement";
const setupHeader = "Engagement setup";
const setupEngagementCode = "Engagement code";
const setupEngagementName = "Engagement name";
const setupSector = "Sector";
const setupClientName = "Client name";
const setupInternalTeam = "Internal team";
const setupExternalTeam = "External team";
const setupTeamSetup = "Team setup";
const setupTableHeaderName = "Name";
const setupTableHeaderEmailId = "Email ID";
const setupTableHeaderTeam = "Team";

// Setup module constants / Validation messages
const setupEngagementCodeRequired = "Engagement code is required";
const setupEngagementCodeLength =
  "Engagement code length must not exceed 20 characters!";
// Setup module constants / Delete modal
const setupRemoveUserModalHeader = "Remove user?";
const setupRemoveUserModalBody =
  "Are you sure you want to remove this user? He/she will no longer have access to this engagement.";

const geoSpec = "Geographical specifications";

const paginationOptions = [5, 10, 15, 30];
const emissionTableColumns = [
  {
    field: "checkbox",
    width: 100,
    headerName: "",
    suppressMovable: true,
    checkboxSelection: true,
    headerCheckboxSelection: true,
    pinned: "left",
  },
  {
    field: "scope",
    width: 150,
    headerName: "Scope",
    suppressMovable: true,
    editable: false,
    pinned: "left",
  },
  {
    field: "category_level1",
    minWidth: 150,
    headerName: "Category 1",
    suppressMovable: true,
    pinned: "left",
    editable: false,
  },
  {
    field: "category_level2",
    minWidth: 150,
    headerName: "Category 2",
    suppressMovable: true,
    pinned: "left",
    editable: false,
  },
  {
    field: "category_level3",
    minWidth: 150,
    headerName: "Category 3",
    suppressMovable: true,
    pinned: "left",
    editable: false,
  },
  {
    field: "region_level1",
    minWidth: 200,
    headerName: "Region level 1",
    suppressMovable: true,
    editable: false,
  },
  {
    field: "region_level2",
    minWidth: 200,
    headerName: "Region level 2",
    suppressMovable: true,
    editable: false,
  },
  {
    field: "region_level3",
    minWidth: 200,
    headerName: "Region level 3",
    suppressMovable: true,
    editable: false,
  },
  {
    field: "entity_level1",
    minWidth: 200,
    headerName: "Entity level 1",
    suppressMovable: true,
    editable: false,
  },
  {
    field: "entity_level2",
    minWidth: 200,
    headerName: "Entity level 2",
    suppressMovable: true,
    editable: false,
  },
  {
    field: "entity_level3",
    minWidth: 200,
    headerName: "Entity level 3",
    suppressMovable: true,
    editable: false,
  },
  {
    field: "entity_level4",
    minWidth: 200,
    headerName: "Entity level 4",
    suppressMovable: true,
    editable: false,
  },
  {
    field: "custom_level1",
    minWidth: 200,
    headerName: "Custom 1",
    suppressMovable: true,
    editable: false,
  },
  {
    field: "custom_level2",
    minWidth: 200,
    headerName: "Custom 2",
    suppressMovable: true,
    editable: false,
  },
  {
    field: "custom_level3",
    minWidth: 200,
    headerName: "Custom 3",
    suppressMovable: true,
    editable: false,
  },
  {
    field: "data_type",
    minWidth: 200,
    headerName: "Data type",
    suppressMovable: true,
    editable: false,
  },
  {
    field: "unit",
    filter: true,
    headerName: "Unit",
    sortable: true,
    minWidth: 150,
    flex: 1,
    suppressMovable: true,
    editable: false,
  },
  {
    field: "2025",
    filter: true,
    headerName: "2025",
    sortable: true,
    minWidth: 150,
    flex: 1,
    suppressMovable: true,
    editable: false,
  },
  {
    field: "2026",
    filter: true,
    headerName: "2026",
    sortable: true,
    minWidth: 150,
    flex: 1,
    suppressMovable: true,
    editable: false,
  },
  {
    field: "2027",
    filter: true,
    headerName: "2027",
    sortable: true,
    minWidth: 150,
    flex: 1,
    suppressMovable: true,
    editable: false,
  },
  {
    field: "2028",
    filter: true,
    headerName: "2028",
    sortable: true,
    minWidth: 150,
    flex: 1,
    suppressMovable: true,
    editable: false,
  },
  {
    field: "2029",
    filter: true,
    headerName: "2029",
    sortable: true,
    minWidth: 150,
    flex: 1,
    suppressMovable: true,
    editable: false,
  },
  {
    field: "2030",
    filter: true,
    headerName: "2030",
    sortable: true,
    minWidth: 150,
    flex: 1,
    suppressMovable: true,
    editable: false,
  },
  {
    field: "2031",
    filter: true,
    headerName: "2031",
    sortable: true,
    minWidth: 150,
    flex: 1,
    suppressMovable: true,
    editable: false,
  },
  {
    field: "2032",
    filter: true,
    headerName: "2032",
    sortable: true,
    minWidth: 150,
    flex: 1,
    suppressMovable: true,
    editable: false,
  },
  {
    field: "2033",
    filter: true,
    headerName: "2033",
    sortable: true,
    minWidth: 150,
    flex: 1,
    suppressMovable: true,
    editable: false,
  },
  {
    field: "2034",
    filter: true,
    headerName: "2034",
    sortable: true,
    minWidth: 150,
    flex: 1,
    suppressMovable: true,
    editable: false,
  },
  {
    field: "2035",
    filter: true,
    headerName: "2035",
    sortable: true,
    minWidth: 150,
    flex: 1,
    suppressMovable: true,
    editable: false,
  },
  {
    field: "2036",
    filter: true,
    headerName: "2036",
    sortable: true,
    minWidth: 150,
    flex: 1,
    suppressMovable: true,
    editable: false,
  },
];
const tablecolumnFilters = ["10 years", "All years", "Forecast start year"];
const setUpTableCOlumns = [
  {
    field: "name",
    headerComponentParams: {
      enableSorting: true,
      showFilter: false,
    },
    headerName: "Name",
    sortable: true,
    flex: 1,
  },
  {
    field: "email",
    filter: true,
    headerComponentParams: {
      enableSorting: false,
      showFilter: true,
    },
    headerName: "Email ID",
    flex: 1,
  },
  {
    field: "engagementTeamTypeID",
    filter: true,
    headerComponentParams: {
      enableSorting: false,
      showFilter: true,
    },
    headerName: "Team",
    flex: 1,
  },
];
const analyseBodyTitle = "Analyze emissions reduction options";
const analyseBodyDescription =
  "Analyze the abatement potential and financial impact of emissions reduction options";
const createNewProject = "Create new project";
const copyFromPastVersions = "Copy from past versions";
const analyseProjectsData = "Analyze emissions reduction options";
const analyseTableHeaderProjectName = "Project name";
const analyseTableHeaderProjectType = "Project type";
const analyseTableHeaderSimplePaybackPeriod = "Simple payback period (yrs)";
const analyseTableHeaderTotalAbatement = "Total abatement (tCO\u{2082}e)";
const analyseTableHeaderAverageAnnualAbatement =
  "Average annual abatement (tCO\u{2082}e/yr)";
const analyseTableHeaderTechnicalAbatementPotential =
  "Technical abatement potential (%)";

const analyseTableHeaderNetPresentValue = "Net present value ($)";
const analyseTableHeaderInternalRateOfReturn =
  "Internal rate of return (%.p.a.)";
const analyseTableHeaderDiscountedPaybackPeriod =
  "Discounted payback period (yrs)";
const analyseTableHeaderMarginalAbatementCost =
  "Marginal abatement cost ($/tCO\u{2082}e)";
const deleteProjectModalHeader = "Delete project";
const deleteProjectModalBody =
  "This action will delete the project, it cannot be undone";
const deleteProjectModalBodyDesc = "Are you sure you want to delete?";
const duplicateProjectNameError = "Project name already exist";
const newProjectHeaderDescr =
  "Fill out the information on Step 1-3 to ensure accurate results in Analysation Results tab.";
const emissionsHeaderDesc =
  "Replace your activity data by clicking on Edit (pen icon), or apply percentage editing against activity data through Bulk editing.";
const financialHeaderDesc =
  "Update you CapEx and OpEx financials by clicking on Edit (pen icon).";
const projectToLibraryHeader = "Request successfully submitted.";
const projectToLibraryBody =
  "Would you like to proceed with transferring this project to the project library? Upon confirmation, the project will move to project library within a timeframe of 10  minutes.";

const unitValidationTableColDefs = [
  {
    field: "unit",
    // width: 200,
    headerName: "Units affected by rules",
    headerTooltip: "Units affected by rules",
    suppressMovable: true,
    wrapText: true,
    autoHeight: true,
    wrapHeaderText: true,
    autoHeaderHeight: true,
  },
  {
    field: "parameter_Name",
    // width: 200,
    headerName: "Parameters applied",
    headerTooltip: "Parameters applied",
    suppressMovable: true,
    wrapText: true,
    autoHeight: true,
    wrapHeaderText: true,
    autoHeaderHeight: true,
  },
];
const unitValidationMsg =
  "The following parameters have not returned a match :";
const unitValidationHeader = "Unit validation";
// Add GMP module constants
const addGMPBtn = "Financial parameters";
const syncData = "Sync data";
const setParameters = " Set parameters";
const addGMPDataType = "Data type";
const addGMPparameterType = "Parameter type";
const addGMPParameterName = "Parameter name";
const addGMPForceCost = "Force as cost";
const addGMPVariableType = "Dependent or independent";
const addGMPSourceYear = "Source year";
const addGMPSource = "Source";
const addGMPDependency = "Data type";
const addGMPAllocation = "Allocation";
const addGMPStartYearValue = "Start value";
const addGMPCurrencyUnit = "Currency unit";
const addGMPUnit = "Data unit";
const addGMPStartYear = "Start year";
const addGMPEndyear = "End year";
const addGMPCAGR = "CAGR (% p.a.)";
const addGMPDataTypeOptions = ["CapEx", "OpEx", "Discount rate"];
const addGMPForceCostOptions = ["Yes", "No"];
const addGMPVariableTypeOptions = ["Dependent", "Independent"];
const addGMPDependencyOptions = [
  "Activity data",
  "Emissions",
  "Not applicable",
];
const addGMPDependencyOptionsNew = ["Activity data", "Emissions"];
const deleteRuleModalHeader = "Delete rule";
const deleteRuleModalBody =
  "This action will delete the rule, it cannot be undone";
const deleteRuleModalBodyDesc = "Are you sure you want to delete?";
const deleteGMPModalHeader = "Delete financial parameter";
const deleteGMPModalBody =
  "This action will delete the rule, it cannot be undone";
const deleteGMPModalBodyDesc = "Are you sure you want to delete?";
const paramNameValidationMsg =
  "The parameter name conflicts with the chosen fields for 'Parameter type, force as cost, Data type & Dependent or independent.' Please modify the name or adjust the field selection to successfully save the Financial parameter";

// Manage emissions module constants
const manageEmissionHeaderMain = "Manage emissions data";
const manageEmisionHeaderDesc =
  "Manage data for actual (current and past) Scope 1, 2 and 3 GHG emissions";
const manageEmissionBodyTemplateTitle = "Template file";
const manageEmissionBodyUploadTitle = "Upload your input file";
const manageEmissionBodyDownloadFile = "Download template file";
const manageEmissionBodyUploadBaseYear = "Base year";
const manageEmissionBodyUploadRecentYear = "Most recent year";
const manageEmissionBodyUploadSaveFile = "Upload file";
const manageEmissionBodyUploadFileHistory = "Version history";

// Manage emissions / File version history modal constants
const fileVersionHistoryModalHeaderDate = "Date";
const fileVersionHistoryModalHeaderFileName = "File name";
const fileVersionHistoryModalHeaderUploadedBy = "Uploaded by";
const fileVersionHistoryModalLiveVersion = "Live version";
const saveButtonTooltip = "Click here to upload the input file";

// Forecast module constants
const foreEndYear = "Forecast end year";
const foreStartYear = "Most recent year";
const forcastResetModalHeader = "Delete forecast";
const forcastResetModalBody =
  "Once you confirm the deletion of the M2 data, all associated forecasts and targets will be permanently removed.";
const forcastResetModalBodyDesc = "Are you sure you want to delete?";
const forecastHeaderDesc = "Forecast ‘business-as-usual’ emissions";
const forecastHeaderMain = "Forecast future emissions";
const forecastBodyCalculateEmissionsSummaryTitle = "Business-as-usual summary";
const forecastBodyCalculateEmissionsSummaryTargetRequired = "Target required";
const forecastBodyCalculateEmissionsSummaryForecastRequired =
  "Forecast required";
const forecastBodyCalculateEmissionsSummaryCoveredEmission = "Average coverage";
const forecastBodyCalculateEmissionsSummaryEmissionCoverage =
  "over the forecast period";
const forecastBodyCalculateEmissionsSummaryChangeOfEmission =
  "Change of emissions (scenario over the forecast period)";
const forecastBodyCalculateEmissionsSummaryEmissionReducationTarget =
  "Emission reduction target";
const forecastBodyCalculateEmissionsSummaryEmissionReductioonGap =
  "Emission reduction gap (target year)";
const forecastBodyCalculateEmissionsSummaryRelativeToBaseYear =
  "Emission reduction target relative to base year";
const forecastBodyCalculateEmissionsSummaryRelativeToTargetYear =
  "Difference in emissions reductions relative to target";

// Forecast / Skip module constants
const forecastSkipModalBody =
  "Indicate your forecast end year, and confirm if you want to skip manually defining any forecast assumptions? This would mean the actuals from the most recent year will be taken as reference for all subsequent years until the forecast end year.";
const forecastSkipModalHeader = "Skip manual forecast assumptions?";
const forecastSkipModalEmptyFEY =
  "It is mandatory to save the forecast end year";

// Forecast / Chart module constants
const forecastBodyChartTitle = "Target and forecast for ";
const forecastBodyChartScope1And2 = "Scope 1 and 2 emissions";
const forecastBodyChartScope3 = "Scope 3 emissions";
const forecastScopeOptions = [
  {
    name: "Scope 1 and 2",
    id: 2,
  },
  {
    name: "Scope 3",
    id: 3,
  },
];
const forecastChartYAxisLabel = "Emissions  (tCO\u{2082}e)";
const forecastChartNoData = "No data to display";
const forecastChartNoDataDesc1 = "Please add your forecasts and targets,";
const forecastChartNoDataDesc2 = "or click on next to see results.";
const forecastChartXAxisEmissionsWithTarget = "BAU emissions (with target)";
const forecastChartXAxisEmissionsWithoutTarget =
  "BAU emissions (without target)";
const forecastChartXAxisTarget = "Target";
const forecastChartProcessingForecastAndTargetData =
  "We're currently processing the provided $1 data";
const forecastChartDataLoadingMessage = "Loading data...";
const forecastChartForecastLabel = "forecast";
const forecastChartTargetLabel = "target";
const forecastChartForecastAndTargetLabel = "forecast and target";

// Forecast / Change FEY Modal constatns
const changeFEYModalHeader = "Change forecast end year?";
const changeFEYModalBody =
  "Are you sure you want to change the forecast end year? The applied forecast from excel and targets will be reset. And the UI forecast applied previously will auto apply if no change in the hierarchy.";

// Forecast / Add Target module constants
const addTarget = "Add targets";
const addTargetTableHeaderTargetName = "Target name";
const addTargetTableHeaderTargetDesc = "Target description";
const addTargetTableHeaderGeo = "Geographical";
const addTargetTableHeaderOrg = "Organisational";
const addTargetTableHeaderCustom = "Custom";
const addTargetTableHeaderTargetType = "Target type";
const addTargetTableHeaderTemporalType = "Temporal type";
const addTargetTableHeaderDataPercentageValue = "Value (% p.a.)";
const addTargetTableHeaderDataBaseYear = "Base year";
const addTargetTableHeaderDataTargetYear = "Target year";

// Forecast / Add Target / Add Target Specification module constants
const addTargetSpecificationName = "Add target details";
const addTargetSpecificationTargetName = "Target name";
const addTargetSpecificationTargetDesc = "Target description";
const addTargetSpecificationTargetType = "Target type";
const addTargetSpecificationTemporalTypeOptions = [
  {
    name: "Annual",
    id: "Annual",
  },
  {
    name: "Target end year",
    id: "Target End Year",
  },
];
const addTargetSpecificationTargetTemporalType = "Temporal type";
const addTargetSpecificationTargetTypeOptions = [
  {
    name: "Intensity",
    id: "Intensity",
  },
  {
    name: "Absolute",
    id: "Absolute",
  },
];
const addTargetSpecificationPercentValueAnnual = "Value (% p.a.)";
const addTargetSpecificationPercentValueTargetYear = "Value (%)";
const addTargetSpecificationBaseYear = "Base year";
const addTargetSpecificationTargetYear = "Target year";
const addTargetSpecificationEmission = "Emission";
const addTargetSpecificationAddEmissionBtn = "Add emission";
const addTargetSpecificationGeo = "Geographical";
const addTargetSpecificationAddGeoBtn = "Add geographical";
const addTargetSpecificationOrg = "Organisational";
const addTargetSpecificationAddOrgBtn = "Add organisational";
const addTargetSpecificationCustom = "Custom";
const addTargetSpecificationAddCustomBtn = "Add custom";
const activityOutputText = "Set alternative output for the intensity target?";
const activityOutput = "Activity output";
// Forecast / Add Forecast / Add Forecast Specification / Validation messages
const addTargetSpecificationEmissionRequired = "Emission data is required";
const addTargetSpecificationGeoRequired = "Geographical data is required";
const addTargetSpecificationOrgRequired = "Organisational data is required";
const addTargetSpecificationTargetTypeRequired = "Target type is required";
const addTargetSpecificationTemporalTypeRequired = "Temporal type is required";
const addTargetSpecificationPercentValueAnnualRequired =
  "Value (% p.a.) is required";
const addTargetSpecificationPercentValueTargetYearRequired =
  "Value (%) is required";
const addTargetSpecificationBaseYearRequired = "Base year is required";
const addTargetSpecificationBaseYearLessThanMRY =
  "Base year should be equal or less than the Most recent year";
const addTargetSpecificationBaseYearGreaterThanFEY =
  "Base year should be greater than Most recent year - 10";
const addTargetSpecificationTargetYearRequired = "Target year is required";
const addTargetSpecificationTargetYearLessThanFEY =
  "Target year should be equal or less than the Forecast end year";
const addTargetSpecificationTargetYearGreaterThanMRY =
  "Target year should be greater than Most recent year";
const addTargetSpecificationForecastNameRequired = "Target name is required";
const duplicateIntensityTargetUnitModalHeader = "Unit consistency confirmation";
const duplicateIntensityTargetUnitDescription =
  "Please ensure that the activity data is measured in consistent units before setting the intensity target. Consider reviewing the choices made regarding this dropdown selection.";

// Forecast / Add Forecast module constants
const addForecast = "Add forecasts";
const addForecastTableHeaderForecastName = "Forecast name";
const addForecastTableHeaderForecastDesc = "Forecast description";
const addForecastTableHeaderEmission = "Emission";
const addForecastTableHeaderGeo = "Geographical";
const addForecastTableHeaderOrg = "Organisational";
const addForecastTableHeaderCustom = "Custom";
const addForecastTableHeaderDataType = "Data type";
const addForecastTableHeaderDataPercentageValue = "Value (% p.a.)";
const addForecastTableHeaderDataStartYear = "Start year";
const addForecastTableHeaderDataEndYear = "End year";

// Forecast / Add Forecast / Add Forecast Specification module constants
const addForecastSpecificationHeader = "Add forecast details";
const addForecastSpecificationForecastName = "Forecast name";
const addForecastSpecificationForecastDesc = "Forecast description";
const addForecastSpecificationDataType = "Data type";
const addForecastSpecificationDataTypeOptions = [
  "Activity data",
  "Emission factor",
];
const addForecastSpecificationPercentValue = "Value (% p.a.)";
const addForecastSpecificationStartYear = "Start year";
const addForecastSpecificationEndYear = "End year";
const emission = "Emission";
const addForecastSpecificationAddEmissionBtn = "Add emission";
const addForecastSpecificationGeo = "Geographical";
const addForecastSpecificationAddGeoBtn = "Add geographical";
const addForecastSpecificationOrg = "Organisational";
const addForecastSpecificationAddOrgBtn = "Add organisational";
const addForecastSpecificationCustom = "Custom";
const addForecastSpecificationAddCustomBtn = "Add custom";

// Forecast / Add Forecast / Add Forecast Specification / Validation messages
const addForecastSpecificationEmissionRequired = "Emission data is required";
const addForecastSpecificationGeoRequired = "Geographical data is required";
const addForecastSpecificationOrgRequired = "Organisational data is required";
const addForecastSpecificationDataTypeRequired = "Data type is required";
const addForecastSpecificationPercentValueRequired =
  "Value (% p.a.) is required";
const addForecastSpecificationStartYearRequired = "Start year is required";
const addForecastSpecificationStartYearGreaterThanMRY =
  "Start year should be greater than the most recent year";
const addForecastSpecificationStartYearLessThanFEY =
  "Start year should be less than forecast end year";
const addForecastSpecificationEndYearRequired = "End year is required";
const addForecastSpecificationEndYearGreaterThanMRY =
  "End year should be greater than the most recent year";
const addForecastSpecificationEndYearLessThanFEY =
  "End year should be equal or less than forecast end year";
const addForecastSpecificationEndYearLessThanStartYear =
  "End year should be greater than start year";
const addForecastSpecificationStartYearGreaterThanEndYear =
  "Start year should be less than end year";
const addForecastSpecificationForecastNameRequired =
  "Forecast name is required";

const projInfo = "Project information";
const projType = "Project type";
const specSelection = "Specification selection";

const projName = "Project name";
const addNewUser = "Add new user";
const name = "Name";
const email = "Email ID";
const selectEmission = "Select emission";
const selectOrg = "Select organisational";
const selectGeo = "Select geographical";
const selectCustom = "Select custom";
const scope = "Scope";
const subcat1 = "Subcategory 1";
const subcat2 = "Subcategory 2";
const subcat3 = "Subcategory 3";
const entLevel1 = "Entity level 1";
const entLevel2 = "Entity level 2";
const entLevel3 = "Entity level 3";
const entLevel4 = "Entity level 4";
const regLevel1 = "Regional level 1";
const regLevel2 = "Regional level 2";
const regLevel3 = "Regional level 3";
const custom1 = "Custom 1";
const custom2 = "Custom 2";
const custom3 = "Custom 3";
const projDesc = "Project description";
const projTag = "Project tags";
const selectProjectTag = "Select project tag";
const createNewProjectTag = "Create new project tag";
const SearchNewProjectTags = "Search existing or type new project tags";
const createNewCategory = "Create new category";
const searchCategory = "Search existing or type new category";
const createNewRegion = "Create new region";
const searchNewRegion = "Search existing or type new region";
const createNewEntity = "Create new entity";
const searchNewEntity = "Search existing or type new entity";
const strtYr = "Start year";
const endYr = "End year";
const orgSpec = " Organisational specifications";
const emisSpec = "Emission specifications";
const custSpec = " Custom specifications";
const userDuplicateMsg =
  "The user you are attempting to add already exists within the engagement.";
const setEngDet = "Setup of engagement details";

const impExcel = "Import excel template";
const addForecastSpec =
  "Add forecast specifications as applicable, and click 'Apply'";
const forecastSpec = "Add forecast";
const targetSpec = "Add target specifications";
const impExcelDesc =
  "Please download the blank template file, fill out the required information, and upload the completed file below";

const specHeaderDesc =
  "These specifications are retrieved from Manage and Forecast modules. Select the specification you want to apply for this project.";

// View Engagement module constants
const engDash = "Engagement dashboard";
const dashHeaderDesc =
  "Below are your current engagements, select any engagement names to make your updates.";
const TableHeaderEngagementName = "Engagement name";
const TableHeaderEngagementCode = "Engagement code";
const TableHeaderClientName = "Client name";
const TableHeaderEngagementStatus = "Engagement status";
const CloseProjectHint = "Close project";

//View Engagement / Delete modal module constants
const deleteModalHeader = "Close engagement";
const deleteModalBody =
  "Are you sure you want to close the engagement? This action cannot be undone.";

// Shared constants
const CancelBtn = "Close";
const YesBtn = "Yes";
const YesChangeBtn = "Yes, change";
const YesSkipBtn = "Yes, skip";
const ConfirmBtn = "Confirm";
const PreviousBtn = "Previous";
const NextBtn = "Next";
const SaveAndNextBtn = "Next";
const SaveBtn = "Save";
const ApplyBtn = "Apply";
const PoweBiBtn = "View Power BI results";
const DownloadBtn = "Download";
const DownloadTemplateBtn = "Download template file";
const DownloadErrorReportBtn = "Download error report";
const CreateNewEngBtn = "Create new engagement";
const AddUserBtn = "Add user";
const AddForecastBtn = "Add forecast";
const AddTargetBtn = "Add target";
const ResetM2Data = "Delete data";
const DeleteBtn = "Delete";
const ExportBtn = "Export data";
const CreateNewScenarioBtn = "Create new scenario";
const BackBtn = "Back";
const SubmitBtn = "Submit";

// Shared constants / Validation messages
const FourDigitYear = "Please enter a valid 4 digit year";

//Shared constants / Excel import
const FileVerificationIsInProgress = "File verification is in progress";
const FileUploadIsInProgress = "File upload is in progress";
// const FileUploadIsInSuccess = "File Upload is in Success";
const FileUploadIsInSuccess = "Data load process triggered successfully";
// const DataVerificationIsInProgress = "Data Verification is in Progress";
const DataVerificationIsInProgress = "Data validation is in progress";
// const DataVerificationIsInFailed = "Data Verification is Failed";
const DataVerificationIsInFailed =
  "Data validation has failed. Please refer file to get the data validation errors.";
const DataVerificationIsInSuccess = "Data validation is successful";
const DataLoadIsInProgress = "Data load is in progress";
const DataLoadIsInFailed = "Data load is failed";
const DataLoadHasFailed =
  "Data load has failed. This may have occurred due to some technical error. Please try again and contact support team if issue persist";
const DataLoadIsInSuccess = "Data load is in success";
const GeneratingErrorReport = "Generating error report...";
const ExcelDataVerificationFailed =
  "A total of $1 records have been identified with errors and the uploading has been terminated. Please review the error report and re-upload the file.";
const FailedStatus = "Fail";
const uploadSuccessMessage1 = "The data upload was successful. A total of ";
const uploadSuccessMessage2 = " records have been uploaded.";
const uploadSuccessMessage3 =
  "No record has been uploaded. Please validate the input file and re-upload.";
const baseYear = "Base year";
const targetYear = "Target year";
const edittype = "Edit type";
const teamType = "Team";
const removeForecast = "Remove forecast specification?";
const removeTarget = "Remove target specification?";
const addForecastModalHeading = "Select how you want to add your forecast.";
const viaExcel = "Via excel import";
const viaUI = "Via UI input";
const deleteMessage = "Are you sure you want to delete this record?";
const deleteMessageLine2 =
  " Once confirmed, the record will disappear from the screen.";
const deleteMessageLine3 =
  "If you are deleting an earlier 'applied' forecast, changes will only be applied to the data after you click 'Apply'.";
const applyErrorMessageTarget =
  " System is busy in executing earlier targets, please try after sometime!";
const applyErrorMessageForecast =
  " System is busy in executing earlier forecasts, please try after sometime!";
const forecastPercentageError =
  "Please enter a valid 2 digit number (up to 2 decimals allowed).";
const targetPercentageError =
  "The value cannot exceed 100 and is limited to 2 decimal places";

//Emission page constants

const emissionHeaderTitle = "Emissions selection";
const reset = "Reset";
const filters = "Filters";
const editData = "Edit data";
const disableEditButton = "Disable edit";
const batchEditreduce = "Batch edit (Reduce)";
const batchEditCompensate = "Batch edit (Compensate)";
const batchEditReplace = "Batch edit (Replace)";
const reduceChartNoData = "No data to display";
const cumulative = "Cumulative or constant";
const absoluteorRelative = "Absolute or relative";
const simulateValue = "Value";
const unit = "Unit";
const simulate = "Simulate";
const unitDropdown = ["Absolute", "Relative"];
const cumulativedropdown = ["Cumulative", "Constant"];
const editExtend = ["Edit", "Extend"];
const yesNo = ["Yes", "No"];
const emissionArray = ["Emissions"];
const projectTypes = [
  { id: 1, name: "Reduce" },
  { id: 2, name: "Replace" },
  { id: 3, name: "Compensate" },
];
const dropdowns = [
  {
    Name: "Emission",
    dropdownList: [
      { label: "Scope", fieldId: "scope", id: 1 },
      { label: "Category 1", fieldId: "category_level1", id: 2 },
      { label: "Category 2", fieldId: "category_level2", id: 3 },
      { label: "Category 3", fieldId: "category_level3", id: 4 },
    ],
    tooltip:
      "Specify emissions categories to tailor the general modelling parameter assumptions from an emissions perspective",
  },
  {
    Name: "Geographical",
    dropdownList: [
      { label: "Region level 1", fieldId: "region_level1", id: 5 },
      { label: "Region level 2", fieldId: "region_level2", id: 6 },
      { label: "Region level 3", fieldId: "region_level3", id: 7 },
    ],
    tooltip:
      "Specify geographical categories to tailor the general modelling parameter assumptions from a regional perspective",
  },
  {
    Name: "Organisational",
    dropdownList: [
      { label: "Entity level 1", fieldId: "entity_level1", id: 8 },
      { label: "Entity level 2", fieldId: "entity_level2", id: 9 },
      { label: "Entity level 3", fieldId: "entity_level3", id: 10 },
      { label: "Entity level 4", fieldId: "entity_level4", id: 11 },
    ],
    tooltip:
      "Specify organisational categories to tailor the general modelling parameter assumptions from a company perspective",
  },
  {
    Name: "Custom",
    dropdownList: [
      { label: "Custom level 1", fieldId: "custom_specification1", id: 12 },
      { label: "Custom level 2", fieldId: "custom_specification2", id: 13 },
      { label: "Custom level 3", fieldId: "custom_specification3", id: 14 },
    ],
    tooltip:
      "Specify custom categories to tailor the general modelling parameter assumptions to customized areas",
  },
];
const unitTooltip =
  "An 'Absolute' change refers to the difference in the actual value, while a 'Relative' change expresses this difference as a percentage of the original value";
const emissionFilterTooltip =
  "Filters applied will update the treemap chart and data table.";
const simulateValueTooltip =
  "Value is dependent on data type and unit. Please select data type and unit first.";
const emissionHeaderTooltip =
  "Select your emissions and click on 'Apply' to enable further drill down on your geographical, organisational and custom filters.";
const emissionGraphHeading =
  "Average annual emissions over forecast period (kgCO\u{2082}e)";
const changeLog = "Change log";
const emissionsChangeLog = "Emission change log";
const calculate = "Calculate";
const changeLogDescription =
  "Record of all the rules applied to the project in chronological order. Users have the option to delete the most recently applied rule by clicking the 'Delete latest rule' button.";
const changeLogTableColDefs = [
  {
    field: "rule_name",
    width: 150,
    headerName: "Rule name",
    headerTooltip: "Rule name",
    suppressMovable: true,
    pinned: "left",
    wrapText: true,
    autoHeight: true,
    wrapHeaderText: true,
    autoHeaderHeight: true,
    cellStyle: {
      "text-overflow": "ellipsis",
      "white-space": "nowrap",
      overflow: "hidden",
      padding: "0.5rem",
    },
    tooltipValueGetter: (p) => p.value,
  },
  {
    field: "ruledescription",
    width: 150,
    headerName: "Rule description",
    headerTooltip: "Rule description",
    suppressMovable: true,
    pinned: "left",
    wrapText: true,
    autoHeight: true,
    wrapHeaderText: true,
    autoHeaderHeight: true,
    tooltipValueGetter: (p) => p.value,
    cellStyle: {
      "text-overflow": "clip",
      "white-space": "nowrap",
      overflow: "hidden",
      padding: "0.5rem",
      "text-align": "center",
    },
  },
  {
    field: "source",
    width: 150,
    headerName: "Source",
    headerTooltip: "Source",
    suppressMovable: true,
    pinned: "left",
    wrapText: true,
    autoHeight: true,
    wrapHeaderText: true,
    autoHeaderHeight: true,
    cellStyle: {
      "text-overflow": "ellipsis",
      "white-space": "nowrap",
      overflow: "hidden",
      padding: "0.5rem",
    },
    tooltipValueGetter: (p) => p.value,
  },
  {
    field: "editType",
    width: 150,
    headerName: "Edit type",
    headerTooltip: "Edit type",
    suppressMovable: true,
    rowDrag: true,
    rowDragManaged: true,
    pinned: "left",
  },
  {
    field: "data_type",
    width: 150,
    headerName: "Data type",
    headerTooltip: "Data type",
    suppressMovable: true,
    rowDrag: true,
  },

  {
    field: "calculation_type",
    width: 200,
    headerName: "Absolute or relative",
    headerTooltip: "Absolute or relative",
    suppressMovable: true,
  },
  {
    field: "cumulative_flag",
    width: 150,
    headerName: "Cumulative or constant",
    headerTooltip: "Cumulative or constant",
    suppressMovable: true,
  },
  {
    field: "abatement_value",
    width: 150,
    headerName: "Value",
    headerTooltip: "Value",
    suppressMovable: true,
  },
  {
    field: "start_year",
    width: 150,
    headerName: "Start year",
    headerTooltip: "Start year",
    suppressMovable: true,
  },
  {
    field: "end_year",
    width: 150,
    headerName: "End year",
    headerTooltip: "End year",
    suppressMovable: true,
  },
  {
    field: "scope",
    width: 150,
    headerName: "Scope",
    headerTooltip: "Scope",
    suppressMovable: true,
    cellStyle: {
      "text-overflow": "ellipsis",
      "white-space": "nowrap",
      overflow: "hidden",
      padding: "0.5rem",
    },
    tooltipValueGetter: (p) => p.value,
  },
  {
    field: "category_level1",
    width: 150,
    headerName: "Category 1",
    headerTooltip: "Category 1",
    suppressMovable: true,
    cellStyle: {
      "text-overflow": "ellipsis",
      "white-space": "nowrap",
      overflow: "hidden",
      padding: "0.5rem",
    },
    tooltipValueGetter: (p) => p.value,
  },
  {
    field: "category_level2",
    width: 150,
    headerName: "Category 2",
    headerTooltip: "Category 2",
    suppressMovable: true,
    cellStyle: {
      "text-overflow": "ellipsis",
      "white-space": "nowrap",
      overflow: "hidden",
      padding: "0.5rem",
    },
    tooltipValueGetter: (p) => p.value,
  },
  {
    field: "category_level3",
    width: 150,
    headerName: "Category 3",
    headerTooltip: "Category 3",
    suppressMovable: true,
    cellStyle: {
      "text-overflow": "ellipsis",
      "white-space": "nowrap",
      overflow: "hidden",
      padding: "0.5rem",
    },
    tooltipValueGetter: (p) => p.value,
  },
  {
    field: "region_level1",
    width: 150,
    headerName: "Regional 1",
    headerTooltip: "Regional 1",
    suppressMovable: true,
    cellStyle: {
      "text-overflow": "ellipsis",
      "white-space": "nowrap",
      overflow: "hidden",
      padding: "0.5rem",
    },
    tooltipValueGetter: (p) => p.value,
  },
  {
    field: "region_level2",
    width: 150,
    headerName: "Regional 2",
    headerTooltip: "Regional 2",
    suppressMovable: true,
    cellStyle: {
      "text-overflow": "ellipsis",
      "white-space": "nowrap",
      overflow: "hidden",
      padding: "0.5rem",
    },
    tooltipValueGetter: (p) => p.value,
  },
  {
    field: "region_level3",
    width: 150,
    headerName: "Regional 3",
    headerTooltip: "Regional 3",
    suppressMovable: true,
    cellStyle: {
      "text-overflow": "ellipsis",
      "white-space": "nowrap",
      overflow: "hidden",
      padding: "0.5rem",
    },
    tooltipValueGetter: (p) => p.value,
  },
  {
    field: "entity_level1",
    width: 150,
    headerName: "Entity 1",
    headerTooltip: "Entity 1",
    suppressMovable: true,
    cellStyle: {
      "text-overflow": "ellipsis",
      "white-space": "nowrap",
      overflow: "hidden",
      padding: "0.5rem",
    },
    tooltipValueGetter: (p) => p.value,
  },
  {
    field: "entity_level2",
    width: 150,
    headerName: "Entity 2",
    headerTooltip: "Entity 2",
    suppressMovable: true,
    cellStyle: {
      "text-overflow": "ellipsis",
      "white-space": "nowrap",
      overflow: "hidden",
      padding: "0.5rem",
    },
    tooltipValueGetter: (p) => p.value,
  },
  {
    field: "entity_level3",
    width: 150,
    headerName: "Entity 3",
    headerTooltip: "Entity 3",
    suppressMovable: true,
    cellStyle: {
      "text-overflow": "ellipsis",
      "white-space": "nowrap",
      overflow: "hidden",
      padding: "0.5rem",
    },
    tooltipValueGetter: (p) => p.value,
  },
  {
    field: "entity_level4",
    width: 150,
    headerName: "Entity 4",
    headerTooltip: "Entity 4",
    suppressMovable: true,
    cellStyle: {
      "text-overflow": "ellipsis",
      "white-space": "nowrap",
      overflow: "hidden",
      padding: "0.5rem",
    },
    tooltipValueGetter: (p) => p.value,
  },
  {
    field: "custom_specification1",
    width: 150,
    headerName: "Custom 1",
    headerTooltip: "Custom 1",
    suppressMovable: true,
    cellStyle: {
      "text-overflow": "ellipsis",
      "white-space": "nowrap",
      overflow: "hidden",
      padding: "0.5rem",
    },
    tooltipValueGetter: (p) => p.value,
  },
  {
    field: "custom_specification2",
    width: 150,
    headerName: "Custom 2",
    headerTooltip: "Custom 2",
    suppressMovable: true,
    cellStyle: {
      "text-overflow": "ellipsis",
      "white-space": "nowrap",
      overflow: "hidden",
      padding: "0.5rem",
    },
    tooltipValueGetter: (p) => p.value,
  },
  {
    field: "custom_specification3",
    width: 150,
    headerName: "Custom 3",
    headerTooltip: "Custom 3",
    suppressMovable: true,
    cellStyle: {
      "text-overflow": "ellipsis",
      "white-space": "nowrap",
      overflow: "hidden",
      padding: "0.5rem",
    },
    tooltipValueGetter: (p) => p.value,
  },
];
const changeLogReplaceTableColDefs = [
  {
    field: "rule_name",
    width: 150,
    headerName: "Rule name",
    headerTooltip: "Rule name",
    suppressMovable: true,
    pinned: "left",
    rowDrag: true,
    rowDragManaged: true,
    resizable: true,
    wrapText: true,
    autoHeight: true,
    wrapHeaderText: true,
    autoHeaderHeight: true,
    cellStyle: {
      "text-overflow": "ellipsis",
      "white-space": "nowrap",
      overflow: "hidden",
      padding: "0.5rem",
    },
    tooltipValueGetter: (p) => p.value,
  },
  {
    field: "ruledescription",
    width: 150,
    headerName: "Rule description",
    headerTooltip: "Rule description",
    suppressMovable: true,
    pinned: "left",
    wrapText: true,
    autoHeight: true,
    wrapHeaderText: true,
    autoHeaderHeight: true,
    cellStyle: {
      "text-overflow": "ellipsis",
      "white-space": "nowrap",
      overflow: "hidden",
      padding: "0.5rem",
    },
    tooltipValueGetter: (p) => p.value,
  },
  {
    field: "source",
    width: 150,
    headerName: "Source",
    headerTooltip: "Source",
    suppressMovable: true,
    pinned: "left",
    wrapText: true,
    autoHeight: true,
    wrapHeaderText: true,
    autoHeaderHeight: true,
    cellStyle: {
      "text-overflow": "ellipsis",
      "white-space": "nowrap",
      overflow: "hidden",
      padding: "0.5rem",
    },
    tooltipValueGetter: (p) => p.value,
  },
  {
    field: "changeType",
    width: 150,
    headerName: "Change type",
    headerTooltip: "Change type",
    suppressMovable: true,
    rowDrag: true,
    rowDragManaged: true,
    pinned: "left",
  },
  {
    field: "editType",
    width: 150,
    headerName: "Edit type",
    headerTooltip: "Edit type",
    suppressMovable: true,
    rowDrag: true,
    rowDragManaged: true,
    pinned: "left",
  },

  {
    field: "data_type",
    width: 150,
    headerName: "Data type",
    headerTooltip: "Data type",
    suppressMovable: true,
    rowDrag: true,
  },
  {
    field: "targetHierarchy",
    width: 150,
    headerName: "Hierarchy",
    headerTooltip: "Hierarchy",
    suppressMovable: true,
  },
  {
    field: "target_Name",
    width: 150,
    headerName: "New target hierarchy",
    headerTooltip: "New target hierarchy",
    suppressMovable: true,
    cellStyle: {
      "text-overflow": "ellipsis",
      "white-space": "nowrap",
      overflow: "hidden",
      padding: "0.5rem",
    },
    tooltipValueGetter: (p) => p.value,
  },
  {
    field: "calculation_type",
    width: 200,
    headerName: "Absolute or relative",
    headerTooltip: "Absolute or relative",
    suppressMovable: true,
  },
  {
    field: "cumulative_flag",
    width: 150,
    headerName: "Cumulative or constant",
    headerTooltip: "Cumulative or constant",
    suppressMovable: true,
  },
  {
    field: "start_year",
    width: 150,
    headerName: "Start year",
    headerTooltip: "Start year",
    suppressMovable: true,
  },
  {
    field: "end_year",
    width: 150,
    headerName: "End year",
    headerTooltip: "End year",
    suppressMovable: true,
  },
  {
    field: "abatement_value",
    width: 150,
    headerName: "Value",
    headerTooltip: "Value",
    suppressMovable: true,
  },
  {
    field: "activitydata_value",
    width: 150,
    headerName: "AD value",
    headerTooltip: "AD value",
    suppressMovable: true,
  },
  {
    field: "activitydata_Unit",
    width: 150,
    headerName: "AD unit",
    headerTooltip: "AD unit",
    suppressMovable: true,
  },

  {
    field: "emissiondata_value",
    width: 150,
    headerName: "EF value",
    headerTooltip: "EF value",
    suppressMovable: true,
  },
  {
    field: "emissiondata_unit",
    width: 150,
    headerName: "EF unit",
    headerTooltip: "EF unit",
    suppressMovable: true,
  },
  {
    field: "scope",
    width: 150,
    headerName: "Scope",
    headerTooltip: "Scope",
    suppressMovable: true,
    cellStyle: {
      "text-overflow": "ellipsis",
      "white-space": "nowrap",
      overflow: "hidden",
      padding: "0.5rem",
    },
    tooltipValueGetter: (p) => p.value,
  },
  {
    field: "category_level1",
    width: 150,
    headerName: "Category 1",
    headerTooltip: "Category 1",
    suppressMovable: true,
    cellStyle: {
      "text-overflow": "ellipsis",
      "white-space": "nowrap",
      overflow: "hidden",
      padding: "0.5rem",
    },
    tooltipValueGetter: (p) => p.value,
  },
  {
    field: "category_level2",
    width: 150,
    headerName: "Category 2",
    headerTooltip: "Category 2",
    suppressMovable: true,
    cellStyle: {
      "text-overflow": "ellipsis",
      "white-space": "nowrap",
      overflow: "hidden",
      padding: "0.5rem",
    },
    tooltipValueGetter: (p) => p.value,
  },
  {
    field: "category_level3",
    width: 150,
    headerName: "Category 3",
    headerTooltip: "Category 3",
    suppressMovable: true,
    cellStyle: {
      "text-overflow": "ellipsis",
      "white-space": "nowrap",
      overflow: "hidden",
      padding: "0.5rem",
    },
    tooltipValueGetter: (p) => p.value,
  },
  {
    field: "region_level1",
    width: 150,
    headerName: "Regional 1",
    headerTooltip: "Regional 1",
    suppressMovable: true,
    cellStyle: {
      "text-overflow": "ellipsis",
      "white-space": "nowrap",
      overflow: "hidden",
      padding: "0.5rem",
    },
    tooltipValueGetter: (p) => p.value,
  },
  {
    field: "region_level2",
    width: 150,
    headerName: "Regional 2",
    headerTooltip: "Regional 2",
    suppressMovable: true,
    cellStyle: {
      "text-overflow": "ellipsis",
      "white-space": "nowrap",
      overflow: "hidden",
      padding: "0.5rem",
    },
    tooltipValueGetter: (p) => p.value,
  },
  {
    field: "region_level3",
    width: 150,
    headerName: "Regional 3",
    headerTooltip: "Regional 3",
    suppressMovable: true,
    cellStyle: {
      "text-overflow": "ellipsis",
      "white-space": "nowrap",
      overflow: "hidden",
      padding: "0.5rem",
    },
    tooltipValueGetter: (p) => p.value,
  },
  {
    field: "entity_level1",
    width: 150,
    headerName: "Entity 1",
    headerTooltip: "Entity 1",
    suppressMovable: true,
    cellStyle: {
      "text-overflow": "ellipsis",
      "white-space": "nowrap",
      overflow: "hidden",
      padding: "0.5rem",
    },
    tooltipValueGetter: (p) => p.value,
  },
  {
    field: "entity_level2",
    width: 150,
    headerName: "Entity 2",
    headerTooltip: "Entity 2",
    suppressMovable: true,
    cellStyle: {
      "text-overflow": "ellipsis",
      "white-space": "nowrap",
      overflow: "hidden",
      padding: "0.5rem",
    },
    tooltipValueGetter: (p) => p.value,
  },
  {
    field: "entity_level3",
    width: 150,
    headerName: "Entity 3",
    headerTooltip: "Entity 3",
    suppressMovable: true,
    cellStyle: {
      "text-overflow": "ellipsis",
      "white-space": "nowrap",
      overflow: "hidden",
      padding: "0.5rem",
    },
    tooltipValueGetter: (p) => p.value,
  },
  {
    field: "entity_level4",
    width: 150,
    headerName: "Entity 4",
    headerTooltip: "Entity 4",
    suppressMovable: true,
    cellStyle: {
      "text-overflow": "ellipsis",
      "white-space": "nowrap",
      overflow: "hidden",
      padding: "0.5rem",
    },
    tooltipValueGetter: (p) => p.value,
  },
  {
    field: "custom_specification1",
    width: 150,
    headerName: "Custom 1",
    headerTooltip: "Custom 1",
    suppressMovable: true,
  },
  {
    field: "custom_specification2",
    width: 150,
    headerName: "Custom 2",
    headerTooltip: "Custom 2",
    suppressMovable: true,
  },
  {
    field: "custom_specification3",
    width: 150,
    headerName: "Custom 3",
    headerTooltip: "Custom 3",
    suppressMovable: true,
  },
];
const deleteLastRule = "Delete last rule";
const ruleName = "Rule name";
const ruleDescription = "Rule description";
const endYearGreaterThanFEYear =
  "End year should not be greater than forecast end year";
const endYearLessThanStartYear = "End year should not be less than start year";

// Analyze page constants
// Analyze / Project Results module constants
const projectResultsHeader = "Project results";
const projectResultsEmissionsAbatementChartHeader = "Emissions abatement";
const projectResultsCashFlowChartHeader = "Cash flow";
const projectResultsBusinessCaseSummaryHeader = "Business case summary";
const projectResultsAbatementCashFlowCalcHeader =
  "Abatement and cash flow calculations";
const projectResultsEmissionAbatementChartFBP = "Forecast (before project)";
const projectResultsEmissionAbatementChartAE = "Abated emissions";
const projectResultsEmissionAbatementChartFAP = "Forecast (after project)";
const projectResultsEmissionAbatementChartRE = "Residual emissions";
const projectResultsEmissionAbatementChartYears = "Years";
const datainSyncMessage =
  "Data synchronization is in process. It may take a while...";

// Session timeout constants
const sessionTimeoutTitle = "Your session is about to expire";
const sessionTimeoutMessage =
  "You're being timed out due to inactivity. Please choose to stay signed in or signed out. Otherwise, you will be signed out automatically in $1 minute(s).";
const sessionTimeoutStayLoggedIn = "Stay signed in";
const sessionTimeoutLogOut = "Sign out now";

// Users consent constants

const consentHeading = "Disclaimer";
const consentBodyText =
  "EY does not intentionally collect any sensitive personal data from you via the tool. The tool’s intention is not to process such information. Accordingly, you should not enter any unnecessary personal information or any sensitive personal data (including government identifiers, such as tax file, social security or national identification numbers), client confidential information (except client entity names), audit secrets, state secrets, trade secrets, or anything that would violate professional secrecy or confidentiality rules or that would be considered abusive or irrelevant in the tool. ";
const consentPrivacyText = "Privacy notice";
const consentIAgree = "I agree";
const consentNotice = " Please review our ";
const consentNoticePrivacy = " and click “I agree” to access the application. ";

// Simulate module constants
const simulateHeaderTitle = "Simulate scenarios and set roadmap";
const simulateHeaderDescription =
  "Simulate different emissions reduction scenarios and set a decarbonization roadmap";
const simulateCloseProjectScenarioHint = "Close project scenario";
const decimalsNotAllowedError = "Please enter numbers only";
const relativePercentageError =
  "Value should be in the range of -100% to 9999.99% for this combination";
const activityDataRelativeError =
  "Value should be in the range of 1 to 100 for this combination";
const CAGRPercentageError = "Value should be in the range of -100% to 9999.99%";
const only2DecimalsAllowed = "Up to 2 decimals only allowed.";
const negativeNotallowed =
  "Negative values are not allowed for this combination";
const maxlimitExceeded = "Max limit exceeded";
const onlyNumbersAllowed = "Only numbers are allowed";
const saveSimulateHeaderTitle = "Save scenario details";
const ScenarioName = "Scenario name";
const sceanrioNameTooltip =
  "The identifier assigned to the scenario. This name helps distinguish between different scenarios within the application";
const ScenarioDescription = "Scenario description";
const scenarioDesctooltip =
  "A detailed explanation or summary of the scenario. This description provides context, assumptions, and key details about the scenario";
const scenarioValidationMessage =
  "There have been modifications in previous modules, please click on “Sync data” before you proceed.";
const correctM3inM4Message =
  "Data has been modified due to M1 file reupload. Please update M3 data to proceed further.";
// Projects Library module constants
const projectsLibraryHeader = "Decarbonization library";
const scopes = [
  { id: "Scope 1", name: "Scope 1" },
  { id: "Scope 2", name: "Scope 2" },
  { id: "Scope 3.1", name: "Scope 3.1" },
  { id: "Scope 3.2", name: "Scope 3.2" },
  { id: "Scope 3.3", name: "Scope 3.3" },
  { id: "Scope 3.4", name: "Scope 3.4" },
  { id: "Scope 3.6", name: "Scope 3.6" },
  { id: "Scope 3.7", name: "Scope 3.7" },
  { id: "Scope 3.8", name: "Scope 3.8" },
  { id: "Scope 3.9", name: "Scope 3.9" },
  { id: "Scope 3.12", name: "Scope 3.12" },
];
const extendScopes = [
  "Scope 1",
  "Scope 2",
  "Scope 3.1",
  "Scope 3.2",
  "Scope 3.3",
  "Scope 3.4",
  "Scope 3.5",
  "Scope 3.6",
  "Scope 3.7",
  "Scope 3.8",
  "Scope 3.9",
  "Scope 3.10",
  "Scope 3.11",
  "Scope 3.12",
  "Scope 3.13",
  "Scope 3.14",
  "Scope 3.15",
];

// Projects Library / Project Library Item module constants
const projectLibraryItemNetPresent = "Net present value";
const projectLibraryItemRateOfReturn = "Internal rate of return";
const projectLibraryItemTechAbatement = "Technical abatement potential";
const projectLibraryItemMargCost = "Marginal abatement cost";

// Projects Library / Project Details module constants
const projectDetailsType = "Project type:";
const projectDetailsTags = "Project tags:";
const projectDetailsBusinessRules = "Rules";
const projectDetailsBusinessRulesRuleName = "Rule name";
const projectDetailsBusinessRuleDescription = "Rule description";
const projectDetailsBusinessRuleSource = "Source";
const projectDetailsBusinessRulesChangeType = "Change type";
const projectDetailsBusinessEditType = "Edit type";
const projectDetailsBusinessRulesDataType = "Data type";
const projectDetailsBusinessRulesHierarchy = "Target hierarchy";
const projectDetailsBusinessRulesHierarchyNew = "New target hierarchy";
const projectDetailsBusinessRulesName = "Name";
const projectDetailsBusinessRulesUnitOp = "Absolute or relative";
const projectDetailsBusinessRulesCumulative = "Cumulative or constant";
const projectDetailsBusinessRulesUnit = "Unit";
const projectDetailsBusinessRulesValue = "Value";
const projectDetailsBusinessRulesTransferAD = "Transfer AD";
const projectDetailsBusinessRulesADValue = "AD value";
const projectDetailsBusinessRulesADUnit = "AD unit";
const projectDetailsBusinessRulesEfValue = "EF value";
const projectDetailsBusinessRulesStartYear = "Start year";
const projectDetailsBusinessRulesEndYear = "End year";
const projectDetailsBusinessRulesScope = "Scope";
const projectDetailsBusinessRulesSubcat1 = "Category 1";
const projectDetailsBusinessRulesSubcat2 = "Category 2";
const projectDetailsBusinessRulesSubcat3 = "Category 3";
const projectDetailsBusinessRulesReg1 = "Regional 1";
const projectDetailsBusinessRulesReg2 = "Regional 2";
const projectDetailsBusinessRulesReg3 = "Regional 3";
const projectDetailsBusinessRulesEnt1 = "Entity 1";
const projectDetailsBusinessRulesEnt2 = "Entity 2";
const projectDetailsBusinessRulesEnt3 = "Entity 3";
const projectDetailsBusinessRulesEnt4 = "Entity 4";
const projectDetailsBusinessRulesCust1 = "Custom 1";
const projectDetailsBusinessRulesCust2 = "Custom 2";
const projectDetailsBusinessRulesCust3 = "Custom 3";
const projectDetailsAbatementCashFlowCalcHeader = "Abatement and cash flow";
const projectDetailsFinancialsHeader = "Financials";
const projectDetailsItemNetPresent = "Net present value ($)";
const projectDetailsItemRateOfReturn = "Internal rate of return (% p.a)";
const projectDetailsItemTechAbatement = "Technical abatement potential (%)";
const projectDetailsItemMargCost = "Marginal abatement cost (tCO\u{2082}e)";
const projectDetailsItemDPP = "Discounted payback period (yrs)";
const projectDetailsItemSImplePayback = "Simple payback period (yrs)";
const projectDetailsCategoryHeader = "Data type";
const projectDetailsUnitHeader = "Unit";

const financialVariableType = "Variable type";
const financialRulesType = "Rules";
const financialParameterType = "Parameter selection";
const financialSetVariable = "Set variables";
const targetHierarchy = "Target hierarchy";
const actDataValue = "Activity data value";
const emisFactValue = "Emission factor value";
const actDataUnit = "Activity data unit";
const emisFactorUnit = "Emission factor unit";
const transferAd = "Transfer AD";
const targetHierarchyValues = ["Emission", "Geography", "Organisational"];
const transferOrCopy = "Transfer or copy";
const resetFiltersHeader = "Reset emission selection?";
const resetFiltersBody =
  "The dataset will undergo a reset, resulting in the removal of all configured rules, as well as capital expenditure (CapEx) and operational expenditure (OpEx) information. Please select confirm to proceed with this action, otherwise select cancel.";
const analyseValidationMessage =
  "There have been modifications in module 2, please click on “Sync data” before you proceed.";
const analyseDeleteMessage =
  "The data in module 2 has been deleted, please correct it before you proceed.";
const copyProjectsMessage =
  "The projects are being copied from the previous version, which may take some time. Please revisit the page later to view the projects.";

// Tooltips
const m2FileTemplateTooltip =
  "A standardized template used for entering 'Activity data' and 'Emission factor' forecast data into the required format for uploading";
const forecastDataTypeTooltip =
  "The classification of the data being modelled. The available options are 'Activity data' or 'Emission factor'";
const forecastNameTooltip =
  "The identifier assigned to the forecast. This name helps distinguish between different forecasts within the application";
const versionHistoryTooltip =
  "If multiple files were uploaded in Module 1, you can go back to a previous file. The solution will revert to the version associated with that file. Reverting to a previous version will delete any Excel files uploaded in Module 2 and require reconnection/synchronization of UI-based changes in all subsequent modules";
const teamTooltip =
  "The team has access to the engagement. Internal team members, who are EY users, have full access, while external team members, who are client users, only have access to the Power BI dashboards. External team members must be registered on MyEY";
const sectorTooltip =
  "Client segment of the economy categorized by industry or activity. Multiple selections are possible";
const templatefileM1tooltip =
  "A standardized template used for entering greenhouse gas inventory data into the required format for uploading ";
const mostRecentYeartooltip =
  "The most recent year for which a greenhouse gas inventory has been completed";
const forecastendYearTooltip =
  "The final year for which projections are made, setting the limit for this and all subsequent modules";
const addforecastTooltip = "Set a specific emissions reduction forecast";
const addtargetTooltip = "Set a specific emissions reduction target";
const forecastDescriptionTooltip =
  "A detailed explanation or summary of the forecast. This description provides context, assumptions, and key details about the forecast";
const forecastPercentageTooltip =
  "The annual percentage rate at which the specified data changes throughout the forecast period (a positive value indicates an increase)";
const forecastValueTooltip = "Expected yearly change rate of the emissions";
const forecastStartYearTooltip =
  "The first year from which the forecast begins and the value is applied. It must be after the 'Most recent year' and before the 'Forecast end year'";
const forecastEndYeartooltip =
  "The final year to which the forecast and value is applied. It must be after the 'Start year' and before or equal to the 'Forecast end year'";
const emissionTooltip =
  "Emission data taxonomy to help organize and classify data according to a structured emission hierarchy. The forecast will be restricted to the selected data";
const forecastGeographyTooltip =
  "Geographical data taxonomy to help organize and classify data according to a structured geographical hierarchy. The forecast will be restricted to the selected data";
const forecastOrgfTooltip =
  "Organizational data taxonomy to help organize and classify data according to a structured organizational hierarchy. The forecast will be restricted to the selected data";
const forecastCustomTooltip =
  "Custom data taxonomy to help organize and classify data according to a structured custom hierarchy. The forecast will be restricted to the selected data";
const targetNameTooltip =
  "The identifier assigned to the target. This name helps distinguish between different targets within the application";
const targetDescriptiontooltip =
  "A detailed explanation or summary of the target. This description provides context, assumptions, and key details about the target";
const targetTypetooltip =
  "The classification of the target. The available options are 'Absolute' or 'Intensity'. Absolute targets aim for a total reduction in emissions, while intensity targets aim for a reduction relative to a unit of activity";
const temporalTypeTooltip =
  "The classification of the timeframe for the target calculation: 'Annual' refers to the expected yearly reduction of the specified value parameter, while 'Target End Year' indicates the total reduction anticipated by the target year";
const targetPercentageTooltip =
  "If 'Annual' is selected, the value parameter is the annual percentage rate at which the specified emissions should reduce over time. If 'Target end year' is selected, the value parameter is the total percentage reduction expected for the specified emissions in the target end year";
const targetBaseYearTooltip =
  "The initial year from which progress towards the target is measured. This year serves as the baseline for evaluating changes in emissions over time. It must be after or equal to the 'Most recent year + 9' and before or equal to the 'Most recent year'";
const targetYearTooltip =
  "The final year by which the target is expected to be achieved. It must be after the 'Base year' and before or equal to the 'Forecast end year'";
const targetEmissionTooltip =
  "Emission data taxonomy to help organize and classify data according to a structured emission hierarchy. The target will be restricted to the selected data";
const targetGeographytooltip =
  "Geographical data taxonomy to help organize and classify data according to a structured geographical hierarchy. The target will be restricted to the selected data";
const targetCustomTooltip =
  "Custom data taxonomy to help organize and classify data according to a structured custom hierarchy. The target will be restricted to the selected data";
const targetOrganisationTooltip =
  "Organizational data taxonomy to help organize and classify data according to a structured organizational hierarchy. The target will be restricted to the selected data";
const intesityTooltip =
  "Intensity targets are automatically calculated using the selected activity data. This option enables the calculation of emissions intensity based on manually and independently defined activity data";
const projNameTooltip =
  "The identifier assigned to the project. This name helps distinguish between different projects within the application";
const projTypeTooltip =
  "The type of project is categorized according to the mitigation hierarchy. 'Reduce' is used when the project involves decreasing activity data or emission factors. 'Replace' is used when the project entails substituting existing activity data with alternative data. 'Compensate' is used to offset any remaining emissions that cannot be fully reduced or replaced";
const projDescTooltip =
  "A detailed explanation or summary of the project. This description provides context, assumptions, and key details about the project";
const projTagsTooltip =
  "Tags are labels used to classify and categorize projects, making it easier to filter, organize, and analyze them";
const ruleNameTooltip =
  "The identifier assigned to the modelling operation. This name helps distinguish between different rules within the project";
const ruleDescTooltip =
  "A detailed explanation or summary of the rule. This description provides context, assumptions, and key details about the rule";
const sourceRuleTooltip = "The origin of the information used for the rule";
const editTypeTooltip =
  "The method by which the data is modified. 'Batch edit' is used when making bulk changes to the data utilizing formulas. 'Manual edit' is used when individually and directly modifying data";
const changeTypeTooltip =
  "The method by which the data is modified, with options to either 'Edit' (alter existing data) or 'Extend' (add new data)";
const reduceDataTypetooltip =
  "Category of data to modify within the project. For this particular project type, modifications can be made to either 'Activity Data' or 'Emission Factors'. ";
const replaceDataTypeTooltip =
  "Specifies the category of data to modify within the project. For this particular project type, modifications can be made to either 'Activity Data' or 'Emission Factors'. ";
const compensateDataTypeTooltip =
  "Identifies the specific data category targeted for modifications in the project.Changes are exclusively applied to the calculated 'Emissions,' without affecting the associated 'Emission factors' or 'Activity data.'";
const cumulativeTooltip =
  "A 'Cumulative' change accumulates annually, whereas a 'Constant' change is a single, stable adjustment";
const emissionsValuetooltip =
  "Represents the quantitative input of the company's initiatives.";
const emissionsStartyearTooltip =
  "The first year from which the rule begins and the value is applied. It must be after the 'Most recent year' and before or equal to the 'Forecast end year'";
const emissionsEndYeartooltip =
  "The final year to which the rule and value is applied. It must be after or equal to the 'Start year' and before or equal to the 'Forecast end year'";
const emissionsFilterbuttonTooltip =
  "The filter function allows you to navigate through your defined hierarchies to customize the table view. Use it to apply rules at the correct level of detail by selecting the desired category—Activity Data, Emission Factors, or Emissions—using the selection icons located to the left of each line.";
const emissionsEditButtonTooltip =
  "The 'Edit Data' feature enables manual input of data values. For Activity Data and Emissions, the total amount is distributed down to the most detailed level. For Emission Factors, the entered value replaces the existing figure in all instances.";
const emissionsToggleTooltip =
  "The toggle feature between 'Edit' and 'Extend' modes facilitates the application of different types of rules. 'Edit' rules modify existing data, allowing for adjustments to previously defined emission factors or activity data.'Extend' rules introduce new data points for activity data and correlating emission factors, creating entirely new hierarchies. For optimal results, it is recommended to complete all 'Edit' rules before applying 'Extend' rules within a project.";
const targetHierarchyTooltip =
  "The hierarchy that will be extended. The other hierarchies will be replicated in the new data";
const activityDataValueTooltip =
  "The numerical figure representing the amount or level of a specific activity that generates emissions";
const activityUnitTooltip =
  "The unit of measurement for the activity data value";
const emissionFactorTooltip =
  "The numerical figure representing the amount of emissions produced per unit of activity";
const emissionFactorunitTooltip =
  "The unit of measurement for the emission factor value, expressed as kgCO2e per unit of activity";
const transferADTooltip =
  "The approach to adding new data. 'Transfer' involves removing the old data and replacing it with the new data, while 'Copy' involves adding the new data without altering the existing data";
const calculateTooltip =
  "Financial parameters selected to be applied to this project. Click 'Calculate' to apply the financial parameters";
const addGMPTooltip =
  "GMP, or Financial parameters, includes the financial parameters essential for automatically calculating the financial impact of your projects and scenarios. These parameters, once defined, will be associated with their respective projects during the project financials phase.";
const setHierarchyTooltip =
  "Specify you hierarchies to tailor your general modelling parameters to the respective hierarchies";
const parameterSelectionTooltip =
  "Selection for the financial parameters created in the engagement that you wish to apply to this project. Click 'Create financial parameters' if you'd like to create new parameters in the engagement. Click 'Save' to finalize your selection for this project";
const setparametersTooltip =
  "In the Parameters section, you can define the financial considerations for your projects, tailored to the specific hierarchies established in step 1. Here, you can detail both the qualitative nature and the quantitative representation of each parameter with respective implications on the calculatons. By grouping multiple entries under a single Parameter name, you can create detailed and granular financial assumptions. An example of this would be setting variable carbon prices across different regions.";
const addGMPDatatypeTooltip =
  "The classification of the financial parameter. 'CapEx' denotes capital expenditure for acquiring or upgrading assets. 'OpEx' denotes operational expenditure for ongoing operational costs. 'Discount rate' is the interest rate used to calculate the present value of future cash flows";
const addGMPParamterTooltip =
  "The identifier assigned to the financial parameter. This name helps distinguish between different financial parameters within the application ";
const forceAsCostTooltip =
  "If 'Yes' all financial calculations related to this parameter are considered costs, regardless of any changes in the dependent data (whether an increase or decrease) or the sign of the value (positive or negative). If 'No' the standard calculation logic will be applied";
const vartypeTooltip =
  "An 'Independent' parameter is not influenced by other variables. A 'dependent' parameter will depend on the change in activity data or emissions, which is determined by the 'Data type'";
const sourceYearTooltip =
  "The specific year from which the information is derived";
const sourceTooltip =
  "The origin of the information used for the financial parameter";
const dependency =
  "The classification of the data that the dependent financial parameter is being modelled on. The available options are 'Activity data' and 'Emissions'";
const allocation =
  "The 'Allocation' setting is crucial for the calculation as it dictates whether the financial implications are influenced by a project's impact on the selected data type or if they remain entirely independent.";
const startyearTooltip =
  "The numerical input represents the initial value of the parameter for the start year.";
const currencyUnitTooltip =
  "The standard monetary unit used to quantify and express financial values within the application. In the MVP (Minimum Viable Product), it is fixed to the US Dollar ($)";
const addGMPUnitTooltip =
  "The specific activity data (e.g., kWh) or emissions (e.g., kgCO2e) measurement to which the dependent financial parameter is applied";
const addGMPstartYearTooltip =
  "The first year from which the financial parameter begins and the value is applied. It must be after the 'Most recent year' and before or equal to the 'Forecast end year'";
const addGMPEndYearTooltip =
  "The final year to which the financial parameter and value is applied. It must be after or equal to the 'Start year' and before or equal to the 'Forecast end year'";
const addGMPStartValueTooltip =
  "The first year from which the financial parameter begins and the value is applied. It must be after the 'Most recent year' and before or equal to the 'Forecast end year'";
const CAGRTooltip =
  "Compound annual growth rate (CAGR) is the annualized average rate of change applied to the start value. If the CAGR is left unspecified, it is assumed to be zero, indicating that the initial value remains constant over time";
const CapexTooltip =
  "'CapEx' (capital expenditures) are used to classify the financial implications. ";
const decarbLibraryDescription =
  "EY's proprietary catalogue of decarbonization projects. The library is constantly updated leveraging sanitized data from engagements. It contains projects across economic sectors and includes technical abatement and business case information";
const addGmpTitleTooltip =
  "The 'Financial parameters' section displays the list of defined and saved parameters. Here, you can adjust the numerical values annually as needed. During project creation, each unique parameter name can be selected from a dropdown menu to associate it with the project.";
const extUserDashboardM1Description =
  "View (current and past) Scope 1, 2 and 3 GHG emissions";
const extUserDashboardM2Description =
  "View ‘business-as-usual’ emissions against targets";
const extUserDashboardM3Description =
  "View the abatement potential and financial impact of decarbonization options";
const extUserDashboardM4Description = "View decarbonization scenarios";

const projectLibraryItemAbatement = "Average annual abatement";
const editTypeOptions = ["Batch edit", "Manual edit"];
const eFValidationError =
  "Multiple emission factor units and/or values have been detected in the data selection. A single unit and value is required to complete this operation. Please review your selection.";
const aDValidationError =
  "Multiple activity data units have been detected in the data selection. A single unit is required to complete this operation. Please review your selection.";
const transferCopyOptions = ["Transfer", "Copy"];
const defineNewHrchy = "Define new target hierarchy";
const newTrgtHrchy = "New target hierarchy";
const cat1 = "Category 1";
const cat2 = "Category 2";
const cat3 = "Category 3";
const reg1 = "Region level 1";
const reg2 = "Region level 2";
const reg3 = "Region level 3";
const ent1 = "Entity level 1";
const ent2 = "Entity level 2";
const ent3 = "Entity level 3";
const ent4 = "Entity level 4";
const newHierarchyWarningMessage =
  "This hierarchy already exists, please add new one.";
export {
  newHierarchyWarningMessage,
  extendScopes,
  newTrgtHrchy,
  defineNewHrchy,
  transferCopyOptions,
  cat1,
  cat2,
  cat3,
  reg1,
  reg2,
  reg3,
  ent1,
  ent2,
  ent3,
  ent4,
  editTypeOptions,
  edittype,
  cumulativedropdown,
  projNameTooltip,
  projTypeTooltip,
  ruleNameTooltip,
  reduceDataTypetooltip,
  replaceDataTypeTooltip,
  compensateDataTypeTooltip,
  cumulativeTooltip,
  emissionsValuetooltip,
  emissionsStartyearTooltip,
  emissionsEndYeartooltip,
  emissionsFilterbuttonTooltip,
  emissionsEditButtonTooltip,
  emissionsToggleTooltip,
  targetHierarchyTooltip,
  activityDataValueTooltip,
  emissionFactorTooltip,
  transferADTooltip,
  addGMPTooltip,
  setparametersTooltip,
  setHierarchyTooltip,
  addGMPDatatypeTooltip,
  addGMPParamterTooltip,
  forceAsCostTooltip,
  vartypeTooltip,
  sourceYearTooltip,
  sourceTooltip,
  dependency,
  allocation,
  startyearTooltip,
  currencyUnitTooltip,
  addGMPUnitTooltip,
  addGMPstartYearTooltip,
  addGMPEndYearTooltip,
  CAGRTooltip,
  CapexTooltip,
  addGmpTitleTooltip,
  addforecastTooltip,
  addtargetTooltip,
  forecastendYearTooltip,
  mostRecentYeartooltip,
  sectorTooltip,
  targetYearTooltip,
  targetBaseYearTooltip,
  targetDescriptiontooltip,
  targetEmissionTooltip,
  emissionTooltip,
  targetOrganisationTooltip,
  targetGeographytooltip,
  targetPercentageTooltip,
  temporalTypeTooltip,
  templatefileM1tooltip,
  targetTypetooltip,
  forecastDescriptionTooltip,
  forecastEndYeartooltip,
  forecastStartYearTooltip,
  forecastValueTooltip,
  forecastPercentageTooltip,
  removeTarget,
  appName,
  name,
  email,
  selectEmission,
  scope,
  subcat1,
  subcat2,
  subcat3,
  selectOrg,
  entLevel1,
  entLevel2,
  entLevel3,
  entLevel4,
  selectCustom,
  selectGeo,
  regLevel1,
  regLevel2,
  regLevel3,
  custom1,
  custom2,
  custom3,
  impExcelDesc,
  foreEndYear,
  addTarget,
  baseYear,
  targetSpec,
  targetYear,
  addForecast,
  impExcel,
  addForecastSpec,
  dashHeaderDesc,
  forecastSpec,
  engDash,
  setUpTableCOlumns,
  setEngDet,
  custSpec,
  orgSpec,
  geoSpec,
  strtYr,
  endYr,
  paginationOptions,
  projTag,
  selectProjectTag,
  createNewProjectTag,
  SearchNewProjectTags,
  projName,
  projType,
  projDesc,
  projInfo,
  userDuplicateMsg,
  analyseBodyTitle,
  analyseBodyDescription,
  createNewProject,
  analyseProjectsData,
  analyseTableHeaderProjectName,
  analyseTableHeaderProjectType,
  analyseTableHeaderSimplePaybackPeriod,
  analyseTableHeaderTotalAbatement,
  analyseTableHeaderAverageAnnualAbatement,
  analyseTableHeaderTechnicalAbatementPotential,
  analyseTableHeaderNetPresentValue,
  analyseTableHeaderInternalRateOfReturn,
  analyseTableHeaderDiscountedPaybackPeriod,
  analyseTableHeaderMarginalAbatementCost,
  deleteProjectModalHeader,
  deleteProjectModalBody,
  deleteProjectModalBodyDesc,
  deleteRuleModalHeader,
  deleteRuleModalBody,
  deleteRuleModalBodyDesc,
  deleteGMPModalHeader,
  deleteGMPModalBody,
  deleteGMPModalBodyDesc,
  paramNameValidationMsg,
  duplicateProjectNameError,
  newProjectHeaderDescr,
  emissionsHeaderDesc,
  emissionTableColumns,
  financialHeaderDesc,
  addGMPBtn,
  setParameters,
  addGMPDataType,
  addGMPparameterType,
  addGMPParameterName,
  addGMPForceCost,
  addGMPVariableType,
  addGMPSourceYear,
  addGMPSource,
  addGMPDependency,
  addGMPAllocation,
  addGMPStartYearValue,
  addGMPCurrencyUnit,
  addGMPUnit,
  addGMPStartYear,
  addGMPEndyear,
  addGMPCAGR,
  addGMPDataTypeOptions,
  addGMPForceCostOptions,
  addGMPVariableTypeOptions,
  addGMPDependencyOptions,
  addGMPDependencyOptionsNew,
  manageEmissionHeaderMain,
  manageEmisionHeaderDesc,
  manageEmissionBodyTemplateTitle,
  manageEmissionBodyDownloadFile,
  manageEmissionBodyUploadBaseYear,
  manageEmissionBodyUploadRecentYear,
  manageEmissionBodyUploadSaveFile,
  manageEmissionBodyUploadFileHistory,
  fileVersionHistoryModalHeaderDate,
  fileVersionHistoryModalHeaderFileName,
  fileVersionHistoryModalHeaderUploadedBy,
  saveButtonTooltip,
  forecastHeaderDesc,
  forecastHeaderMain,
  forecastBodyCalculateEmissionsSummaryTitle,
  forecastBodyCalculateEmissionsSummaryTargetRequired,
  forecastBodyCalculateEmissionsSummaryForecastRequired,
  forecastBodyCalculateEmissionsSummaryCoveredEmission,
  forecastBodyCalculateEmissionsSummaryEmissionCoverage,
  forecastBodyCalculateEmissionsSummaryChangeOfEmission,
  forecastBodyCalculateEmissionsSummaryEmissionReducationTarget,
  forecastBodyCalculateEmissionsSummaryEmissionReductioonGap,
  forecastBodyCalculateEmissionsSummaryRelativeToBaseYear,
  forecastBodyCalculateEmissionsSummaryRelativeToTargetYear,
  forecastBodyChartTitle,
  forecastChartYAxisLabel,
  forecastSkipModalBody,
  forecastSkipModalHeader,
  addForecastTableHeaderCustom,
  addForecastTableHeaderDataType,
  addForecastTableHeaderDataEndYear,
  addForecastTableHeaderDataPercentageValue,
  addForecastTableHeaderDataStartYear,
  addForecastTableHeaderEmission,
  addForecastTableHeaderForecastDesc,
  addForecastTableHeaderForecastName,
  addForecastTableHeaderGeo,
  addForecastTableHeaderOrg,
  foreStartYear,
  manageEmissionBodyUploadTitle,
  homeHeader,
  homeHeaderBody,
  homescreencards,
  CreateNewEngBtn,
  specSelection,
  deleteModalBody,
  forcastResetModalHeader,
  forcastResetModalBody,
  forcastResetModalBodyDesc,
  deleteModalHeader,
  specHeaderDesc,
  emisSpec,
  CancelBtn,
  ConfirmBtn,
  PreviousBtn,
  NextBtn,
  SaveAndNextBtn,
  PoweBiBtn,
  SaveBtn,
  YesBtn,
  AddUserBtn,
  addNewUser,
  teamType,
  removeForecast,
  addForecastModalHeading,
  viaExcel,
  viaUI,
  DownloadTemplateBtn,
  DownloadErrorReportBtn,
  FourDigitYear,
  FileVerificationIsInProgress,
  FileUploadIsInProgress,
  FileUploadIsInSuccess,
  DataVerificationIsInProgress,
  DataVerificationIsInFailed,
  DataVerificationIsInSuccess,
  GeneratingErrorReport,
  DataLoadIsInFailed,
  DataLoadIsInSuccess,
  DataLoadIsInProgress,
  ExcelDataVerificationFailed,
  ApplyBtn,
  unitValidationTableColDefs,
  unitValidationMsg,
  unitValidationHeader,
  FailedStatus,
  TableHeaderClientName,
  TableHeaderEngagementCode,
  TableHeaderEngagementName,
  TableHeaderEngagementStatus,
  CloseProjectHint,
  homeSolutionModules,
  homeViewDashboard,
  setupClientName,
  setupCreateNewEngHeader,
  setupEngagementCode,
  setupEngagementName,
  setupExternalTeam,
  setupHeader,
  setupInternalTeam,
  setupSector,
  setupTableHeaderEmailId,
  setupTableHeaderName,
  setupTableHeaderTeam,
  setupTeamSetup,
  setupRemoveUserModalBody,
  setupRemoveUserModalHeader,
  setupEngagementCodeRequired,
  AddForecastBtn,
  AddTargetBtn,
  ResetM2Data,
  addForecastSpecificationAddCustomBtn,
  addForecastSpecificationAddEmissionBtn,
  addForecastSpecificationAddGeoBtn,
  addForecastSpecificationAddOrgBtn,
  addForecastSpecificationCustom,
  addForecastSpecificationDataType,
  addForecastSpecificationDataTypeOptions,
  addForecastSpecificationEndYear,
  addForecastSpecificationForecastDesc,
  addForecastSpecificationForecastName,
  addForecastSpecificationGeo,
  addForecastSpecificationOrg,
  addForecastSpecificationPercentValue,
  addForecastSpecificationStartYear,
  addForecastSpecificationHeader,
  addForecastSpecificationEmissionRequired,
  addForecastSpecificationGeoRequired,
  addForecastSpecificationOrgRequired,
  addForecastSpecificationDataTypeRequired,
  addForecastSpecificationPercentValueRequired,
  addForecastSpecificationStartYearRequired,
  addForecastSpecificationStartYearGreaterThanMRY,
  addForecastSpecificationStartYearLessThanFEY,
  addForecastSpecificationEndYearRequired,
  addForecastSpecificationEndYearGreaterThanMRY,
  addForecastSpecificationEndYearLessThanFEY,
  addForecastSpecificationForecastNameRequired,
  addTargetTableHeaderCustom,
  addTargetTableHeaderDataPercentageValue,
  addTargetTableHeaderDataBaseYear,
  emission,
  addTargetTableHeaderGeo,
  addTargetTableHeaderOrg,
  addTargetTableHeaderTargetDesc,
  addTargetTableHeaderTargetName,
  addTargetTableHeaderTargetType,
  addTargetTableHeaderTemporalType,
  addTargetTableHeaderDataTargetYear,
  addTargetSpecificationName,
  addTargetSpecificationTargetDesc,
  addTargetSpecificationTargetName,
  addTargetSpecificationTargetTemporalType,
  addTargetSpecificationTargetType,
  addTargetSpecificationPercentValueAnnual,
  addTargetSpecificationPercentValueTargetYear,
  addTargetSpecificationBaseYear,
  addTargetSpecificationTargetYear,
  addTargetSpecificationEmission,
  addTargetSpecificationAddEmissionBtn,
  addTargetSpecificationGeo,
  addTargetSpecificationAddGeoBtn,
  addTargetSpecificationOrg,
  addTargetSpecificationAddOrgBtn,
  addTargetSpecificationCustom,
  addTargetSpecificationAddCustomBtn,
  activityOutputText,
  activityOutput,
  addTargetSpecificationTargetTypeOptions,
  addTargetSpecificationTemporalTypeOptions,
  addTargetSpecificationEmissionRequired,
  addTargetSpecificationGeoRequired,
  addTargetSpecificationOrgRequired,
  addTargetSpecificationTargetTypeRequired,
  addTargetSpecificationTemporalTypeRequired,
  addTargetSpecificationPercentValueAnnualRequired,
  addTargetSpecificationPercentValueTargetYearRequired,
  addTargetSpecificationBaseYearRequired,
  addTargetSpecificationBaseYearGreaterThanFEY,
  addTargetSpecificationBaseYearLessThanMRY,
  addTargetSpecificationTargetYearRequired,
  addTargetSpecificationForecastNameRequired,
  duplicateIntensityTargetUnitModalHeader,
  duplicateIntensityTargetUnitDescription,
  addTargetSpecificationTargetYearGreaterThanMRY,
  addTargetSpecificationTargetYearLessThanFEY,
  addForecastSpecificationEndYearLessThanStartYear,
  addForecastSpecificationStartYearGreaterThanEndYear,
  DeleteBtn,
  forecastChartNoData,
  forecastChartNoDataDesc1,
  forecastChartNoDataDesc2,
  forecastChartProcessingForecastAndTargetData,
  forecastChartXAxisEmissionsWithTarget,
  forecastChartXAxisEmissionsWithoutTarget,
  forecastChartXAxisTarget,
  forecastChartDataLoadingMessage,
  forecastChartForecastLabel,
  forecastChartTargetLabel,
  forecastChartForecastAndTargetLabel,
  DataLoadHasFailed,
  deleteMessage,
  deleteMessageLine2,
  deleteMessageLine3,
  setupEngagementCodeLength,
  uploadSuccessMessage1,
  uploadSuccessMessage2,
  uploadSuccessMessage3,
  applyErrorMessageForecast,
  applyErrorMessageTarget,
  forecastPercentageError,
  targetPercentageError,
  DownloadBtn,
  fileVersionHistoryModalLiveVersion,
  YesChangeBtn,
  changeFEYModalBody,
  changeFEYModalHeader,
  YesSkipBtn,
  forecastSkipModalEmptyFEY,
  forecastBodyChartScope1And2,
  forecastBodyChartScope3,
  emissionHeaderTitle,
  reset,
  simulateValueTooltip,
  emissionHeaderTooltip,
  emissionGraphHeading,
  filters,
  editData,
  disableEditButton,
  emissionFilterTooltip,
  batchEditreduce,
  reduceChartNoData,
  cumulative,
  simulateValue,
  unit,
  simulate,
  unitTooltip,
  unitDropdown,
  yesNo,
  dropdowns,
  tablecolumnFilters,
  sessionTimeoutLogOut,
  sessionTimeoutMessage,
  sessionTimeoutStayLoggedIn,
  sessionTimeoutTitle,
  ExportBtn,
  changeLog,
  emissionsChangeLog,
  changeLogDescription,
  changeLogTableColDefs,
  changeLogReplaceTableColDefs,
  endYearGreaterThanFEYear,
  endYearLessThanStartYear,
  consentHeading,
  consentBodyText,
  consentPrivacyText,
  consentIAgree,
  consentNotice,
  consentNoticePrivacy,
  ruleName,
  ruleDescription,
  viewresults,
  CreateNewScenarioBtn,
  simulateHeaderDescription,
  simulateHeaderTitle,
  saveSimulateHeaderTitle,
  ScenarioName,
  ScenarioDescription,
  scenarioValidationMessage,
  projectTypes,
  projectResultsHeader,
  projectResultsEmissionsAbatementChartHeader,
  decimalsNotAllowedError,
  relativePercentageError,
  CAGRPercentageError,
  only2DecimalsAllowed,
  negativeNotallowed,
  financialParameterType,
  financialRulesType,
  financialVariableType,
  financialSetVariable,
  simulateCloseProjectScenarioHint,
  batchEditCompensate,
  batchEditReplace,
  emissionArray,
  projectResultsAbatementCashFlowCalcHeader,
  projectResultsBusinessCaseSummaryHeader,
  projectResultsCashFlowChartHeader,
  projectResultsEmissionAbatementChartAE,
  projectResultsEmissionAbatementChartFAP,
  projectResultsEmissionAbatementChartFBP,
  projectResultsEmissionAbatementChartRE,
  projectResultsEmissionAbatementChartYears,
  targetHierarchy,
  actDataValue,
  actDataUnit,
  emisFactValue,
  emisFactorUnit,
  transferAd,
  targetHierarchyValues,
  projectsLibraryHeader,
  scopes,
  maxlimitExceeded,
  resetFiltersHeader,
  resetFiltersBody,
  projectLibraryItemMargCost,
  projectLibraryItemNetPresent,
  projectLibraryItemRateOfReturn,
  projectLibraryItemTechAbatement,
  BackBtn,
  projectDetailsType,
  projectDetailsTags,
  projectDetailsBusinessRules,
  projectDetailsBusinessRulesRuleName,
  projectDetailsBusinessRuleDescription,
  projectDetailsBusinessRuleSource,
  projectDetailsBusinessRulesChangeType,
  projectDetailsBusinessEditType,
  projectDetailsBusinessRulesDataType,
  projectDetailsBusinessRulesHierarchy,
  projectDetailsBusinessRulesHierarchyNew,
  projectDetailsBusinessRulesName,
  projectDetailsBusinessRulesUnitOp,
  projectDetailsBusinessRulesCumulative,
  projectDetailsBusinessRulesUnit,
  projectDetailsBusinessRulesValue,
  projectDetailsBusinessRulesTransferAD,
  projectDetailsBusinessRulesADValue,
  projectDetailsBusinessRulesADUnit,
  projectDetailsBusinessRulesEfValue,
  projectDetailsBusinessRulesStartYear,
  projectDetailsBusinessRulesEndYear,
  projectDetailsBusinessRulesScope,
  projectDetailsBusinessRulesSubcat1,
  projectDetailsBusinessRulesSubcat2,
  projectDetailsBusinessRulesSubcat3,
  projectDetailsBusinessRulesReg1,
  projectDetailsBusinessRulesReg2,
  projectDetailsBusinessRulesReg3,
  projectDetailsBusinessRulesEnt1,
  projectDetailsBusinessRulesEnt2,
  projectDetailsBusinessRulesEnt3,
  projectDetailsBusinessRulesEnt4,
  projectDetailsBusinessRulesCust1,
  projectDetailsBusinessRulesCust2,
  projectDetailsBusinessRulesCust3,
  projectDetailsAbatementCashFlowCalcHeader,
  projectDetailsFinancialsHeader,
  projectDetailsItemDPP,
  projectDetailsItemMargCost,
  projectDetailsItemNetPresent,
  projectDetailsItemRateOfReturn,
  projectDetailsItemSImplePayback,
  projectDetailsItemTechAbatement,
  projectDetailsCategoryHeader,
  projectDetailsUnitHeader,
  projectToLibraryHeader,
  projectToLibraryBody,
  SubmitBtn,
  onlyNumbersAllowed,
  syncData,
  analyseValidationMessage,
  datainSyncMessage,
  analyseDeleteMessage,
  copyFromPastVersions,
  copyProjectsMessage,
  activityDataRelativeError,
  correctM3inM4Message,
  calculate,
  extUserDashboardM1Description,
  extUserDashboardM2Description,
  extUserDashboardM3Description,
  extUserDashboardM4Description,
  projectLibraryItemAbatement,
  absoluteorRelative,
  editExtend,
  eFValidationError,
  aDValidationError,
  transferOrCopy,
  deleteLastRule,
  createNewCategory,
  searchCategory,
  createNewRegion,
  searchNewEntity,
  searchNewRegion,
  createNewEntity,
  forecastScopeOptions,
  teamTooltip,
  versionHistoryTooltip,
  forecastNameTooltip,
  forecastDataTypeTooltip,
  forecastCustomTooltip,
  forecastGeographyTooltip,
  forecastOrgfTooltip,
  m2FileTemplateTooltip,
  targetNameTooltip,
  targetCustomTooltip,
  intesityTooltip,
  projDescTooltip,
  projTagsTooltip,
  ruleDescTooltip,
  sourceRuleTooltip,
  editTypeTooltip,
  changeTypeTooltip,
  activityUnitTooltip,
  emissionFactorunitTooltip,
  parameterSelectionTooltip,
  calculateTooltip,
  addGMPStartValueTooltip,
  decarbLibraryDescription,
  scenarioDesctooltip,
  sceanrioNameTooltip,
};
