import { POWER_BI_URL } from "./endpoints/powerBI";
import axios from "axios";

async function getPowerBIEmbedToken({ request }) {
  const instance = axios.create();

  return await instance.get(
     // `${POWER_BI_URL.GetEmbedToken}?userName=${request.username}&datasetId=${request.datasetId}`,
    `${POWER_BI_URL.GetEmbedToken}?userName=${request.username}&datasetId=84DD8366-85B0-45EB-A278-B7FDB254C880`,
    // `/WebApi/Invoke`,
    // {
    //   ActionVerb: "GET",
    //   ControllerName: "PowerBI",
    //   ActionName: "GetEmbedTokenAsync",
    //   QueryString:
    //     "userName=" + request.username + "&" + "datasetId=" + request.datasetId,
    //   Data: "",
    // },
    {
      withCredentials: true,
      baseURL: request.baseUrl,
      headers: {
        Authorization: `Bearer ${request.accessToken}`,
      },
    }
  );
}

export { getPowerBIEmbedToken };
