import React, { memo, useState } from "react";
import { Input } from "../../Input/Input";
import { useSelector } from "react-redux";

export default memo(({ value, node, field, onValueChange }) => {
  const gmp = useSelector((state) => state.gmp.gmp);
  const [data1, setValue] = useState(gmp?.rowData?.field);
  let original = value === null ? "" : value;
  // let viewed = data1
  //   ? data1 === "text"
  //     ? 0
  //     : data1.charAt(0) === "0"
  //     ? data1.substring(1)
  //     : data1
  //   : original;

    let viewed = data1
    ? data1 === "text"
      ? 0
      : data1.charAt(0) === "0"
      ? data1.substring(1)
      : data1
    : original;
  return (
    <Input
      type="decimal"
      value={viewed}
      onChange={(event) => {
        if (event.target.value === "" || isNaN(event.target.value)) {
          setValue("0");
        } else {
          setValue(event.target.value);
        }
      }}
      onBlur={(event) => {
        onValueChange(
          // value,
          field,
          event.target.value
        );
      }}
      disabled={
        // !gmp?.isAnyRowSelected ||
        gmp?.rowIndex !== node.rowIndex || value === null || value === ""
      }
    />
  );
});
