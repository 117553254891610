import { SIMULATE_URL } from "./endpoints/simulate";
import { post } from "./base.service";

async function saveScenario({ request }) {
  return await post(
    // `${SIMULATE_URL.SaveScenario}`, request
    `/WebApi/Invoke`,
    {
      ActionVerb: "POST",
      ControllerName: "M4Project",
      ActionName: SIMULATE_URL.SaveScenario,
      QueryString: "",
      Data: request,
    }
  );
}
async function generateGraph({ request }) {
  return await post(
    // `${SIMULATE_URL.MapProjectsToScenario}`, request
    `/WebApi/Invoke`,
    {
      ActionVerb: "POST",
      ControllerName: "M4Project",
      ActionName: SIMULATE_URL.MapProjectsToScenario,
      QueryString: "",
      Data: request,
    }
  );
}
async function getM4TrajectoryGraphData({ request }) {
  return await post(
    // `${SIMULATE_URL.GetM4TrajectoryGraphData}?engagementCode=${request.engagementCode}&ScenarioID=${request.ScenarioID}`
    `/WebApi/Invoke`,
    {
      ActionVerb: "GET",
      ControllerName: "M4Project",
      ActionName: SIMULATE_URL.GetM4TrajectoryGraphData,
      QueryString:
        "engagementCode=" +
        request.engagementCode +
        `&` +
        "ScenarioID=" +
        request.ScenarioID,
      Data: "",
    }
  );
}
async function getEmissionData({ request }) {
  return await post(
    // `${SIMULATE_URL.GetEmissionData}?engagementCode=${request.engagementCode}&ScenarioID=${request.ScenarioID}`
    `/WebApi/Invoke`,
    {
      ActionVerb: "GET",
      ControllerName: "M4Project",
      ActionName: SIMULATE_URL.GetEmissionData,
      QueryString:
        "engagementCode=" +
        request.engagementCode +
        `&` +
        "ScenarioID=" +
        request.ScenarioID,
      Data: "",
    }
  );
}
async function getM4WaterfallGraphData({ request }) {
  return await post(
    // `${SIMULATE_URL.GetM4WaterfallGraphData}?engagementCode=${request.engagementCode}&ScenarioID=${request.ScenarioID}`
    `/WebApi/Invoke`,
    {
      ActionVerb: "GET",
      ControllerName: "M4Project",
      ActionName: SIMULATE_URL.GetM4WaterfallGraphData,
      QueryString:
        "engagementCode=" +
        request.engagementCode +
        `&` +
        "ScenarioID=" +
        request.ScenarioID,
      Data: "",
    }
  );
}
async function getM4MarginalAbatementCostGraphData({ request }) {
  return await post(
    // `${SIMULATE_URL.GetM4MarginalAbatementCostGraphData}?engagementCode=${request.engagementCode}&ScenarioID=${request.ScenarioID}`
    `/WebApi/Invoke`,
    {
      ActionVerb: "GET",
      ControllerName: "M4Project",
      ActionName: SIMULATE_URL.GetM4MarginalAbatementCostGraphData,
      QueryString:
        "engagementCode=" +
        request.engagementCode +
        `&` +
        "ScenarioID=" +
        request.ScenarioID,
      Data: "",
    }
  );
}
async function getBusinessCaseSummaryData({ request }) {
  return await post(
    // `${SIMULATE_URL.GetBusinessCaseSummaryData}?engagementCode=${request.engagementCode}&ScenarioID=${request.ScenarioID}`
    `/WebApi/Invoke`,
    {
      ActionVerb: "GET",
      ControllerName: "M4Project",
      ActionName: SIMULATE_URL.GetBusinessCaseSummaryData,
      QueryString:
        "engagementCode=" +
        request.engagementCode +
        `&` +
        "ScenarioID=" +
        request.ScenarioID,
      Data: "",
    }
  );
}
async function getAbatementCashflowData({ request }) {
  return await post(
    // `${SIMULATE_URL.GetAbatementCashflowData}?engagementCode=${request.engagementCode}&ScenarioID=${request.ScenarioID}`
    `/WebApi/Invoke`,
    {
      ActionVerb: "GET",
      ControllerName: "M4Project",
      ActionName: SIMULATE_URL.GetAbatementCashflowData,
      QueryString:
        "engagementCode=" +
        request.engagementCode +
        `&` +
        "ScenarioID=" +
        request.ScenarioID,
      Data: "",
    }
  );
}
async function deleteScenarioDetail({ request }) {
  return await post(
    // `${SIMULATE_URL.DeleteScenarioDetail}?engagementCode=${request.engagementCode}&ScenarioID=${request.scenarioId}`
    `/WebApi/Invoke`,
    {
      ActionVerb: "POST",
      ControllerName: "M4Project",
      ActionName: SIMULATE_URL.DeleteScenarioDetail,
      QueryString:
        "engagementCode=" +
        request.engagementCode +
        `&` +
        "ScenarioID=" +
        request.scenarioId,
      Data: "",
    }
  );
}

async function getScenarioDetails({ request }) {
  return await post(
    // `${SIMULATE_URL.GetScenarioDetails}?ScenarioID=${request.scenarioId}`
    `/WebApi/Invoke`,
    {
      ActionVerb: "GET",
      ControllerName: "M4Project",
      ActionName: SIMULATE_URL.GetScenarioDetails,
      QueryString: "ScenarioID=" + request.scenarioId,
      Data: "",
    }
  );
}
async function updateScenarioDescription({ request }) {
  return await post(
    // `${SIMULATE_URL.UpdateScenarioDesc}?ScenarioID=${request.ScenarioID}&scenarioDesc=${request.scenarioDesc}`
    `/WebApi/Invoke`,
    {
      ActionVerb: "POST",
      ControllerName: "M4Project",
      ActionName: SIMULATE_URL.UpdateScenarioDesc,
      QueryString:
        "ScenarioID=" +
        request.ScenarioID +
        `&` +
        "scenarioDesc=" +
        encodeURIComponent(request.scenarioDesc),
      Data: "",
    }
  );
}

export {
  saveScenario,
  generateGraph,
  getM4TrajectoryGraphData,
  getEmissionData,
  getM4WaterfallGraphData,
  getM4MarginalAbatementCostGraphData,
  getBusinessCaseSummaryData,
  getAbatementCashflowData,
  deleteScenarioDetail,
  getScenarioDetails,
  updateScenarioDescription,
};
